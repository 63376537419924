import React from "react";
import Classnames from "classnames";
import styles from "../../Wallet/Purchase/CreditPackagesModal/CreditPackagesModal.module.scss";
import {t} from "i18next";

const HowFantasyWorksModal = ({handleModalClose, handleShowHowFantasyPoints}) => {
   const handleShowHowFantasyPointsBtn = () => {
      handleShowHowFantasyPoints();
      handleModalClose();
   }

   return (<div
      className={Classnames("modal fade show", styles.modalBlur, styles.PaymentConfirmmodal, styles.HowFantasyWorks)}
      id="exampleModalCenter"
      tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"
      style={{display: "block"}}>
      <div className={Classnames(styles.CreditPurchaseModal, "modal-dialog modal-dialog-centered")} role="document">
         <div className={Classnames("modal-content", styles.modalContentContainer)}>
            <button className={Classnames(styles.closeButton, "close z-3")} type="button" onClick={handleModalClose}
                    data-dismiss="modal" aria-label="Close">
                   <span className={styles.closeButton} aria-hidden="true">
                      <img alt='Close Icon' src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/>
                   </span>
            </button>
            <div className={Classnames(styles.UserProfileModalWrapper)}>
               <div className={Classnames(styles.ellipse, styles.eMulticolorHalf)}></div>
               <div className={Classnames(styles.ellipse, styles.e392)}></div>
               <div className="row p-0">
                  <div className={Classnames(styles.UserProfileModal, "col-md-12")}>
                     <div className={Classnames(styles.TitleWrapper)}>
                        <h4>{t("FANTASY.HOW_FANTACY_WORKS")}</h4>
                     </div>
                  </div>
               </div>
               <div className="row p-0">
                  <div className={Classnames(styles.UserProfileModal, "col-md-12")}>
                     <ul>
                        <li>
                           <div>🏏 {t("FANTASY.HOW_DO_I_CREATE_MY_FANTASY_TEAM_ON_META_11")}</div>
                           <p>{t("FANTASY.FANTASY_GAMES_TYPICALLY_INVOLVE_SELECTING_VIRTUAL_TEAMS_OF_REAL_PLAYERS_IN_META_11_FANTASY_YOU_CHOOSE_VIRTUAL_TEAMS_OF_VIRTUAL_AI_PLAYERS_INCLUDING_A_CAPTAIN_AND_VICE_CAPTAIN_THESE_SELECTIONS_PLAY_A_CRUCIAL_ROLE_IN_MAXIMISING_YOUR_POINTS")}</p>
                        </li>
                        <li>
                           <div>⏳ {t("FANTASY.HOW_OFTEN_DO_I_NEED_TO_SELECT_MY_FANTASY_TEAM")}</div>
                           <p>{t("FANTASY.FANTASY_TEAMS_HAVE_TO_BE_SELECTED_DAILY_WHICH_SIMPLY_MEANS_1_FANTASY_TEAM_PER_1_TOURNAMENT_THIS_ENSURES_THAT_YOU_ARE_ALWAYS_ENGAGED_AND_UPTODATE_WITH_THE_LATEST_MATCHES_AND_PLAYER_PERFORMANCES")}</p>
                        </li>
                        <li>
                           <div>✅ {t("FANTASY.HOW_ARE_POINTS_EARNED_AND_UPDATED_IN_META_11_FANTASY")}</div>
                           <p>{t("FANTASY.POINTS_CAN_BE_EARNED_FROM_FANTASY_GAMES_BASED_ON_THE_PERFORMANCE_OF_THE_PLAYERS_YOU_HAVE_SELECTED_THESE_POINTS_ARE_UPDATED_IN_REALTIME_IN_THE_GAME_REFLECTING_THE_LATEST_ACTIONS_AND_SCORES_FROM_THE_MATCHES")}</p>
                        </li>
                        <li>
                           <div>🪙 {t("FANTASY.WHAT_REWARDS_CAN_I_EARN_BY_PLAYING_META_11_FANTASY")}</div>
                           <p>{t("FANTASY.WIN_XP_POINTS_DAILY_THAT_UNLOCK_$SPRTS_OUR_IN_GAME_UTILITY_TOKEN_THESE_TOKENS_CAN_BE_USED_FOR_VARIOUS_INGAME_PURCHASES_AND_ADVANTAGES_ENHANCING_YOUR_OVERALL_GAMING_EXPERIENCE")}</p>
                        </li>
                        <li>
                           <button className={Classnames(styles.SeeFantasyPointsCalculation, "bg-transparent border-0 text-white")} onClick={handleShowHowFantasyPointsBtn}>
                              {t("FANTASY.SEE_HOW_YOUR_FANTASY_POINTS_ARE_CALCULATED")}
                           </button>
                        </li>
                        <li>
                           {/* <div className={Classnames(styles.HowFantasyWorksCheckBox)}><input type="checkbox" id="checkbox" name="checkbox"></input><label>Don't show this again</label></div> */}
                        </li>
                     </ul>
                  </div>
               </div>
               <div className="row p-0">
                  <div className={Classnames(styles.ModalButtonRow, "row p-0")}>
                     <button className="grayButton" onClick={handleModalClose}>
                        <text>{t("FANTASY.GET_STARTED")}</text>
                     </button>
                  </div>
               </div>
               <div className={Classnames(styles.Radial1)}></div>
               <div className={Classnames(styles.Radial2)}></div>
               <div className={Classnames(styles.Radial3)}></div>
               {/* <div className={Classnames(styles.EdgeRadial1)}></div> */}
               <div className={Classnames(styles.EdgeRadial2)}></div>
            </div>
         </div>
      </div>
   </div>)
}

export default HowFantasyWorksModal;
