/****---stable version 4.0.0 ---****/
/****--- QBOT ---***/
import React, {useCallback, useEffect, useState} from 'react'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {connect} from "react-redux";
import styles from "./FantasyBot.module.scss";
import classNames from "classnames";
import Classnames from "classnames";
import classnames from "classnames";

import SprtsToken from "../../assets/images/icons/24_sprts_token.webp";
import OmniPoints from "../../assets/images/icons/24_xp.webp";
import {getConf} from "../../services/ConfigService";
import {WALLET} from "../../utilities/ConfigConstants";
import {t} from "i18next";
import {checkUserFantasySubscription, getFantasyTeamByUsernameAndTournamentId} from "../../services/FantasyService";
import Styles from "../Tournament/FantasyTeamSection/TournamentFantasySection.module.scss";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {getTournamentById} from "../../services/TournamentService";
import {
   formatNumberToKWithoutRounding,
   getBoosterRemainingCircle, getBoosterRemainingTime,
   getFantasyPointsStatusClass,
   getShowingFantasyPoints,
   getSprtsValueFromPoints, toolTipProvider
} from "../../helpers/CommonFunctions";
import MoreInfoIcon from "../../assets/images/icons/more_info_icon.svg";
import {ReactComponent as FlashIcon} from "../../assets/images/icons/flash.svg";
import * as StatisticsService from "../../services/StatisticsService";
import {AVAILABILITY_CHECK_STATUS} from "../../utilities/Enums";
import {BOOSTER_EXTRA_TIME} from "../../utilities/Constants";

function FantasyBot(props) {
   const XP_TO_SPRTS_RATIO = getConf(WALLET.XP_TO_SPRTS_RATIO);
   const CHECKING_TOAST_ID = "checkingToast";
   const CHECKING_INTERVAL = 30000;
   const MAX_TRIES = 3;
   const navigate = useNavigate();
   const FANTASY_STATUS_TOAST_ID = "FANTASY_STATUS_TOAST_ID";
   const lang = localStorage.getItem("language") || "en";

   const [pointFetchTries, setPointFetchTries] = useState(0);

   const [matchData, setMatchData] = useState({});
   const [tournamentId, setTournamentId] = useState(undefined);
   const [checkingSubscription, setCheckingSubscription] = useState(true);
   const [isFetchingFantasyTeam, setIsFetchingFantasyTeam] = useState(true);
   const [isSubscribed, setIsSubscribed] = useState(false);
   const [isBoosterPurchaseAvailable, setIsBoosterPurchaseAvailable] = useState(false);

   const [fantasyTeam, setFantasyTeam] = useState([]);
   const [teamCaptain, setTeamCaptain] = useState(null);
   const [teamViceCaptain, setTeamViceCaptain] = useState(null);
   const [totalPoints, setTotalPoints] = useState(0);
   const [fantasyTeamName, setFantasyTeamName] = useState('');
   const [activeStatus, setActiveStatus] = useState(false);
   const [thisMatchPlayersObj, setThisMatchPlayersObj] = useState({});

   const [shouldGetFantasyPoints, setShouldGetFantasyPoints] = useState(false);
   const [availableUpcomingMatch, setAvailableUpcomingMatch] = useState(AVAILABILITY_CHECK_STATUS.IN_PROGRESS);

   const fetchFantasyTeam = useCallback(async () => {
      getFantasyTeamByUsernameAndTournamentId(props.userName, tournamentId, props.Token)
         .then((response) => {
            if (response.apiStatus && response.team) {
               response?.team.forEach(player => {
                  // set captain and vice captain
                  if (player.isCaptain) {
                     setTeamCaptain(player);
                  } else if (player.isVcCaptain) {
                     setTeamViceCaptain(player);
                  }
               });

               setTotalPoints(prevState => {
                  console.log("FantasyBot points changed:", prevState, response?.totalPoints);
                  if (prevState !== response?.totalPoints) {
                     setShouldGetFantasyPoints(false);
                     toast.update(CHECKING_TOAST_ID, {
                        render: t("FANTASY.FANTASY_POINTS_CALCULATED_SUCCESSFULLY"),
                        type: toast.TYPE.SUCCESS,
                        autoClose: 5000,
                        isLoading: false,
                     })
                  }
                  return response?.totalPoints || 0;
               })
               setFantasyTeamName(response?.teamName || '')
               setFantasyTeam(response?.team);

               const currentTime = new Date();
               if (new Date(matchData?.tournament?.startTime) < currentTime && new Date(matchData?.tournament?.endTime) > currentTime) {
                  setActiveStatus(response?.activeStatus);
               } else {
                  setActiveStatus(false);
               }
            }
         })
         .finally(() => {
            setIsFetchingFantasyTeam(false);
         });
   }, [matchData?.tournament?.endTime, matchData?.tournament?.startTime, props.Token, props.userName, tournamentId]);

   // navigate to create fantasy team page
   const handleCreateFantasyTeam = async () => {
      const res = await getTournamentById(tournamentId)
      if (res?.apiStatus && res?.tournament?.id) {
         const tournamentDetails = res?.tournament;
         // check if the tournament is completed
         if (tournamentDetails?.active === false && tournamentDetails?.toStart === false) {
            toast.warning(t("FANTASY.YOU_CANT_CREATE_FANTASY_TEAM_FOR_A_COMPLETED_TOURNAMENT"));
         } else if (availableUpcomingMatch === AVAILABILITY_CHECK_STATUS.UNAVAILABLE) {
            toast.warning(t("FANTASY.YOU_CANT_CREATE_A_FANTASY_TEAM_WHEN_THE_LAST_MATCH_IS_LIVE_OR_COMPLETED"));
         } else if (availableUpcomingMatch === AVAILABILITY_CHECK_STATUS.IN_PROGRESS) {
            toast.warning(t("FANTASY.CHECKING_MATCH_AVAILABILITY"));
         } else if (availableUpcomingMatch === AVAILABILITY_CHECK_STATUS.ERROR) {
            toast.error(t("FANTASY.ERROR_CHECKING_MATCH_AVAILABILITY"));
         } else {
            navigate(`/fantasy-team/${tournamentId}`, {state: {tournamentDetails: tournamentDetails, returnPath: `/match/${matchData?.matchId}`}})
         }
      } else {
         toast.error(t("FANTASY.TOURNAMENT_NOT_FOUND"));
      }
   }

   // get classes when captain and vice captain are not present
   const getCaptainRowClasses = () => {
      if (!teamCaptain && !teamViceCaptain) {
         return classNames(styles.CardWrapper, "row", styles.NoAnyCaptain);
      } else if (!teamCaptain || !teamViceCaptain) {
         return classNames(styles.CardWrapper, "row", styles.NoOneCaptain);
      } else {
         return classNames(styles.CardWrapper, "row");
      }
   }

   const handleShowBoosterPurchase = (player) => {
      if (isBoosterPurchaseAvailable) {
         props?.setShowBoosterPurchaseModal(player)
      } else {
         toast.warning(t("FANTASY.YOU_CANT_PURCHASE_A_BOOSTER_FOR_A_COMPLETED_TOURNAMENT"));
      }
   }

   useEffect(() => {
      console.log("FantasyBot props changed");
      if (props?.matchData) {
         setTournamentId(props.matchData?.tournamentId);
         setMatchData(prevState => {
            if (prevState?.live === true && props.matchData?.live === false) {
               console.log("FantasyBot match ended");

               if (isSubscribed && activeStatus) {
                  setShouldGetFantasyPoints(true);
                  toast.loading(t("FANTASY.CALCULATING_FANTASY_POINTS"), {
                     toastId: CHECKING_TOAST_ID,
                  });
               }
            }

            return props.matchData;
         })
      }
   }, [activeStatus, isSubscribed, props.matchData]);

   // check if user is subscribed to fantasy team
   useEffect(() => {
      console.log("FantasyBot checking subscription", tournamentId);
      const checkSubscription = async () => {
         setCheckingSubscription(true);
         checkUserFantasySubscription(tournamentId, props.userName, props.Token)
            .then((response) => {
               if (response.apiStatus && response.isSubscribed === true) {
                  setIsSubscribed(true);
                  setCheckingSubscription(false);
               } else if (response.apiStatus) {
                  setIsSubscribed(false);
                  setCheckingSubscription(false);
               }
            })
      }

      if (tournamentId) {
         checkSubscription();
      }
   }, [props.Token, props.userName, tournamentId]);

   // get fantasy team of the user
   useEffect(() => {
      console.log("FantasyBot fetching fantasy team", tournamentId);
      if (isSubscribed) {
         setIsFetchingFantasyTeam(true);
         fetchFantasyTeam();

         // fetch fantasy team every 30 seconds
         const interval = setInterval(() => {
            console.log("FantasyBot fetching fantasy team", tournamentId);
            if (isSubscribed) {
               fetchFantasyTeam();
            } else {
               clearInterval(interval);
            }
         }, CHECKING_INTERVAL);
         return () => clearInterval(interval);
      }
   }, [fetchFantasyTeam, isSubscribed, props.Token, props.userName, tournamentId, props?.refreshFantasy]);

   // handle get fantasy points at the end of the match
   useEffect(() => {
      if (shouldGetFantasyPoints && pointFetchTries < MAX_TRIES) {
         const interval = setInterval(() => {
            // console.log("FantasyBot fetching fantasy team in interval", pointFetchTries);
            // fetchFantasyTeam(); // commented because calling it every 30 seconds handling in another useEffect
            setPointFetchTries(prevState => prevState + 1);

         }, CHECKING_INTERVAL);

         return () => clearInterval(interval);
      } else if (pointFetchTries >= MAX_TRIES) {
         console.log(`Max attempts (${MAX_TRIES}) reached, stopping interval`);
         toast.dismiss(CHECKING_TOAST_ID);
      }
   }, [fetchFantasyTeam, pointFetchTries, shouldGetFantasyPoints]);

   useEffect(() => {
      const thisMatchPlayers = (matchData?.teamOne?.players && matchData?.teamTwo?.players) ? [...matchData?.teamOne?.players, ...matchData?.teamTwo?.players] : []
      if (thisMatchPlayers.length > 0) {
         const playersObj = thisMatchPlayers?.reduce((acc, player) => {
            acc[player.playerId] = player;
            return acc;
         }, {});

         setThisMatchPlayersObj(playersObj);
      }

   }, [matchData?.teamOne?.players, matchData?.teamTwo?.players, props?.thisMatchPlayers]);

   useEffect(() => {
      setAvailableUpcomingMatch(AVAILABILITY_CHECK_STATUS.IN_PROGRESS);
      const fetchData = async (type) => {
         StatisticsService.getAllMatchCardsByTournamentId("", 0, 1, "isLive,isScheduled,matchId,desc", type, tournamentId, lang, props.Token)
            .then((response) => {
               if (response.apiStatus && response.totalElements > 0) {
                  type === "upcoming" ? setAvailableUpcomingMatch(AVAILABILITY_CHECK_STATUS.AVAILABLE) : setIsBoosterPurchaseAvailable(true);
               } else {
                  type === "upcoming" ? setAvailableUpcomingMatch(AVAILABILITY_CHECK_STATUS.UNAVAILABLE) : setIsBoosterPurchaseAvailable(false);
               }
            }).catch(err => {
            setAvailableUpcomingMatch(AVAILABILITY_CHECK_STATUS.ERROR);
            console.log(err)
         })
      };
      fetchData("upcoming");
      fetchData("all");
   }, [lang, props.Token, tournamentId]);

   const loadingIndicator = (<div className={styles.loaderContainer} style={{textAlign: "center"}}>
      <div className="lds-facebook">
         <div></div>
         <div></div>
         <div></div>
      </div>
   </div>);

   const PlayerCard = ({player, role}) => {

      const activeBooster = player?.boosterRulePointsDTOS?.find(booster => {
         const endTime = new Date(booster?.endTime) - BOOSTER_EXTRA_TIME;
         if (booster?.active === true && new Date(endTime) > new Date()) {
            return booster
         }
      });

      const boosterTooltip = (element) => toolTipProvider(getBoosterRemainingTime(activeBooster), "top", element, true)

      return <div className={classNames(styles.Card)}>
         <div className={classNames(styles.CardBackground)}>
            <div className="row dddd">
               {boosterTooltip(<div className={classNames(Styles.BoostRemainingTime, "BoostRemainingTime")}>
                  <svg viewBox="0 0 180 180" width="180" height="180" xmlns="http://www.w3.org/2000/svg">
                     <path className="TimePath1" d="m 90,4 a 86,86 0 0,1 0,172 a 86,86 0 0,1 0,-172" fill="none"
                           stroke="#d9d9d9" stroke-width="8"></path>
                     <path className="TimePath2" style={{
                        stroke: activeBooster ? activeBooster?.colourCode : "transparent",
                        strokeDasharray: 540,
                        strokeDashoffset: getBoosterRemainingCircle(activeBooster),
                     }} d="m 90,4 a 86,86 0 0,1 0,172 a 86,86 0 0,1 0,-172" fill="none" stroke="rgb(0,255,194)"
                           stroke-linecap="round" stroke-width="8"
                     ></path>
                  </svg>
               </div>)}
               <a className={classNames(Styles.MoreInfoIconBtn, "MoreInfoIconBtn z-1")} role={"button"}
                  onClick={() => props.setShowPlayerInfoHistoryModal(player)}>
                  <img src={MoreInfoIcon}/>
               </a>
               <div
                  className={classNames(styles.ProfilePic, thisMatchPlayersObj?.[player?.playerId] ? "PlayingNow" : "")}
                  style={{backgroundImage: `url(${player?.playerPictureURL})`}}>
               </div>
               {role !== "CAPTAIN" && role !== "VICE_CAPTAIN" &&
                  <div className={classNames(styles.BoostIconWrapper, "BoostIconWrapper")}
                       style={{
                          cursor: isBoosterPurchaseAvailable ? 'pointer' : 'not-allowed'
                       }}
                       onClick={() => handleShowBoosterPurchase(player)}>
                     <div className={classNames(styles.Circle, "Circle")}>
                        <div className={classNames(styles.InnerCircle, "InnerCircle")}></div>
                        <FlashIcon/>
                     </div>
                  </div>
               }
            </div>
            <div className={classNames(styles.TitleRow, "row")}>
               <h4>{player?.playerName}</h4>
               {role === "CAPTAIN" ? <span>{"(C)"}</span> : role === "VICE_CAPTAIN" ?
                  <span>{"(VC)"}</span> : null}
            </div>
            <div className="PlayerPointsRow row">
               {(() => {
                  const playerPoints = getShowingFantasyPoints(player?.points);
                  return (<div
                     className={classnames("PlayerPoints", `${getFantasyPointsStatusClass(playerPoints?.sign)}`)}>
                     <span className={classNames(Styles.PointSplus, "PointSplus")}>{playerPoints.sign}</span>
                     <div
                        className={classNames(Styles.PointsWrapper, "PointsWrapper", playerPoints.intPart === 0 ? 'noPoints' : '')}>
                        {/*<span className={classNames(Styles.Points, "Points")}>{playerPoints.intPart}{playerPoints.decPart}</span>*/}
                        <span
                           className={classNames(Styles.DecimalPoints, "DecimalPoints")}>{playerPoints.intPart}{playerPoints.decPart}</span>
                     </div>
                  </div>);
               })()}
            </div>
         </div>
      </div>
   }

   const fetchData = async () => {
      await StatisticsService.getAllMatchCardsByTournamentId("", 0, 1, "isLive,isScheduled,matchId,desc", "all", tournamentId, lang, props.Token)
         .then((response) => {
            if (response.apiStatus && response.totalElements !== 0) {
               setActiveStatus(true);
            } else {
               setActiveStatus(false);
            }
         });
   };

   const fantasyTeamStatusToast = (isActive) => {
      const currentTime = new Date();

      console.log(matchData)
      console.log(new Date(matchData?.tournament?.startTime), currentTime, new Date(matchData?.tournament?.startTime) > currentTime)
      console.log(new Date(matchData?.tournament?.endTime), currentTime, new Date(matchData?.tournament?.endTime) > currentTime)

      if (isActive) {
         toast.success("Your fantasy team is Active", {toastId: FANTASY_STATUS_TOAST_ID})
      } else if (new Date(matchData?.tournament?.startTime) > currentTime) {
         toast.info("Team is still inactive, will get active once the tournament starts.", {toastId: FANTASY_STATUS_TOAST_ID})
      } else if (new Date(matchData?.tournament?.endTime) > currentTime) {
         toast.info("Team is still inactive, will get active from next match onwards.", {toastId: FANTASY_STATUS_TOAST_ID})
      } else {
         toast.info("Team is inactive and the tournament is completed.", {toastId: FANTASY_STATUS_TOAST_ID})
      }
   }

   return (
      <div className={classNames(styles.quizBoardWrapper, styles.FantasyzBoardWrapper, "App")}>
         <div className="chatbothead p-relative">
            <div className={classNames(styles.TitleWrapper)}>
               <h2>MY FANTASY TEAM</h2>
               <a className={classNames(styles.MoreInfoIconBtn)} onClick={() => {
                  props.setShowHowFantasyWorksModal(true)
               }}>
                  <img src={MoreInfoIcon}/>
               </a>
            </div>
         </div>

         {!props?.userName ?
            <div className={classNames(Styles.WinPrizesWrapper)}>
               <div className={classNames(Styles.Tournament)}>
                  <div className={classNames(Styles.NoDataWrapper)}>
                     <div className={classNames(Styles.NoFantasyDataWrapper)}>
                        <p>
                           Please login/Register to interact with your fantasy team.
                        </p>
                        <div>
                           <div className={classNames(Styles.NoFantasyDataButtonWrapper)}>
                              <button className="lightGreenButton" onClick={() => navigate("/login")}>
                                 {t("LOGIN_PAGE.SIGN_IN")}
                              </button>
                           </div>
                           <div className={classNames(Styles.NoFantasyDataButtonWrapper)}>
                              <button className="lightGreenButton" onClick={() => navigate("/register")}>
                                 {t("LOGIN_PAGE.CLICK_TO_SIGN_UP")}
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            : checkingSubscription || (isSubscribed && isFetchingFantasyTeam) || props?.parentLoading ? loadingIndicator : <>{!isSubscribed ?
               <div className={classNames(Styles.WinPrizesWrapper)}>
                  <div className={classNames(Styles.Tournament)}>
                     <div className={classNames(Styles.NoDataWrapper)}>
                        <div className={classNames(Styles.NoFantasyDataWrapper)}>
                           <p>
                              {t("FANTASY.JOIN_THE_META_11_FANTASY_TOURNAMENT_BUILD_YOUR_DREAM_TEAM_AND_SCORE_BIG_TO_WIN_SPRTS_TOKENS_LEARN_MORE")}
                              <button
                                 className={Classnames(Styles.SeeFantasyPointsCalculation, "bg-transparent border-0 text-white")}
                                 onClick={() => {
                                    props.setShowHowFantasyWorksModal(true)
                                 }}
                              >
                                 {t("FANTASY.LEARN_MORE")}
                              </button>
                           </p>
                        </div>
                        <div className={classNames(Styles.NoFantasyDataButtonWrapper)}>
                           <button className="lightGreenButton" onClick={handleCreateFantasyTeam}>
                              {t("FANTASY.CREATE_FANTASY_TEAM")}
                           </button>
                        </div>
                     </div>
                  </div>
               </div> :
               <div style={{position: "relative", minHeight: "fit-content", width: "100%", backgroundColor: "rgb(2, 19, 27)"}}>
                  <div className={classNames(styles.FantasyCardWrapper,"FantasyCardWrapper")}>

                     <div className="text-center mb-2">
                        <div className={classNames(Styles.ActiveStatusTagWrapper, "ActiveStatusTagWrapper")}>
                           {!activeStatus ?
                              <div className={classNames(Styles.TournamentStatus, Styles.InactiveStatus, "TournamentStatus InactiveStatus")} role={'button'} onClick={() => fantasyTeamStatusToast(false)}><span></span>{t("FANTASY.INACTIVE")}</div> :
                              <div className={classNames(Styles.TournamentStatus, Styles.ActiveStatus, "TournamentStatus ActiveStatus")} role={'button'} onClick={() => fantasyTeamStatusToast(true)}><span></span>{t("FANTASY.ACTIVE")}</div>
                           }
                        </div>
                     </div>
                     <div className="row">
                        <h2>{fantasyTeamName}</h2>
                     </div>
                     <div className={classNames(styles.CardWrapper, "row CardWrapper")}>
                        {teamCaptain && <PlayerCard player={teamCaptain} role="CAPTAIN"/>}
                        {teamViceCaptain && <PlayerCard player={teamViceCaptain} role="VICE_CAPTAIN"/>}
                        {fantasyTeam.map(player => {
                           if (!player.isCaptain && !player.isVcCaptain) {
                              return (<PlayerCard player={player}/>)
                           } else {
                              return null;
                           }
                        })}
                     </div>
                     <div className="row">
                        <div className={classNames(styles.TotalEarningWrapper)}>
                           <div className={classNames(styles.TotalEarning)}>
                              <div className={classNames(styles.Title)}>
                                 {t("FANTASY.TOTAL_EARNING")}
                              </div>
                              <div className={classNames(styles.SprtsToken)}>
                                 <img
                                    src={SprtsToken}/>{totalPoints ? getSprtsValueFromPoints(totalPoints, XP_TO_SPRTS_RATIO) : 0}
                              </div>
                              <div className={classNames(styles.OmniPoints)}>
                                 <img src={OmniPoints}/>{formatNumberToKWithoutRounding(totalPoints)}
                              </div>
                           </div>
                        </div>
                     </div>

                  </div>

               </div>}</>}
      </div>
   )
}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      refresh: state.auth.refreshToken,
      Token: state.auth.accessToken,
      userId: state.auth.userId,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      email: state.auth.email
   };
}

export default connect(mapStateToProps)(FantasyBot)
