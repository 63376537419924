import React from 'react';

const ActionProvider = ({createChatBotMessage, setState, children}) => {

   const handleHi = () => {
      const botMessage = createChatBotMessage('Lets get started, What are you looking for?', {
         widget: 'InitialButtonList'
      });

      setState((prevState) => ({...prevState, messages: [...prevState.messages, botMessage]}));
   }

   const handleDog = () => {
      const botMessage = createChatBotMessage(
         "Here's a nice dog picture for you!",
         {
            widget: 'DogPicture',
         }
      );
      setState((prevState) => ({...prevState, messages: [...prevState.messages, botMessage]}));
   }

   const showValues = (name) => {
      console.log(name);
   }

   return (
      <div>
         {React.Children.map(children, (child) => {
            return React.cloneElement(child, {
               actions: {
                  handleHi,
                  handleDog,
                  showValues
               },
            });
         })}
      </div>
   );
};

export default ActionProvider;
