import Classnames from "classnames";
import styles from "./PaymentConfirmModal.module.scss";
import React, {useEffect, useState} from "react";
import {formatDateForTournament} from "../../../../helpers/CommonFunctions";
import {useTranslation} from "react-i18next";
import DeleteAccHeaderImg from "../../../../assets/images/delete_profile_img.webp";
import CreditIcon from "../../../../assets/images/icons/24_sprts_token.webp";
import * as WalletService from "../../../../services/WalletService";
import {AUTHTOKEN_ERRORS, TOURNAMENT_TYPES, TRANSACTION_STATUS} from "../../../../utilities/Constants";
import {connect} from "react-redux";
import * as CartService from "../../../../services/CartService";
import {Puff} from "react-loading-icons";
import {toast} from "react-toastify";
import * as TournamentService from "../../../../services/TournamentService";
import {Link, useLocation} from "react-router-dom";
import CreditPackagesModal from "../CreditPackagesModal/CreditPackagesModal";
import SequenceLoginModal from "../../SequenceLoginModal/SequenceLoginModal";
import CreditPurchaseStatusModal from "../CreditPurchaseStatusModal/CreditPurchaseStatusModal";

function PaymentConfirmModal(props) {

   const {t} = useTranslation();
   const [walletDetails, setWalletDetails] = useState({});
   const [cartDetails, setCartDetails] = useState({});
   const [cartCreationLoading, setCartCreationLoading] = useState(false);
   const [cartCheckoutDetails, setCartCheckoutDetails] = useState({});
   const [isPurchased, setIsPurchased] = useState(null);
   const location = useLocation();
   const [isAvailableStatusLoaded, setIsAvailableStatusLoaded] = useState(false);
   const [isCreditPurchaseModalOpen, setIsCreditPurchaseModalOpen] = useState(false);
   const [isSequenceLoginModalOpen, setIsSequenceLoginModalOpen] = useState(false);
   const [isTransactionStatusModalOpen, setIsTransactionModalOpen] = useState(false);
   const [packageId, setPackageId] = useState(0);
   const [purchaseMaticAmount, setPurchaseMaticAmount] = useState(0);
   const [transactionStatus, setTransactionStatus] = useState(null);
   const [transactionAmount, setTransactionAmount] = useState(0);
   const [transactionHash, setTransactionHash] = useState(0);
   const [translationsLoaded, setTranslationsLoaded] = useState(false);
   const [sprtsAmount, setSprtsAmount] = useState()

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   useEffect(() => {
      const fetchData = () => {
         if (props.token && props.userName) {
            WalletService.getOmniCreditsByUsername(props.token, props.userName)
               .then(res => {
                  if (res?.data?.apiStatus) {
                     setWalletDetails(res.data);
                  } else if (res?.status === 400 && res?.data?.rejectMessages[0] === AUTHTOKEN_ERRORS.AUTHENTICATION_TOKEN_HAS_EXPIRED) {
                     // props.onLogout();
                  }
               });
         }
      };

      fetchData();

      const intervalId = setInterval(fetchData, 120000);

      return () => clearInterval(intervalId);
   }, [props.token, props.userName, props.onLogout]);

   useEffect(() => {
      setIsAvailableStatusLoaded(false);
      const checkTournamentRegistration = async (tournamentId) => {
         TournamentService.checkTournamentRegistration(tournamentId, props.userName)
            .then((response) => {
               if (response.apiStatus) {
                  if (response.available) {
                     setIsAvailableStatusLoaded(true);
                     setIsPurchased(true);
                  } else {
                     setIsAvailableStatusLoaded(true);
                     setIsPurchased(false);
                  }
               }
            }).catch(err => {
            console.log(err)
         });
      }

      checkTournamentRegistration(props.tournamentDetails.id);
   }, [props.token, props.userName, props.tournamentDetails]);


   const purchaseTournament = async (tournamentData) => {
      setCartCreationLoading(true);
      const cartData = {
         "items": [
            {
               "productId": 'TOURNAMENT_' + tournamentData.id,
               "qty": 1
            }
         ]
      }
      createCart(cartData);
   }

   const navigateButtonHandler = () => {
      if (props.tournamentDetails?.tournamentType === TOURNAMENT_TYPES.LEAGUE) {
         return (
            <button type="button" className={styles.LoginButton}>
               <Link style={{textDecoration: 'none'}} to={{pathname: `/league/${props.tournamentDetails?.id}`, search: `returnUrl=${encodeURIComponent(location.pathname)}`}}>{t("PAYMENT_CONFIRM_MODAL.GO_TO_TOURNAMENT")}</Link>
            </button>
         );
      } else if (props.tournamentDetails?.tournamentType === TOURNAMENT_TYPES.KNOCKOUT) {
         return (
            <button type="button" className={styles.LoginButton}>
               <Link style={{textDecoration: 'none'}} to={{pathname: `/knockout/${props.tournamentDetails?.id}`, search: `returnUrl=${encodeURIComponent(location.pathname)}`}}>{t("PAYMENT_CONFIRM_MODAL.GO_TO_TOURNAMENT")}</Link>
            </button>
         );
      } else if (props.tournamentDetails?.tournamentType === TOURNAMENT_TYPES.LEAGUE_KNOCKOUT) {
         return (
            <button type="button" className={styles.LoginButton}>
               <Link style={{textDecoration: 'none'}} to={{pathname: `/league-knockout/${props.tournamentDetails?.id}`, search: `returnUrl=${encodeURIComponent(location.pathname)}`}}>{t("PAYMENT_CONFIRM_MODAL.GO_TO_TOURNAMENT")}</Link>
            </button>
         );
      }
   }

   const createCart = async (cartData) => {
      const createCartToast = toast.loading(t("PAYMENT_CONFIRM_MODAL.PURCHASING_THE_TOURNAMENT"));
      CartService.createNewCart(cartData, props.token)
         .then(res => {
               setCartCreationLoading(false);
               if (res?.data?.apiStatus) {
                  toast.update(createCartToast, {
                     render: t("PAYMENT_CONFIRM_MODAL.CART_CREATED_SUCCESSFULLY_CHECKING_OUT_THE_CART"),
                     type: toast.TYPE.SUCCESS,
                     autoClose: 5000,
                     closeButton: null,
                     isLoading: false
                  })
                  setCartDetails(res);
                  checkoutCart(res?.data?.cartId);
               } else {
                  toast.update(createCartToast, {
                     render: res?.data?.rejectMessages[0],
                     type: toast.TYPE.ERROR,
                     autoClose: 5000,
                     closeButton: null,
                     isLoading: false
                  })
                  setCartDetails(null);
               }
            }
         ).catch(err => console.log(err));
   }

   const checkoutCart = async (cartId) => {
      const checkoutCartToast = toast.loading(t("PAYMENT_CONFIRM_MODAL.CHECKING_OUT_THE_CART"));
      setCartCreationLoading(true);
      CartService.checkoutCart(cartId, props.token)
         .then(res => {
            setCartCreationLoading(false);
            if (res?.data?.apiStatus) {
               toast.update(checkoutCartToast, {
                  render: t("PAYMENT_CONFIRM_MODAL.YOUR_TOURNAMENT_PURCHASE_IS_A_SUCCESS"),
                  type: toast.TYPE.SUCCESS,
                  autoClose: 5000,
                  closeButton: null,
                  isLoading: false
               })
               setCartCheckoutDetails(res?.data);
               props.handleClose();
               if (props.tournamentDetails?.tournamentType === TOURNAMENT_TYPES.LEAGUE) {
                  window.location.href = `/league/${props.tournamentDetails?.id}?returnUrl=${encodeURIComponent(location.pathname)}`;
               } else if (props.tournamentDetails?.tournamentType === TOURNAMENT_TYPES.KNOCKOUT) {
                  window.location.href = `/knockout/${props.tournamentDetails?.id}?returnUrl=${encodeURIComponent(location.pathname)}`;
               } else if (props.tournamentDetails?.tournamentType === TOURNAMENT_TYPES.LEAGUE_KNOCKOUT) {
                  window.location.href = `/league-knockout/${props.tournamentDetails?.id}?returnUrl=${encodeURIComponent(location.pathname)}`;
               }
            } else {
               toast.update(checkoutCartToast, {
                  render: res?.data?.message,
                  type: toast.TYPE.ERROR,
                  autoClose: 5000,
                  closeButton: null,
                  isLoading: false
               })
               setCartCheckoutDetails(null);
            }
         })
         .catch(err => console.log(err));
   }

   const loadingIndicator = (
      <div style={{textAlign: "center"}}>
         <div className="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
         </div>
      </div>
   );

   const rechargeWallet = () => {
      setIsCreditPurchaseModalOpen(true);
   }

   const openSequenceLoginModal = (packageId, maticValue) => {
      setPackageId(packageId);
      setPurchaseMaticAmount(maticValue);
      setIsSequenceLoginModalOpen(true);
   }

   const handleGoBack = () => {
      setIsSequenceLoginModalOpen(false);
      setIsCreditPurchaseModalOpen(true);
   }

   const handleOpen = () => {
      setIsCreditPurchaseModalOpen(true)
   }

   const closeAllModals = () => {
      setIsCreditPurchaseModalOpen(false);
      setIsSequenceLoginModalOpen(false);
      setIsTransactionModalOpen(false);
   }

   function closeModal() {
      setIsCreditPurchaseModalOpen(false);
      setIsSequenceLoginModalOpen(false);
   }

   const handleTransactionStatus = (transactionStatus, transactionAmount, transactionHash,sprtsAmount) => {
      setTransactionAmount(transactionAmount);
      setTransactionHash(transactionHash);
      setIsTransactionModalOpen(true);
      setSprtsAmount(sprtsAmount);

      if (transactionStatus === TRANSACTION_STATUS.SUCCESS) {
         setTransactionStatus(true);
      } else {
         setTransactionStatus(false);
      }
   }

   return (
      <React.Fragment>
         <div className={Classnames("modal fade show", styles.modalBlur, styles.PaymentConfirmmodal)} id="exampleModalCenter"
              tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{display: "block"}}>
            <div className={Classnames(styles.userProfileModalWrapper, "modal-dialog modal-dialog-centered")} role="document">
               <div className={Classnames("modal-content", "p-4", styles.modalContentContainer)}>
                  <button className={Classnames(styles.closeButton, "close")} style={{position: "absolute", right: "15px", top: "10px", zIndex: "2"}} disabled={cartCreationLoading} type="button" onClick={props.handleClose} data-dismiss="modal" aria-label="Close">
                   <span className={styles.closeButton} aria-hidden="true">
                      <img alt='Close Icon' src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/>
                   </span>
                  </button>
                  <div className={Classnames(styles.UserProfileModalWrapper)}>
                     <div className={Classnames(styles.circle1)}></div>
                     <div className={Classnames(styles.circle2)}></div>
                     <div className={Classnames(styles.circle3)}></div>
                     <div className="row p-0">
                        <div className={Classnames(styles.UserProfileModal, "col-md-12")}>
                           <img src={DeleteAccHeaderImg} className={Classnames(styles.DeleteAccHeaderImg)}/>
                           <h4>{t("PAYMENT_CONFIRM_MODAL.PLAY_LIMITED_TOURNAMENT_ON")} {formatDateForTournament(props.tournamentDetails?.startTime)}</h4>
                        </div>
                     </div>
                     <div className="row p-0">
                        <div className="col-md-12">
                           <p>{t("PAYMENT_CONFIRM_MODAL.HONE_YOUR_SKILLS_AND_TRY_TO_REACH_THE_TOP_ON_THE_LEADERBOARD")}</p>
                        </div>
                     </div>
                  </div>

                  <div className={Classnames(styles.creditsWrapper)}>
                     <div className={Classnames(styles.credits)}>
                        <label>{t("PAYMENT_CONFIRM_MODAL.PRICE")} : </label>
                        <img src={CreditIcon}/>
                        <span>{props.tournamentDetails?.tournamentTicketOmniCredits}</span>
                     </div>
                  </div>

                  {walletDetails?.omniCredits < props.tournamentDetails?.tournamentTicketOmniCredits ?
                     <div className="row p-0">
                        <div className="col-md-12">
                           <p>{t("PAYMENT_CONFIRM_MODAL.YOU_DONT_HAVE_SUFFICIENT_BALANCE_TO_ENTER_TO_THE_TOURNAMENT_TRY_RECHARGING_YOUR_ACCOUNT")}</p>
                        </div>
                     </div> : <></>
                  }

                  <div className={Classnames(styles.photoModifyModelBtnRow, "p-3")}>
                     <button type="button" className={styles.BlackSkBtn}
                             onClick={props.handleClose}>
                        <span>{t("PAYMENT_CONFIRM_MODAL.CANCEL")}</span>
                     </button>

                     {isAvailableStatusLoaded ? <>
                        {isPurchased ?
                           navigateButtonHandler() : <>
                              {walletDetails?.omniCredits < props.tournamentDetails?.tournamentTicketOmniCredits ?
                                 <button type="button" className={styles.LoginButton} onClick={rechargeWallet}>{t("PAYMENT_CONFIRM_MODAL.RECHARGE")}</button> :
                                 <>
                                    {cartCreationLoading ?
                                       <button type="button" className={styles.LoginButton}><Puff height={'2em'} strokeWidth={5} speed={2}/></button> :
                                       <button type="button" className={styles.LoginButton} onClick={() => purchaseTournament(props.tournamentDetails)}>{t("PAYMENT_CONFIRM_MODAL.CONFIRM")}</button>
                                    }
                                 </>
                              }
                           </>
                        }
                     </> : <button type="button" className={styles.LoginButton}><Puff height={'2em'} strokeWidth={5} speed={2}/></button>
                     }
                  </div>
               </div>
            </div>
         </div>

         {isCreditPurchaseModalOpen ?
            <CreditPackagesModal handleClose={closeModal} handleProceedClose={openSequenceLoginModal}/> : <></>}

         {isSequenceLoginModalOpen ?
            <SequenceLoginModal handleClose={closeModal} handleGoBack={handleGoBack} packageId={packageId} amount={purchaseMaticAmount} transactionStatus={handleTransactionStatus}/> : <></>}

         {isTransactionStatusModalOpen && transactionStatus != null ?
            <CreditPurchaseStatusModal transactionStatus={transactionStatus} transactionAmount={transactionAmount} transactionHash={transactionHash} closeAllModals={closeAllModals} sprtsAmount={sprtsAmount}></CreditPurchaseStatusModal>
            : <></>}
      </React.Fragment>
   );
}

const mapStateToProps = (state) => {
   return {
      token: state.auth.accessToken,
      isAuthenticated: state.auth.accessToken !== null,
      profileImage: state.auth.imageURL,
      oAuthProviderType: state.auth.oAuthProviderType,
      userName: state.auth.userName,
      name: state.auth.name
   };
};

export default connect(mapStateToProps)(PaymentConfirmModal);
