import classNames from "classnames";
import Styles from "./WinPrizesSection.module.scss";
import OmniPoints from "../../../assets/images/icons/24_xp.webp";
import Credit from "../../../assets/images/icons/24_sprts_token.webp";
import React, {useEffect, useState} from "react";
import * as LeaderBoardService from "../../../services/LeaderBoardService";
import {connect} from "react-redux";
import * as TournamentService from "../../../services/TournamentService";
import {getProfileImage} from "../../../helpers/LeaderBoardHelpers";
import {formatNumberToKWithoutRounding, getPlayerShowingName, getRandomPlaceHolderImage} from "../../../helpers/CommonFunctions";
import {useTranslation} from "react-i18next";

const WinPrizesSection = (props) => {
   const [winners, setWinners] = useState();
   const [rewardsCategory, setRewardsCategory] = useState();
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);
   const [placeHolderImage] = useState(Array(3).fill(0).map(() => getRandomPlaceHolderImage()));

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);


   const fetchTournamentData = async () => {
      try {
         const leaderBoardResponse = await LeaderBoardService.getTournamentLeaderBoard(0, 9, props.tournament.id, 'rank', "asc", props.Token);
         if (leaderBoardResponse.apiStatus) {
            if (leaderBoardResponse?.results) {
               setWinners(leaderBoardResponse?.results);
               console.log(leaderBoardResponse?.results);
            }
         }
      } catch (error) {
         console.error("An error occurred:", error);
      }
   };

   useEffect(() => {
      fetchTournamentData();
   }, []);

   useEffect(() => {
      const fetchData = async () => {
         await TournamentService.getUserRewardCategory(props.tournament.id)
            .then((response) => {
               if (response.apiStatus && response.rewardCategoryList) {
                  setRewardsCategory(response.rewardCategoryList);
               }
            })
      };
      fetchData();
   }, [props.tournament?.id]);

   function getRewardForPosition(position, rewardCategoryList, winner) {

      if (!props.tournament.tournamentTicketOmniCredits) {
         return (winner?.points / 250).toFixed(2);
      }

      if (!Array.isArray(rewardCategoryList)) {
         return 0;
      }

      for (const category of rewardCategoryList) {
         const {minRank, maxRank, reward} = category.userRanking;
         if (position >= minRank && position <= maxRank) {
            if (props.tournament.tournamentTicketOmniCredits) {
               return formatNumberToKWithoutRounding(reward, 2);
            } else {
               return (winner?.points / 1000).toFixed(2);
            }
         }
      }
      return 0;
   }

   return (
      <div className="row p-0">
         <div className="col p-0">
            <div className={classNames(Styles.OverviewWrapper)}>
               <div className={classNames(Styles.WinPrizes)}>
                  <div className={classNames(Styles.Conner1)}></div>
                  <div className={classNames(Styles.Conner2)}></div>
                  <div className={classNames(Styles.Conner3)}></div>
                  <div className={classNames(Styles.Conner4)}></div>
                  <div className={classNames(Styles.Shade1)}></div>
                  <div className={classNames(Styles.Shade2)}></div>
                  <div className={classNames(Styles.Shade3)}></div>
                  <div className={classNames(Styles.Shade4)}></div>
                  <div className={classNames(Styles.WinPrizesWrapper)}>

                     <button className={"FantasyLeaderboardGrayButton color-white mb-3"} onClick={props?.onBtnClick}>
                        <span>Fantasy Leaderboard</span>
                     </button>

                     {/* Winner */}
                     {winners?.slice(0, 3).map(winner => {
                        const winnerName = getPlayerShowingName(winner).split(' ');

                        return (
                           <div className={classNames(Styles.WinnerWrapper, Styles.WinnerWrapperCol)} key={winner.position}>
                              <div className="row">
                                 <div className={classNames(Styles.WinnerProfilePic, winner?.points == 0 || !winner?.profilePicUrl ? Styles.noProfilePic : '')}>
                                    {winner?.points == 0 ?
                                       <div className={classNames(Styles.EmptyProfilePic)}
                                            style={{backgroundImage: `url(${placeHolderImage[winner.position - 1]})`}}></div> :
                                       getProfileImage(winner?.profilePicUrl, winnerName[0], winnerName[1])}
                                    {/* {winner?.points == 0 ? <img src={questionCircle}/> : getProfileImage(winner?.profilePicUrl, winnerName[0], winnerName[1])} */}
                                 </div>
                                 <h4>{winner?.points == 0 ? t("MODAL.TBA") : winnerName[0]}</h4>
                                 <div className={classNames(Styles.WinnerPointsWrapper)}>
                                    <div>
                                       <span className="body-text5 color-gray2">{t("MODAL.POINTS")}:</span>
                                       <div className={classNames(Styles.IconWithValues)}><img src={OmniPoints}/>{formatNumberToKWithoutRounding(winner?.points, 0)}</div>
                                    </div>
                                    <div className={classNames(Styles.WinnerPlace)}>
                                       <span>{winner?.position}</span>
                                    </div>
                                    <div>
                                       <span className="body-text5 color-gray2">{t("MODAL.REWARDS")}:</span>
                                       <div className={classNames(Styles.IconWithValues)}><img
                                          src={Credit}/>&nbsp;{props.tournament.tournamentTicketOmniCredits ? getRewardForPosition(winner?.position, rewardsCategory, winner) :
                                          winner?.points == 0 ? '0' : getRewardForPosition(winner?.position, rewardsCategory, winner)}</div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        )
                     })}
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      email: state.auth.email,
      Token: state.auth.accessToken,
      RefreshToken: state.auth.refreshToken,
      timeOut: state.auth.sessionTimeout,
   };
};

export default connect(mapStateToProps)(WinPrizesSection)
