import {createChatBotMessage} from 'react-chatbot-kit';
import ButtonList from "./botComponents/DogPicture";
import InitialButtonList from "./botComponents/InitialButtonList";

const botName = 'META11 QA';

const config = {
   initialMessages: [createChatBotMessage(`Hi! I'm ${botName} say hi to get started..`)],
   botName: botName,
   customStyles: {
      botMessageBox: {
         backgroundColor: '#376B7E',
      },
      chatButton: {
         backgroundColor: '#5ccc9d',
      },
   },
   widgets: [
      {
         widgetName: 'DogPicture',
         widgetFunc: (props) => <ButtonList {...props} />,
      },
      {
         widgetName: 'InitialButtonList',
         widgetFunc: (props) => <InitialButtonList {...props} />,
      },
   ]
};

export default config;
