import React, {useEffect, useState} from "react";
import styles from "./PlayerProfile.module.scss";
import NavbarSecondary from "../../components/NavigationBar/NavigationBar";
import classNames from "classnames";
import {BsChevronLeft} from "react-icons/bs";
import HeartDefault from "../../assets/images/HeartDefault.svg";
import HeartActive from "../../assets/images/HeartActive.svg";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import * as FavouriteService from "../../services/FavouriteService";
import * as StatisticsService from "../../services/StatisticsService";
import * as DigitalAssetService from "../../services/DigitalAssetService"
import {getPlayerLatestNews} from "../../services/DigitalAssetService"
import Footer from "../../components/Footer/Footer";
import {connect} from "react-redux";
import batsman from "../../assets/images/bat.svg";
import baller from "../../assets/images/ball.svg";
import {BattingStyleEN, BattingStyleHI, BattingStylesEN, BattingStylesHI, BowlingStylesEN, BowlingStylesHI, FavLengthEN, FavLengthHI, PlayerHittingRanks, PlayingHandEN, PlayingHandHI, PlayingRolesEN, PlayingRolesHI, StrikeRateEN, StrikeRateHI} from "../../utilities/Enums";
import {toast} from "react-toastify";
import {isExistChecker} from "../../helpers/CommonFunctions";
import * as actions from "../../store/actions";
import {useTranslation} from "react-i18next";
import GuidanceTip from "../../components/GuidanceTip/GuidanceTip";
import {ADVERTISEMENT_IMAGES, SITE_LANGUAGE} from "../../utilities/Constants";
import Bat from "../../assets/images/NowPlaying_bat.webp";
import Ball from "../../assets/images/NowPlaying_ball.webp";
import AdvertisementSpace from "../../components/AdvertisementSpace/AdvertisementSpace";

const PlayerProfile = (props) => {

   const location = useLocation();
   const currentUrl = location.pathname + location.search;
   const returnUrl = new URLSearchParams(location.search).get('returnUrl');
   let {playerId} = useParams();
   let navigate = useNavigate();
   const [playerData, setPlayerData] = useState({});
   const [playerStat, setPlayerStat] = useState({});
   const [playerProfileData, setPlayerProfileData] = useState({});
   const [playersData, setPlayersData] = useState([]);
   const [playerHistory, setPlayerHistory] = useState([]);
   const [bowlingRankings, setBowlingRankings] = useState([]);
   const [battingRankings, setBattingRankings] = useState([]);
   const [favouritePlayers, setFavouritePlayers] = useState([]);
   const [favouriteLoading, setFavouriteLoading] = useState(false);
   const [playerNews, setPlayerNews] = useState([]);
   const [user, setUser] = useState({});
   let [loadingInProgress, setLoading] = useState(true);
   const [totalFavouritePlayers, setTotalFavouritePlayers] = useState(0);
   const [totalNews, setTotalNews] = useState(0);
   const [viewAllFavouritePlayers, setViewAllFavouritePlayers] = useState(true);
   const [viewAllNews, setViewAllNews] = useState(true);
   const [loadingNews, setLoadingNews] = useState(true);
   const [loadingFavouritePlayers, setLoadingFavouritePlayers] = useState(true);
   const [pageLang, setPageLang] = useState(localStorage.getItem('language'));

   const handleReturn = () => {
      if (returnUrl) {
         navigate(returnUrl);
      } else {
         navigate('/players');
      }
   };

   const resPlayers = (page, size) => {
      setLoadingFavouritePlayers(true);
      StatisticsService.getAllPlayersFromStat(page, size).then((response) => {
         if (response?.apiStatus == true) {
            setPlayersData(response?.players)
            setTotalFavouritePlayers(response?.totalElements)
         }
         setLoadingFavouritePlayers(false);
      }).catch(err => console.log(err))
   }

   const playerNewsResponse = (page, size) => {
      setLoadingNews(true)
      DigitalAssetService.getPlayerLatestNews(playerId, page, size, "id,desc").then((response) => {
         if (response?.apiStatus == true) {
            setPlayerNews(response?.playerNews)
            setTotalNews(response?.totalElements)
         }
         setLoadingNews(false)
      }).catch(err => console.log(err));
   }

   useEffect(() => {
      window.scrollTo({top: 0, behavior: "smooth"});
   }, [playerId])

   useEffect(() => {
      setLoading(true);
      const fetchDetails = async () => {
         if (props.isAuthenticated) {
            FavouriteService.getFavourites(props.Token, "player", props.userName, 0, 100).then((response) => {
               if (response.data.apiStatus == true) {
                  setFavouritePlayers(response?.data?.userFavourites);
                  setLoading(false);
               } else if (response.status == 401) {
                  setLoading(false);
                  //toast.error(COMMON_TOASTS.UNAUTHORIZED_REQUEST);
               }
            }).catch(err => console.log(err))
         }

         const playerHistoryResponse = StatisticsService.getPlayerMatchHistory(playerId, 4);
         playerHistoryResponse.then((response) => {
               if (response.apiStatus == true) {
                  setPlayerHistory(response?.history);
                  setLoading(false);
               }
            }
         ).catch(err => console.log(err))

         DigitalAssetService.getPlayerProfileById(playerId, pageLang).then((response) => {
            if (response?.apiStatus == true) {
               setPlayerData(response?.playerProfileDTO);
               setPlayerStat(response?.playerProfileDTO?.playerStat);
               setLoading(false);
            }
         }).catch(err => console.log(err))

         DigitalAssetService.getPlayerCardById(playerId, pageLang)
            .then((response) => {
                  if (response?.apiStatus == true) {
                     setPlayerProfileData(response?.player)
                     setLoading(false);
                  }
               }
            )

         resPlayers(0, 3)
         playerNewsResponse(0, 3)
         favouritePlayerController();

      };
      fetchDetails();

   }, [playerId]);

   useEffect(() => {
      setLoading(true);
      const getBattingRankings = async () => {
         await StatisticsService.getBattingRanks(0, 9, "battingPoints,desc").then(
            (response) => {
               if (response.apiStatus) {
                  setBattingRankings(response?.battingRankDTOS);
                  setLoading(false);
               } else {
                  console.log("Error getting Player Batting Rankings.");
                  setLoading(false);
               }
            }
         );
      };
      getBattingRankings();
   }, [playerId, user]);

   useEffect(() => {
      setLoading(true);
      const getBowlingRankings = async () => {
         await StatisticsService.getBowlingRanks(0, 9, "bowlingPoints,desc").then(
            (response) => {
               if (response?.apiStatus) {
                  setBowlingRankings(response?.playerBowlingRankDTOList);
                  setLoading(false);
               } else {
                  console.log("Error getting Player Bowling Rankings.");
                  setLoading(false);
               }
            }
         );
      };
      getBowlingRankings();
   }, [playerId, user]);

   useEffect(() => {
      playerProfileData?.playerId ? setLoading(false) : setLoading(true);
   }, [playerProfileData]);

   const updateFavouritePlayer = async (isFavourite, playerName, playerId) => {

      if (isFavourite == true && favouritePlayers?.length > 0) {
         setFavouritePlayers(prevState => [...prevState, {id: playerId, name: playerName}]);
      } else if (isFavourite == false && favouritePlayers?.length > 0) {
         setFavouritePlayers((current) => current.filter((player) => player.id != playerId))
      } else if (isFavourite == true && favouritePlayers?.length == 0) {
         setFavouritePlayers([{id: playerId, name: playerName}]);
      } else if (isFavourite == false && favouritePlayers?.length == 0) {

      }
      if (playerName != undefined || playerName != null) {
         const toastId = toast.loading(`${isFavourite ? t("PLAYER.ADD") : t("PLAYER.REMOVE")} ${t("PLAYER.PLAYER")} ${playerName} ${isFavourite ? t("PLAYER.TO") : t("PLAYER.FROM")} ${t("PLAYER.FAVOURITE")}`)

         await FavouriteService.updateFavoritePlayer(props.Token, {
            id: parseInt(playerId),
            like: isFavourite,
            name: playerName,
            userName: props.userName,
         }).then((response) => {
            if (response.data.apiStatus) {
               setUser(response.data.user);
               toast.update(toastId, {
                  render: `${t("PLAYER.SUCCESSFULLY")} ${isFavourite ? t("PLAYER.ADDED") : t("PLAYER.REMOVED")} ${t("PLAYER.THE_PLAYER")} ${playerName} ${isFavourite ? t("PLAYER.TO") : t("PLAYER.FROM")} ${t("PLAYER.FAVOURITES")}`,
                  type: toast.TYPE.SUCCESS,
                  autoClose: 5000,
                  closeButton: null,
                  isLoading: false
               })
            } else if (response.status == 401) {
               setFavouritePlayers((current) => current.filter((player) => player.id != playerId))
               //toast.error(COMMON_TOASTS.UNAUTHORIZED_REQUEST);
               toast.update(toastId, {
                  render: `${t("PLAYER.ERROR_UPDATING_FAVOURITE_PLAYER")}`,
                  type: toast.TYPE.ERROR,
                  autoClose: 5000,
                  closeButton: null,
                  isLoading: false
               })
            } else {
               // toast.error(response.rejectMessages[0]);
               setFavouritePlayers((current) => current.filter((player) => player.id != playerId))

               toast.update(toastId, {
                  render: `${t("PLAYER.ERROR_UPDATING_FAVOURITE_PLAYER")}`,
                  type: "error",
                  autoClose: 5000,
                  closeButton: null,
                  isLoading: false
               })
            }
         });
      } else {
         console.log("Error occurred in marking Favourite Player.");
      }
   };
   const playerRoleStyleController = (role) => {
      if (pageLang == SITE_LANGUAGE.ENGLISH) {
         if (BowlingStylesEN.hasOwnProperty(role)) {
            return BowlingStylesEN[role];
         } else if (BattingStylesEN.hasOwnProperty(role)) {
            return BattingStylesEN[role];
         } else if (PlayingRolesEN.hasOwnProperty(role)) {
            return PlayingRolesEN[role];
         } else {
            return "N/A"
         }
      } else if (pageLang == SITE_LANGUAGE.HINDI) {
         if (BowlingStylesHI.hasOwnProperty(role)) {
            return BowlingStylesHI[role];
         } else if (BattingStylesHI.hasOwnProperty(role)) {
            return BattingStylesHI[role];
         } else if (PlayingRolesHI.hasOwnProperty(role)) {
            return PlayingRolesHI[role];
         } else {
            return "N/A"
         }
      }
   };

   const playerStyleController = (role) => {
      if (pageLang == SITE_LANGUAGE.ENGLISH) {
         if (BattingStyleEN.hasOwnProperty(role)) {
            return BattingStyleEN[role];
         } else {
            return "N/A"
         }
      } else if (pageLang == SITE_LANGUAGE.HINDI) {
         if (BattingStyleHI.hasOwnProperty(role)) {
            return BattingStyleHI[role];
         } else {
            return "N/A"
         }
      }
   };

   const BattingBowlingRateController = (role, pageLang) => {
      if (pageLang == "hi") {
         if (StrikeRateHI.hasOwnProperty(role)) {
            return StrikeRateHI[role];
         } else {
            return "एन.ए."
         }
      } else {
         if (StrikeRateEN.hasOwnProperty(role)) {
            return StrikeRateEN[role];
         } else {
            return "N/A"
         }
      }

   };

   const PlayingHandController = (role, pageLang) => {
      if (pageLang == "hi") {
         if (PlayingHandHI.hasOwnProperty(role)) {
            return PlayingHandHI[role];
         } else {
            return "एन.ए."
         }
      } else {
         if (PlayingHandEN.hasOwnProperty(role)) {
            return PlayingHandEN[role];
         } else {
            return "N/A"
         }
      }

   };

   const BowlingLengthController = (role, pageLang) => {
      if (pageLang == "hi") {
         if (FavLengthHI.hasOwnProperty(role)) {
            return FavLengthHI[role];
         } else {
            return "एन.ए."
         }
      } else {
         if (FavLengthEN.hasOwnProperty(role)) {
            return FavLengthEN[role];
         } else {
            return "N/A"
         }
      }

   };


   const playerRoleIcon = (playerRole) => {
      if (playerRole.includes("BATSMAN")) {
         return <img src={batsman} style={{position: "absolute", left: 0}}/>
      } else if (playerRole.includes("BOWLER")) {
         return <img src={baller} style={{position: "absolute", left: 0}}/>
      } else {
         return ""
      }
   }
   const favouritePlayerController = (playerName, playerID) => {
      if (isExistChecker(favouritePlayers, playerID)) {
         return (
            <Link to={currentUrl}>
               <button onClick={() => updateFavouritePlayer(false, playerName, playerID)} className="heartLikeFill" style={{top: 15, right: 15}}>
                  <img src={HeartActive} alt="HeartActive" className="heartActive"/>
               </button>
            </Link>
         );
      } else {
         return (
            <Link to={currentUrl}>
               <button onClick={() => updateFavouritePlayer(true, playerName, playerID)} className="heartLike" style={{top: 15, right: 15}}>
                  <img src={HeartDefault} alt="HeartDefault" className="heartDefault"/>
               </button>
            </Link>
         );
      }
   };

   const getPlayerHittingRank = (percentage) => {
      if (percentage >= 80 && percentage <= 100) {
         return PlayerHittingRanks.LEGEND;
      } else if (percentage >= 60 && percentage < 80) {
         return PlayerHittingRanks.CHAMPION;
      } else if (percentage >= 40 && percentage < 60) {
         return PlayerHittingRanks.SKILLED;
      } else if (percentage >= 20 && percentage < 40) {
         return PlayerHittingRanks.NOVICE;
      } else if (percentage >= 0 && percentage < 20) {
         return PlayerHittingRanks.ROOKIE;
      } else {
         return 'Invalid Rank';
      }
   };
   const ViewAll = (type) => {
      if (type == 'latestNews') {
         setViewAllNews(false)
         getPlayerLatestNews(0, (totalNews > 6 ? 6 : totalNews))
      } else if (type == 'favouritePlayers') {
         setViewAllFavouritePlayers(false)
         resPlayers(0, (totalFavouritePlayers > 9 ? 9 : totalFavouritePlayers))
      }
   }
   const ViewLess = (type) => {
      if (type == 'latestNews') {
         setViewAllNews(true)
         getPlayerLatestNews(0, 3)
      } else if (type == 'favouritePlayers') {
         setViewAllFavouritePlayers(true)
         resPlayers(0, 3)
      }
   }

   const getBattingAverage = () => {
      const matches = playerStat?.matches;
      const runs = playerStat?.runs;
      if (matches !== 0 && runs !== 0) {
         return (isNaN((runs / matches).toFixed(2)) ? 0 : (runs / matches).toFixed(2));
      }
      return '--';
   }
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 1000);
   }, []);

   const loadingIndicator = (
      <div className="w-100 text-center">
         <div className={styles.loaderContainer}>
            <div className="lds-facebook">
               <div></div>
               <div></div>
               <div></div>
            </div>
         </div>
      </div>);


   const [nextGotItIndex, setNextGotItIndex] = useState(0)
   const [tipIndexCounter, setTipIndexCounter] = useState(1)

   const gotItHandler = () => {
      setNextGotItIndex(nextGotItIndex + 1)
      setTipIndexCounter(tipIndexCounter + 1)
   }

   const tipCancelHandler = () => {
      setNextGotItIndex(-1)
   }
   return (
      <div className="pb-4">
         <div className={classNames(styles.ResponceCont, "col-12", "row", "ps-2", "pe-2", "ps-xxl-0", "pe-xxl-0")}>
            <div className="col-12 p-0">
               <NavbarSecondary page="matches"/>
            </div>
            {!translationsLoaded ? <>{loadingIndicator}</> : <>
               <div className="row gx-4 mt40 p-0">
                  <div className="col-12 p-0">
                     <button className="blackBtn" onClick={() => handleReturn()}>
                        <BsChevronLeft/>
                        {t("PLAYER.BACK")}
                     </button>
                  </div>
               </div>
               <div className={classNames(styles.PlayerProfilePage, "col-12 p-0")}>
                  <div className="row gx-0">
                     {loadingInProgress ?
                        <div className="col-12 col-xxl-9 col-xl-9 col-lg-12 col-md-12 col-sm-12">
                           <div className={styles.loaderContainer}>
                              <div className="lds-facebook">
                                 <div></div>
                                 <div></div>
                                 <div></div>
                              </div>
                           </div>
                        </div> : <div className="col-12 col-xxl-9 col-xl-9 col-lg-12 col-md-12 col-sm-12">
                           <div className={classNames(styles.mainHeadingInfoWrapper)}>
                              <div className="row gx-0">
                                 <div className="col-12 col-md-4 p-0">
                                    <div className={classNames(styles.profilePicWrapper, "text-right text-center")}>
                                       <img src={playerProfileData?.pictureURL} style={{maxHeight: "257px"}}/>
                                    </div>
                                 </div>
                                 <div className="col-12 col-md-8 ps-0 ps-md-3 pe-0 pe-xl-3">
                                    <div className={classNames(styles.playerSummary, "matchCardInnerWrapper player-wrapper", "p-3", "w-100")}>
                                       <div style={{position: "relative", top: "-20px", right: "-20px", zIndex: 1000}}>
                                          {props.isAuthenticated ? <>
                                             {favouritePlayerController(playerProfileData?.playerName, playerProfileData?.playerId)}
                                          </> : <></>}
                                       </div>
                                       <div className="teamDetail teamsTeamDetail w-100">
                                          <div className="row gx-0 m-0">
                                             <div className="col text-start">
                                                <div className={classNames(styles.playerNameHeader, "row gx-0 m-0")}>
                                                   <div className="col-md-6-">
                                                      <h2 className={classNames(styles.playerNameLbl)}>{playerProfileData?.playerName} <span className={classNames(styles.AgeLbl)}>{playerData?.age}yrs</span></h2>
                                                   </div>
                                                   <div className="col-md-6-">
                                                      <div className={classNames(styles.progressBarWrapper, "progressBarWrapper")}>
                                                         <span className="playerRole">{t("PLAYERS.HITTING_POWER")}</span>
                                                         <span className="playerLevel">{getPlayerHittingRank(playerProfileData?.hittingPower)}</span>
                                                         <span className="progressGroove">
                                                                                    <span className="progresStick" style={{width: `${playerProfileData?.hittingPower}%`}}>
                                                                                    <span className="progressDot"></span>
                                                                                    </span>
                                                                                 </span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>

                                             <div className={classNames(styles.Row2, "row gx-0 mt-10")}>
                                                <div className="col-md-4 pe-2">

                                                   <label className={classNames(styles.roleLbl)}>{t("PLAYER.REALM")}</label>
                                                   <label className={classNames(styles.roleVal)}>
                                                      {playerData?.nationality}
                                                   </label>

                                                </div>
                                                <div className="col-md-4 pe-2">

                                                   <label className={classNames(styles.roleLbl)}>{t("PLAYER.BOWLING")}</label>
                                                   <label className={classNames(styles.roleVal)}>{playerRoleStyleController(playerData?.bowlingStyle)}</label>

                                                </div>
                                                <div className="col-md-4">

                                                   <label className={classNames(styles.roleLbl)}>{t("PLAYER.BATTING")}</label>
                                                   <label className={classNames(styles.roleVal)}>{playerStyleController(playerData?.batterHand)}</label>

                                                </div>
                                             </div>

                                             <div className="col-12 mt-2">
                                                <p className="roleLbl" style={{fontSize: 16, lineHeight: "20px"}}>{playerProfileData?.description}</p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div className="row gx-0">
                                 <div className={classNames(styles.LeftColInfoWrapper, "col-12 col-md-4 p-0")}>
                                    <div className="">

                                       <div className={classNames(styles.indicatorWrap, styles.borderTop2, "p-3 sub-section-match")}>
                                          <div>
                                                <span className="shedmatchplayersubwrap">
                                                   <label className="body-text5 color-white upper-case heading-italic">{t("SCHEDULED_MATCH_SECTION.SHARPNESS")}</label>
                                                   <label className={classNames(styles.playerattrScoreTxt, "body-text4 playerattrScore upper-case heading-italic")}>{playerData?.playerAbilities?.intellect ? `${playerData?.playerAbilities?.["intellect"]}/10` : '--'}</label>
                                                </span>
                                             <span className="shedmatchplayersubwrap">
                                                      <label className="body-text5 color-white upper-case heading-italic">{t("SCHEDULED_MATCH_SECTION.AGILITY")}</label>
                                                      <label className={classNames(styles.playerattrScoreTxt, "body-text4 playerattrScore upper-case heading-italic")}>{playerData?.playerAbilities?.dexterity ? `${playerData?.playerAbilities?.["dexterity"]}/10` : '--'}</label>
                                                </span>
                                             <span className="shedmatchplayersubwrap">
                                                      <label className="body-text5 color-white upper-case heading-italic">
                                                         {t("SCHEDULED_MATCH_SECTION.STRENGTH")}
                                                      </label>
                                                      <label className={classNames(styles.playerattrScoreTxt, "body-text4 playerattrScore upper-case heading-italic")}>{playerData?.playerAbilities?.strength ? `${playerData?.playerAbilities?.["strength"]}/10` : '--'}</label>
                                             </span>
                                             <span className="shedmatchplayersubwrap">
                                                <h3 className="pt-3">{t("SCHEDULED_MATCH_SECTION.SCOUTING_REPORT")}</h3>
                                                </span>
                                             <span className="shedmatchplayersubwrap">
                                                <label className="body-text5 color-white upper-case heading-italic w-100">{playerData?.scoutingPropsList && playerData.scoutingPropsList[0] !== undefined ? playerData.scoutingPropsList[0].scout : "--"}</label>
                                                </span>
                                             <span className="shedmatchplayersubwrap">
                                                <label className="body-text5 color-white upper-case heading-italic w-100">{playerData?.scoutingPropsList && playerData.scoutingPropsList[1] !== undefined ? playerData.scoutingPropsList[1].scout : "--"}</label>
                                                </span>
                                             <span className="shedmatchplayersubwrap">
                                                      <label className="body-text5 color-white upper-case heading-italic w-100">{playerData?.scoutingPropsList && playerData.scoutingPropsList[2] !== undefined ? playerData.scoutingPropsList[2].scout : "--"}</label>
                                                </span>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="pt-4 sub-section-match">
                                       <div className={classNames(styles.indicatorWrap, styles.borderTop2, "p-3 sub-section-match")}>
                                          <h3 className="">{t("PLAYER.BATTING_PROFILE")}</h3>
                                          <p>
                                             {playerData?.battingProfile?.description ? playerData?.battingProfile?.description : '--'}
                                          </p>
                                          <h4>{t("PLAYER.BATTING_HAND")}</h4>
                                          <p>{playerRoleStyleController(playerData?.batterHand)}</p>
                                          <h4>{t("PLAYER.POWER_LEVEL")}</h4>
                                          <p>{BattingBowlingRateController(playerData?.battingProfile?.powerLevel, pageLang)}</p>
                                       </div>
                                    </div>
                                    <div className="pt-4 sub-section-match">
                                       <div className={classNames(styles.indicatorWrap, styles.borderTop2, "p-3 sub-section-match")}>
                                          <h3 className="">{t("PLAYER.BOWLING_PROFILE")}</h3>
                                          <p>
                                             {playerData?.bowlingProfile?.description ? playerData?.bowlingProfile?.description : '--'}
                                          </p>
                                          <h4>{t("PLAYER.BOWLING_HAND")}</h4>
                                          <p>{playerRoleStyleController(playerData?.bowlingStyle)}</p>
                                          <h4>{t("PLAYER.FAVOURITE_LENGTH")}</h4>
                                          <p>{BowlingLengthController(playerData?.bowlingProfile?.favouriteLength, pageLang)}</p>
                                       </div>
                                    </div>
                                 </div>
                                 <div className={classNames(styles.rightColInfoWrapper, "col-12 col-md-8 ps-3 ps-md-3 pe-0 pe-xl-3")}>
                                    <div className="row">
                                       <div className={classNames(styles.StatisticsCards, "col-12 p-0 row pb-3")}>
                                          <GuidanceTip page="playerProfile" gotItIndex={1} gotItHandler={gotItHandler} nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler} tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}/>
                                          <h2 className="simpleHeader">{t("PLAYER.STATISTICS")}</h2>
                                          <div className="col-6 col-md-6 p-0 pe-3 d-flex">
                                             <div className={classNames(styles.OverviewBox, "d-flex flex-column", styles.borderTop2)}>
                                                <div className={classNames(styles.mainText)}>{playerStat?.matches || 0}</div>
                                                <div className={classNames(styles.smallText, "w-100", "mt-2", "mb-1")}>{t("PLAYER.TOTAL_MATCHES_PLAYED")}</div>
                                             </div>
                                          </div>
                                          {/*TODO: check BE property correct or wrong*/}
                                          <div className="col-6 col-md-6 p-0 pe-0 d-flex">
                                             <div className={classNames(styles.OverviewBox, "d-flex flex-column", styles.borderTop3)}>
                                                <div className={classNames(styles.mainText)}>{playerStat?.winPercentage ? playerStat?.winPercentage?.toFixed(2) : 0}</div>
                                                <div className={classNames(styles.smallText, "w-100", "mt-2", "mb-1")}>{t("PLAYER.WIN_PERCENTAGE")}</div>
                                             </div>
                                          </div>
                                          <div className="col-6 col-md-6 p-0 pe-3 d-flex">
                                             <div className={classNames(styles.OverviewBox, "d-flex flex-column", styles.borderTop1)}>
                                                <div className={classNames(styles.mainText)}>{playerStat?.runs || 0}</div>
                                                <img src={Bat} className={classNames(styles.StatIcon)}/>
                                                <div className={classNames(styles.smallText, "w-100", "mt-2", "mb-1")}>{t("PLAYER.TOTAL_RUNS")}</div>
                                             </div>
                                          </div>

                                          <div className="col-6 col-md-6 p-0 pe-0 d-flex">
                                             <div className={classNames(styles.OverviewBox, "d-flex flex-column", styles.borderTop1)}>
                                                <div className={classNames(styles.mainText)}>{playerStat?.strikeRate ? playerStat?.strikeRate.toFixed(2) : 0}</div>
                                                <img src={Bat} className={classNames(styles.StatIcon)}/>
                                                <div className={classNames(styles.smallText, "w-100", "mt-2", "mb-1")}>{t("PLAYER.BATTING_STRIKE_RATE")}</div>
                                             </div>
                                          </div>
                                          <div className="col-6 col-md-6 p-0 pe-3 d-flex">
                                             <div className={classNames(styles.OverviewBox, "d-flex flex-column", styles.borderTop2)}>
                                                <div className={classNames(styles.mainText)}>{getBattingAverage()}</div>
                                                <img src={Bat} className={classNames(styles.StatIcon)}/>
                                                <div className={classNames(styles.smallText, "w-100", "mt-2", "mb-1")}>{t("PLAYER.BATTING_AVERAGE_PER_MATCH")}</div>
                                             </div>
                                          </div>

                                          <div className="col-6 col-md-6 p-0 pe-0 d-flex">
                                             <div className={classNames(styles.OverviewBox, "d-flex flex-column", styles.borderTop2)}>
                                                <div className={classNames(styles.mainText)}>{playerStat?.sixes || 0}</div>
                                                <img src={Bat} className={classNames(styles.StatIcon)}/>
                                                <div className={classNames(styles.smallText, "w-100", "mt-2", "mb-1")}>{t("PLAYER.TOTAL_SIXES")}</div>
                                             </div>
                                          </div>
                                          <div className="col-6 col-md-6 p-0 pe-3 d-flex">
                                             <div className={classNames(styles.OverviewBox, "d-flex flex-column", styles.borderTop3)}>
                                                <div className={classNames(styles.mainText)}>{playerStat?.fours || 0}</div>
                                                <img src={Bat} className={classNames(styles.StatIcon)}/>
                                                <div className={classNames(styles.smallText, "w-100", "mt-2", "mb-1")}>{t("PLAYER.TOTAL_FOURS")}</div>
                                             </div>
                                          </div>

                                          <div className="col-6 col-md-6 p-0 pe-0 d-flex">
                                             <div className={classNames(styles.OverviewBox, "d-flex flex-column", styles.borderTop1)}>
                                                <div className={classNames(styles.mainText)}>{playerStat?.wickets || '--'}</div>
                                                <img src={Ball} className={classNames(styles.StatIcon)}/>
                                                <div className={classNames(styles.smallText, "w-100", "mt-2", "mb-1")}>{t("PLAYER.TOTAL_WICKETS")}</div>
                                             </div>
                                          </div>
                                          <div className="col-6 col-md-6 p-0 pe-3 d-flex">
                                             <div className={classNames(styles.OverviewBox, "d-flex flex-column", styles.borderTop3)}>
                                                <div className={classNames(styles.mainText)}>{playerStat?.economy ? playerStat?.economy?.toFixed(2) : 0}</div>
                                                <img src={Ball} className={classNames(styles.StatIcon)}/>
                                                <div className={classNames(styles.smallText, "w-100", "mt-2", "mb-1")}>{t("PLAYER.BOWLING_ECONOMY")}</div>
                                             </div>
                                          </div>
                                          {/*TODO: check BE property correct or wrong*/}
                                          <div className="col-6 col-md-6 p-0 pe-0 d-flex">
                                             <div className={classNames(styles.OverviewBox, "d-flex flex-column", styles.borderTop2)}>
                                                <div className={classNames(styles.mainText)}>{playerStat?.bowlingAverage ? playerStat?.bowlingAverage?.toFixed(2) : '--'}</div>
                                                <img src={Ball} className={classNames(styles.StatIcon)}/>
                                                <div className={classNames(styles.smallText, "w-100", "mt-2", "mb-1")}>{t("PLAYER.BOWLING_AVERAGE")}</div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                    {/*<div className="row">*/}
                                    {/*   <div className={classNames(styles.StatisticsCards,"col-12 p-0 row pb-3")}>*/}
                                    {/*      <h2 className="simpleHeader">{t("PLAYER.1_VS_1_STATISTICS")}</h2>*/}
                                    {/*      <div className="col-6 col-md-6 p-0 pe-3 pe-md-3 mt-3 d-flex">*/}
                                    {/*               <div className={classNames(styles.OverviewBox, "d-flex flex-column", styles.borderTop3)}>*/}
                                    {/*                  <div className={classNames(styles.mainText)}>7</div>*/}
                                    {/*                  <div className={classNames(styles.smallText, "w-100", "mt-2", "mb-1")}>{t("PLAYER.MATCHES_PLAYED")}</div>*/}
                                    {/*               </div>*/}
                                    {/*      </div>*/}
                                    {/*      <div className="col-6 col-md-6 p-0 pe-3 pe-md-3 mt-3 d-flex">*/}
                                    {/*               <div className={classNames(styles.OverviewBox, "d-flex flex-column", styles.borderTop3)}>*/}
                                    {/*                  <div className={classNames(styles.mainText)}>1</div>*/}
                                    {/*                  <div className={classNames(styles.smallText, "w-100", "mt-2", "mb-1")}>{t("PLAYER.MATCHES_WON")}</div>*/}
                                    {/*               </div>*/}
                                    {/*      </div>*/}
                                    {/*      <div className="col-6 col-md-6 p-0 pe-3 pe-md-3 mt-3 d-flex">*/}
                                    {/*               <div className={classNames(styles.OverviewBox, "d-flex flex-column", styles.borderTop3)}>*/}
                                    {/*                  <div className={classNames(styles.mainText)}>0</div>*/}
                                    {/*                  <div className={classNames(styles.smallText, "w-100", "mt-2", "mb-1")}>{t("PLAYER.MATCHES_DRAWN")}</div>*/}
                                    {/*               </div>*/}
                                    {/*      </div>*/}
                                    {/*      <div className="col-6 col-md-6 p-0 pe-3 pe-md-3 mt-3 d-flex">*/}
                                    {/*               <div className={classNames(styles.OverviewBox, "d-flex flex-column", styles.borderTop3)}>*/}
                                    {/*                  <div className={classNames(styles.mainText)}>6</div>*/}
                                    {/*                  <div className={classNames(styles.smallText, "w-100", "mt-2", "mb-1")}>{t("PLAYER.MATCHES_LOST")}</div>*/}
                                    {/*               </div>*/}
                                    {/*      </div>*/}
                                    {/*   </div>*/}
                                    {/*</div>*/}

                                    {/*<div className="row">*/}
                                    {/*   <div className={classNames(styles.LastMatchOutcomes,"col-12 p-0 row pb-3")}>*/}
                                    {/*      <h2 className="simpleHeader">{t("PLAYER.LAST_5_MATCH_OUTCOMES")}</h2>*/}
                                    {/*      <div className={classNames(styles.OverviewBox)}>*/}
                                    {/*         <div>*/}
                                    {/*            <img src={RedBall}/>*/}
                                    {/*            <img src={GreenBall}/>*/}
                                    {/*            <img src={GreenBall}/>*/}
                                    {/*            <img src={RedBall}/>*/}
                                    {/*            <img src={GreenBall}/>*/}
                                    {/*         </div>*/}
                                    {/*      </div>*/}
                                    {/*   </div>*/}
                                    {/*</div>*/}
                                 </div>
                              </div>
                           </div>
                        </div>}
                     <AdvertisementSpace
                        imageUrl1={ADVERTISEMENT_IMAGES.BANNER_1}
                        imageUrl2={ADVERTISEMENT_IMAGES.BANNER_2}/>
                  </div>
               </div>
            </>}

         </div>
         {!translationsLoaded ? <></> :
            <Footer/>
         }
      </div>
   );
};

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      profileImage: state.auth.imageURL,
      userId: state.auth.userId,
      userName: state.auth.userName,
      email: state.auth.email,
      Token: state.auth.accessToken,
      RefreshToken: state.auth.refreshToken,
      timeOut: state.auth.sessionTimeout,
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      onLogout: () => dispatch(actions.logout()), onRefreshAuth: (accessToken, refreshToken) => dispatch(actions.refreshSession(accessToken, refreshToken)),
   };
};
export default connect(mapStateToProps, mapDispatchToProps)(PlayerProfile);
