import config from './config.js';
import MessageParser from './MessageParser';
import ActionProvider from './ActionProvider';
import Chatbot from 'react-chatbot-kit';

const ChatBot = () => {
   return (
      <div>
         <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
         />
      </div>
   );
};

export default (ChatBot);
