// new file called DogPicture.jsx
import React from 'react';

const InitialButtonList = (props) => {

   const handleClick = (option) => {
      props.actionProvider.actions.showValues(option);
   }

   return (
      <div>
         <button value={'option1'} onClick={() => handleClick('option1')}>option 1</button>
         <button value={'option2'} onClick={() => handleClick('option2')}>option 2</button>
         <button value={'option3'} onClick={() => handleClick('option3')}>option 3</button>

      </div>
   );
};

export default InitialButtonList;
