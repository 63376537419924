import React, {useEffect, useState} from "react";
import Classnames from "classnames";
import styles from "../../Wallet/Purchase/CreditPackagesModal/CreditPackagesModal.module.scss";
import {getFantasyRules} from "../../../services/FantasyService";
import {t} from "i18next";

const HowFantasyWorksModal = ({handleModalClose}) => {
   const [loading, setLoading] = useState(false);
   const [fantasyRules, setFantasyRules] = useState([]);

   useEffect(() => {
      const fetchFantasyRules = async () => {
         setLoading(true);

         getFantasyRules().then((response) => {
            if (response?.apiStatus && response?.fantasyRules) {
               setFantasyRules(response.fantasyRules);
            }
         }).catch((error) => {
            console.error(error);
         }).finally(() => {
            setLoading(false);
         });
      }

      fetchFantasyRules();
   }, []);

   const loadingIndicator = (<div style={{textAlign: "center"}}>
      <div className="lds-facebook">
         <div></div>
         <div></div>
         <div></div>
      </div>
   </div>);

   return (<div
      className={Classnames("modal fade show", styles.modalBlur, styles.PaymentConfirmmodal, styles.FantasyPointCalculation)}
      id="exampleModalCenter"
      tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"
      style={{display: "block", zIndex: "1000"}}>
      <div className={Classnames(styles.CreditPurchaseModal, "modal-dialog modal-dialog-centered")} role="document">
         <div className={Classnames("modal-content", styles.modalContentContainer)}>
            <button className={Classnames(styles.closeButton, "close z-3")} type="button" onClick={handleModalClose}
                    data-dismiss="modal" aria-label="Close">
                   <span className={styles.closeButton} aria-hidden="true">
                      <img alt='Close Icon' src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/>
                   </span>
            </button>
            <div className={Classnames(styles.UserProfileModalWrapper)}>
               <div className={Classnames(styles.Radial1)}></div>
               <div className={Classnames(styles.Radial2)}></div>
               <div className={Classnames(styles.Radial3)}></div>
               <div className={Classnames(styles.EdgeRadial2)}></div>

               <div className={Classnames(styles.ellipse, styles.eMulticolorHalf)}></div>
               <div className={Classnames(styles.ellipse, styles.e392)}></div>
               <div className="row p-0">
                  <div className={Classnames(styles.UserProfileModal, "col-md-12")}>
                     <div className={Classnames(styles.TitleWrapper)}>
                        <h4>{t("FANTASY.FANTASY_POINT_CALCULATION")}</h4>
                     </div>
                  </div>
               </div>
               <div className="row p-0">
                  <div className={Classnames(styles.UserProfileModal, "col-md-12")}>
                     {loading ? loadingIndicator :
                        <table>
                           <thead>
                           <tr>
                              <th>{t("FANTASY.RULE")}</th>
                              <th>{t("FANTASY.IF")}<br/>{t("FANTASY.PLAYER")}(x1)</th>
                              <th>{t("FANTASY.IF")}<br/>{t("FANTASY.CAPTAIN")}(x2)</th>
                              <th>{t("FANTASY.IF")}<br/>{t("FANTASY.V_CAPTAIN")}(x1.5)</th>
                           </tr>
                           </thead>
                           <tbody>
                           {fantasyRules.map((rule, index) => {
                              return (
                                 <tr key={index}>
                                    <td>{rule.description}</td>
                                    <td>{rule.playerPoints}</td>
                                    <td>{rule.captainPoints}</td>
                                    <td>{rule.viseCaptainPoints}</td>
                                 </tr>
                              );
                           })}
                           </tbody>
                        </table>
                     }
                  </div>
               </div>
               <div className="row p-0">
                  <div className={Classnames(styles.ModalButtonRow, "row p-0")}>
                     <button className="grayButton" onClick={handleModalClose}>
                        <text>{t("FANTASY.CLOSE")}</text>
                     </button>
                  </div>
               </div>

            </div>
         </div>
      </div>
   </div>)
}

export default HowFantasyWorksModal;
