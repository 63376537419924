import classNames from "classnames";
import styles from "../../containers/Match/Match.module.scss";
import {capitalize1stLetter, getNotificationTypeIcon, getTimeAgoString} from "../../helpers/CommonFunctions";

const getConvertedTime = (time) => {
   if (typeof time === "number") {
      time = new Date(time * 1000).toISOString();
   }
   return time;
};

const NotificationSection = ({notifications, t}) => {

   return (<div className={classNames(styles.NotitWrap, "col-12")}>
      <div className={classNames(styles.header)}>
         <h2>{t("MATCH.NOTIFICATIONS")} </h2>
      </div>
      <ul className={classNames(styles.NotifyUi)}>
         {notifications?.length > 0 ? <>
            {notifications?.slice(0, 10).map((notification, index) => (<li className="row" key={notification?.notifiedTime}>
               <div className="col-2 p-0"><img
                  src={getNotificationTypeIcon(notification?.notificationType)}></img>
               </div>
               <div className="col-10 p-0">
                  <div>{capitalize1stLetter(notification?.message)}
                     {/*<span style={{color: "#17EA85"}}> +2 points</span>*/}
                     <section
                        className={classNames(styles.subtext)}>{getTimeAgoString(getConvertedTime(notification?.notifiedTime))}</section>
                  </div>
               </div>
            </li>))}
         </> : <div className="col-12 row p-0"
                    style={{borderBottom: "1px solid #FFFFFF0F"}}>
            <div className="col-9 p-3 ">
               <font className={classNames(styles.text16OSW)}>
                  {t("MATCH.NO_NOTIFICATIONS")}.</font><br/>
               {/*<div className={classNames(styles.text14OS, "pt-2")}></div>*/}
            </div>
            <div className="col-3 p-3 pt-4 text-end">
               {/*<img src="https://storage.googleapis.com/meta11/serviceplatform/LockIcon.png"/>*/}
            </div>
         </div>}
      </ul>
   </div>);
}

export default NotificationSection;
