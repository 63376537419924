import React from "react";
import {Link} from "react-router-dom";

const AdvertisementSpace = ({imageUrl1, imageUrl2}) => {
   return (<div
      className="col-12 col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 d-md-none d-sm-none d-none d-xl-block">
      <div className="row gx-0">
         {imageUrl1 && (<Link to={'/register'} className="col-12">
            <div className="adImgWrap" style={{marginBottom: 25}}><img
               src={imageUrl1} alt=""/></div>
         </Link>)}

         {imageUrl2 && (<Link to={'/register'} className="col-12">
            <div className="adImgWrap"><img
               src={imageUrl2} alt=""/></div>
         </Link>)}
      </div>
   </div>);
};

export default AdvertisementSpace;
