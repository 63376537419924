import React from "react";
import Classnames from "classnames";
import styles from "../../Wallet/Purchase/CreditPackagesModal/CreditPackagesModal.module.scss";
import {t} from "i18next";

const FantasyTeamConfirmation = ({isSubmitting, captain, viceCaptain, openSecondModal, handleModalClose, handleConfirmation}) => {
   return (<div
      className={Classnames("modal fade show", styles.modalBlur, styles.PaymentConfirmmodal, styles.CreatedTeamConfirmationModal, styles.AreYouSureMsgModal)}
      id="exampleModalCenter"
      tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"
      style={{display: "block"}}>
      <div className={Classnames(styles.CreditPurchaseModal, "modal-dialog modal-dialog-centered")} role="document">
         <div className={Classnames("modal-content", styles.modalContentContainer)}>
            <button className={Classnames(styles.closeButton, "close z-3")} type="button" onClick={handleModalClose}
                    data-dismiss="modal" aria-label="Close">
                   <span className={styles.closeButton} aria-hidden="true">
                      <img alt='Close Icon' src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/>
                   </span>
            </button>
            <div className={Classnames(styles.UserProfileModalWrapper)}>
               <div className={Classnames(styles.ellipse, styles.eMulticolorHalf)}></div>
               <div className={Classnames(styles.ellipse, styles.e392)}></div>
               <div className="row p-0">
                  <div className={Classnames(styles.UserProfileModal, "col-md-12")}>
                     <div className={Classnames(styles.TitleWrapper)}>
                        <h4>{t("FANTASY.ARE_YOU_SURE_YOU_WANT_TO_CONTINUE_WITH_THE_SELECTED_TEAM")}</h4>
                     </div>
                     {(!captain || !viceCaptain) &&
                        <p className={Classnames(styles.SelectCaptainVcaptain)}>{t("FANTASY.IT_LOOKS_LIKE_YOU_HAVEN_T_ASSIGNED_A_CAPTAIN_VICE_CAPTAIN_BOTH_THESE_ROLES_SCORE_HIGHER_POINTS_THAN_OTHER_PLAYERS")} (<span
                           role={'button'} className={'text-decoration-underline'}
                           onClick={openSecondModal}>{t("FANTASY.POINT_CALCULATION")}</span>). {t("FANTASY.FOR_A_BETTER_SCORE_PLEASE_REVISIT_AND_SELECT_YOUR_CAPTAIN_AND_VICE_CAPTAIN")}
                        </p>
                     }
                  </div>
               </div>

               <div className="row p-0">
                  <div className={Classnames(styles.ModalButtonRow, styles.ContinueWithSelectedTeamBtns, "row p-0")}>
                     <button className={Classnames(styles.grayButton, "grayButton")} onClick={handleModalClose}>
                        <text>{t("FANTASY.CANCEL")}</text>
                     </button>
                     <button className={Classnames(styles.ContinueBtn, "ContinueBtn")}
                             disabled={isSubmitting}
                             onClick={handleConfirmation}>
                        <span>{t("FANTASY.CONTINUE")}</span>
                     </button>
                  </div>
               </div>
               <div className={Classnames(styles.ellipse, styles.eMulticolorFull)}></div>
            </div>
         </div>
      </div>
   </div>)
}

export default FantasyTeamConfirmation;
