import React from "react";
import Classnames from "classnames";
import styles from "../../Wallet/Purchase/CreditPackagesModal/CreditPackagesModal.module.scss";

const BoosterPurchaseConfirmationModal = ({isPurchasing, player, booster, rate, handleModalClose, handleConfirmation}) => {
   return (<div
      className={Classnames("modal fade show", styles.modalBlur, styles.PaymentConfirmmodal, styles.CreatedTeamConfirmationModal, styles.AreYouSureMsgModal)}
      id="exampleModalCenter"
      tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"
      style={{display: "block"}}>
      <div className={Classnames(styles.CreditPurchaseModal, "modal-dialog modal-dialog-centered")} role="document">
         <div className={Classnames("modal-content", styles.modalContentContainer)}>
            <button className={Classnames(styles.closeButton, "close z-3")} type="button" onClick={handleModalClose}
                    data-dismiss="modal" aria-label="Close">
                   <span className={styles.closeButton} aria-hidden="true">
                      <img alt='Close Icon' src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/>
                   </span>
            </button>
            <div className={Classnames(styles.UserProfileModalWrapper)}>
               <div className={Classnames(styles.ellipse, styles.eMulticolorHalf)}></div>
               <div className={Classnames(styles.ellipse, styles.e392)}></div>
               <div className="row p-0">
                  <div className={Classnames(styles.UserProfileModal, "col-md-12")}>
                     <div className={Classnames(styles.TitleWrapper)}>
                        <h4>{`Are you sure you want to apply ${booster?.boosterName} booster to ${player} for ${rate?.tokens} SPRTS?`}</h4>
                     </div>
                  </div>
               </div>

               <div className="row p-0">
                  <div className={Classnames(styles.ModalButtonRow, styles.ContinueWithSelectedTeamBtns, "row p-0")}>
                     <button className={Classnames(styles.grayButton, "grayButton")} onClick={handleModalClose}>
                        <text>Cancel</text>
                     </button>
                     <button className={Classnames(styles.ContinueBtn, "ContinueBtn")}
                             disabled={isPurchasing}
                             onClick={handleConfirmation}>
                        <span>Confirm</span>
                     </button>
                  </div>
               </div>
               <div className={Classnames(styles.ellipse, styles.eMulticolorFull)}></div>
            </div>
         </div>
      </div>
   </div>)
}

export default BoosterPurchaseConfirmationModal;
