/****---stable version 4.0.0 ---****/
/****--- QBOT ---***/
import React, {useEffect, useReducer, useRef, useState} from 'react'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {ChatContainer, MainContainer, Message, MessageList, TypingIndicator} from '@chatscope/chat-ui-kit-react';
import {connect} from "react-redux";
import Modal from "react-bootstrap/Modal";
import {BOT_STATUS} from "../../utilities/Constants";
import {useParams} from "react-router-dom";
import PubNubQuizHandler from "./PubNubQuizHandler";
import * as PubNubService from "../../services/PubNubService";
import * as ResolverService from "../../services/ResolverService";
import styles from "../../containers/ScheduledMatch/ScheduledMatch.module.scss";
import btnStyles from "./PubNubQuizBot.module.scss"
import sounds from "../../assets/music/QuestionSound.mp3"
import {Howl} from 'howler';
import classNames from "classnames";
import PubNubAuthHandler from "./PubNubAuthHandler";
import PubNubUnicastHandler from "./PubNubUnicastHandler";
import CardComponent from "./CardComponent";
import BallByBallCard from "./BallByBallCard";
import PubNubPredictionHandler from "./PubNubPredictionHandler";
import BallByBallHistory from "./BallByBallHistory";
import {HiOutlineCog} from "react-icons/hi";
import {IoMdInformationCircleOutline} from "react-icons/io";
import {MdOutlineKeyboardArrowRight} from "react-icons/md";
import {RxSpeakerModerate} from "react-icons/rx";
import {useTranslation} from "react-i18next";
import globe from "../../assets/images/globe-03.webp";
import HowToUseQBot from "../Modals/HowToUseQBot/HowToUseQBot";


function PubNubQuizBot(props) {

   let {matchId} = useParams();
   const [tempMsg, setTempMsg] = useState(null);
   const [intermidiateMessage, setIntermediateMessage] = useState(null);
   const [isTyping, setIsTyping] = useState(false);
   const [showMoreInfoModal, setShowMoreInfoModal] = useState(false);
   const [showQbotMenu, setShowQbotMenu] = useState(false)
   const [timesUp, setTimesUp] = useState(true);
   const [answerSubmit, setAnswerSubmit] = useState([]);
   const [answerResponse, setAnswerResponse] = useState("");
   const [message, setMessage] = useState(null);
   const [unicastMessage, setUnicastMessage] = useState(null);
   const [authMessage, setAuthMessage] = useState(null);
   const [subscribeChannel, setSubscribeChannel] = useState(null);
   const [unSubscribeChannel, setUnsubscribeChannel] = useState(null);
   const [questionQueue, setQuestionQueue] = useState([]);
   const [connectPubnubQuiz, setConnectPubnubQuiz] = useState(false);
   const [quizToken, setQuizToken] = useState(null);
   const [loading, setLoading] = useState(true);
   const [authResReceived, setAuthResReceived] = useState(false);
   const [isSoundEnabled, setSoundEnabled] = useState(localStorage.getItem("enableSound") != null ? JSON.parse(localStorage.getItem("enableSound")) : true);
   const [mappedData, setMappedData] = useState({});
   const [ballData, setBallData] = useState([]);
   const [overSummary, setOverSummary] = useState([]);
   const [overSummaryMessages, setOverSummaryMessages] = useState(null);
   const [isLangWrapperVisible, setLangWrapperVisible] = useState(true);
   const [answers, setAnswers] = useState(null);
   const [lateForOver, setLateForOver] = useState(false);
   const [okToResolve, setOkToResolve] = useState(true);
   let language = localStorage.getItem("language");
   const {i18n} = useTranslation();
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);
   const [resendAuth, setResendAuth] = useState(false);

   // Function to map data based on fId
   const mapData = (feedbacks) => {
      const mappedObject = {};

      feedbacks.forEach((item) => {
         const {fId, language_code, feedback_txt} = item;

         // Define key names based on fId
         let keyName = '';

         switch (fId) {
            case 0:
               keyName = 'GreetSuccess';
               break;
            case 1:
               keyName = 'PastResults';
               break;
            case 2:
               keyName = 'Farewell';
               break;
            case 3:
               keyName = 'ExpiredQuestion';
               break;
            case 4:
               keyName = 'TimeUp';
               break;
            case 5:
               keyName = 'Thanks';
               break;
            case 20:
               keyName = 'Late';
               break;
            case 21:
               keyName = 'Reject';
               break;
            // Add more cases as needed

            default:
               keyName = `DefaultKey_${fId}`;
         }

         // Use keyName as the key
         if (!mappedObject[keyName]) {
            mappedObject[keyName] = {en: [], ta: [], hi: []};
         }
         mappedObject[keyName][language_code].push({language_code, feedback_txt});
      });
      setMappedData(mappedObject);
   };

   useEffect(() => {
      if (props.ballHistory != null) {
         console.log("---------------000000000000ballHistory000000000000-----------------", props.ballHistory)
         handleBallData(props.ballHistory);
      }

   }, [props.ballHistory])

   const handleBallData = (data) => {
      console.log("data", data.predictionResultsList[0]);
      console.log("balldata", ballData);
      if (data.predictionResultsList.length == 1) {
         if (ballData?.some(prediction => (prediction?.inning == data.predictionResultsList[0].inning && prediction?.overId == data.predictionResultsList[0].overId && prediction?.ballId == data.predictionResultsList[0].ballId))) {
            let updatedPredictionList = ballData?.map(prediction => {
               if (prediction?.inning == data.predictionResultsList[0].inning &&
                  prediction?.overId == data.predictionResultsList[0].overId &&
                  prediction?.ballId == data.predictionResultsList[0].ballId &&
                  (prediction.actual == null || prediction.actual == "N/P")) {
                  return {
                     ...prediction,
                     actual: data.predictionResultsList[0].actual
                  };
               } else {
                  return prediction;
               }
            });
            setBallData(updatedPredictionList);
         } else {
            setBallData(prevState => [...prevState, data.predictionResultsList[0]]);
         }
      } else if (data.predictionResultsList.length > 1) {
         data.predictionResultsList.forEach(prediction => {
            setBallData(prevState => [...prevState, prediction])
         });
      }
      console.log("---------------ball  length-----------------", ballData);
   }

   useEffect(() => {
      const fetchData = async () => {

         try {
            const predictionHistoryResponse = await ResolverService.getPredictionResultHistory(props.userName, matchId).then(response => {
               if (response != null) {
                  if (response?.overPredictionSummaryResultsDtoList?.length > 0) {
                     let summaryData = [];
                     response?.overPredictionSummaryResultsDtoList?.forEach(summary => {
                        let overSummaryData = {
                           "inning": summary?.inning,
                           "overId": summary?.overId,
                           "overSummary": summary
                        }
                        summaryData.push(overSummaryData);
                     })

                     setOverSummary(prevState => [...prevState, ...summaryData])
                  }
               }
            })

         } catch (error) {
            console.error("Error:", error);
         }

      }

      fetchData();
   }, []);


   const handleLanguageChange = (selectedLanguage) => {
      localStorage.setItem('language', selectedLanguage);
      i18n.changeLanguage(selectedLanguage);
      window.location.reload();
   };

   const hideLangWrapper = () => {
      setLangWrapperVisible(!isLangWrapperVisible);
   };

   useEffect(() => {
      localStorage.setItem('language', i18n.language === 'hi' ? 'hi' : 'en');
   }, []);


   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   const messageFromCallback = async (message) => {
      if (message.messageBodyType == "QUESTION") {
         setIntermediateMessage(message);
         await handleMessage(message)
      } else {
         console.log("Not Compatible Type : ", message)
      }
   }
   const messageFromPredictionCallback = async (message) => {
      if (state?.okToResolve) {
         console.log("Data coming from predictioncallback ", message)
         handleBallData(message);
      }
   }

   const messageFromUnicastCallback = async (message) => {
      if (message.messageBodyType == "OVER_SUMMARY" && message?.overSummary != null) {
         console.log("+++++++++++++++over summary data++++++++++++++++++++ ", message);
         setOverSummaryMessages(message?.feedbackMessages)
         let overSummaryData = {
            "inning": message?.inning,
            "overId": message?.overNo,
            "overSummary": message?.overSummary
         }
         setOverSummary(prevState => [...prevState, overSummaryData]);
         dispatch({
            type: "CHANGE_MESSAGE_STATUS_BY_TYPE",
            payload: {type: "TEXT", STATUS: BOT_STATUS.EXPIRED}
         })
         if (message?.overNo % 2 != 0) {
            handleMessage(message)
         }
      }
      if (message.messageBodyType != "INFO" && message.messageBodyType != "SUBSCRIBE" && message.messageBodyType != "UNSUBSCRIBE" && message.messageBodyType != "OVER_SUMMARY") {
         setIntermediateMessage(message);
         await handleMessage(message)
      } else {
         console.log("Not Compatible Type : ", message)
      }
      if (message.messageBodyType == "GREETING") {
         mapData(message?.feedbackMessages)
         setOverSummaryMessages(message?.feedbackMessages)
      }
      // handleMessage(message)
   }

   useEffect(() => {
      if ((answers != null || props.ballHistory?.length > 0) && ballData?.some(ball => ball?.actual != null) && props.matchData?.matchInningList != null) {
         dispatch({
            type: "CHANGE_MESSAGE_STATUS_BY_TYPE",
            payload: {type: "TEXT", STATUS: BOT_STATUS.EXPIRED}
         })
         setLateForOver(false);
      } else if (props.matchData?.matchInningList != null && props.matchData?.matchInningList?.length > 0) {
         setLateForOver(true);
      }

   }, [ballData, props.matchData]);

   //sound configs

   useEffect(() => {
      if (localStorage.getItem("enableSound") == null) {
         localStorage.setItem("enableSound", true)
      } else {
         localStorage.setItem("enableSound", isSoundEnabled)
      }
   }, [isSoundEnabled]);

   const initialState = {
      socketMessageState: [],
      lastMessageExpired: false,
      subscriptionStatus: false,
      subscriptionStatusCheck: false,
      pendingAnswered: false,
      expiredStatus: false,
      isResultCardReceived: false,
      isGreetingReceived: false,
      PredictionQuestionPresent: false,
      channelSubscribe: "",
      okToResolve: true
   }

   const botReducer = (state, action) => {
      switch (action.type) {
         case 'ADD_MESSAGES':
            const updatedMessages = [...state.socketMessageState, action.payload];
            return {
               ...state,
               socketMessageState: updatedMessages,
               lastMessageExpired:
                  updatedMessages.length > 0 &&
                  updatedMessages.some(
                     (message) => message?.STATUS == BOT_STATUS.INITIALIZED
                  ),
            };
         case 'CHANGE_MESSAGE_STATUS':
            const updated = state?.socketMessageState?.map(message => {
               if (action.payload.questionId == message.questionId) {
                  return {...message, "STATUS": action.payload.STATUS}
               }
               return message
            })
            return {...state, socketMessageState: updated};
         case 'CHANGE_MESSAGE_STATUS_BY_TYPE':
            const updatedByType = state?.socketMessageState?.map(message => {
               if (action?.payload?.type == message?.messageBodyType) {
                  return {...message, "STATUS": action?.payload?.STATUS}
               }
               return message
            })
            return {...state, socketMessageState: updatedByType};
         case 'CHANGE_MESSAGE_STATUS_BY_STATUS':
            const updatedByStatus = state?.socketMessageState?.map(message => {
               if (action?.payload?.STATUS == message?.STATUS) {
                  return {...message, "STATUS": action?.payload?.STATUS_TYPE}
               }
               return message
            })
            return {...state, socketMessageState: updatedByStatus};
         case 'CHANGE_ALL_MESSAGE_STATUS':
            const updatedArr = state.socketMessageState.map(message => {
               if (message?.questionId != 100000 && message?.messageBodyType != "OVER_HISTORY" && message?.messageBodyType != "OVER_SUMMARY") {
                  return {...message, "STATUS": action.payload.STATUS}
               }
               return message
            });
            return {...state, socketMessageState: updatedArr};
         case 'REMOVE_MESSAGES':
            const updatedRmResponse = state.socketMessageState.filter(message => message.STATUS != action.payload.STATUS)
            return {...state, socketMessageState: updatedRmResponse};
         case 'CHECK_EXPIRED_MESSAGE_STATUS':
            return {...state, lastMessageExpired: state.socketMessageState.length > 0 && state.socketMessageState?.some(message => (message?.STATUS == BOT_STATUS.INITIALIZED))};
         case 'CHANGE_LAST_MESSAGE_STATE':
            return {...state, lastMessageExpired: action.payload}
         case 'CHANGE_SUBSCRIPTION_STATE':
            return {...state, subscriptionStatus: action.payload}
         case 'CHANGE_EXPIRED_STATE':
            return {...state, expiredStatus: action.payload}
         case 'CHECK_SUBSCRIPTION_STATE':
            return {...state, subscriptionStatusCheck: state.subscriptionStatus}
         case 'UPDATE_RESULT_STATE':
            return {...state, isResultCardReceived: true}
         case 'UPDATE_GREETING_STATE':
            return {...state, isGreetingReceived: true}
         case 'ADD_CHANNEL_SUBSCRIPTION':
            return {...state, channelSubscribe: action.payload}
         case 'OK_TO_RESOLVE_STATE':
            return {...state, okToResolve: action.payload}
         default:
            return state;
      }
   };

   const [state, dispatch] = useReducer(botReducer, initialState);

   const initialChatMessage = {

      "userName": props.userName,
      "jwtToken": props.Token,
      "userId": props.userId,
      "tournamentId": props.matchData?.tournamentId,
      "questionRun": null,
      "messageBodyType": null,
      "matchId": matchId,
      "questionId": null,
      "questionTxt": null,
      "inning": null,
      "ttl": null,
      "maxAcceptance": null,
      "message": null,
      "answers": null,
      "userAnswer": null,
      "result": null,
      "points": null,
      "statistics": null

   }
   const getOverAndBallData = () => {
      const uniqueCombinations = new Set();


      const uniqueData = ballData?.filter(item => {
         const combination = `${item.overId}-${item.inning}`;
         if (!uniqueCombinations.has(combination) && item.prediction != null) {
            uniqueCombinations.add(combination);
            return true;
         }
         return false;
      });

      const result = uniqueData.map(item => ({inning: item.inning, overId: item.overId})).sort((a, b) => {
         if (a.inning === b.inning) {
            return a.overId - b.overId;
         }
         return a.inning - b.inning;
      });

      if (result?.length > 0) {
         result?.forEach(item => {
            let cardData = {
               "messageBodyType": "OVER_HISTORY",
               "direction": "incoming",
               "inning": item?.inning,
               "overNo": item?.overId
            }
            if (item?.overId % 2 == 0) {
               handleMessage(cardData)
            }

            // dispatch({
            //    type: "ADD_MESSAGES",
            //    payload: cardData
            // })
         })
      }
   }


   useEffect(() => {
      const fetchData = async () => {
         try {
            // const predictionResponse = await ResolverService.getPredictionHistory(props.userName,matchId).then(response => {
            //     if (response != null) {
            //         console.log(response);
            //         handleBallData(response?.predictionResultsDto)
            //     }
            // });
            const response = await PubNubService.requestAccessTokenQuiz(props.userName, matchId, props.tournamentId ?? props?.matchData?.tournamentId, props.Token).then(response => {
               if (response != null) {

                  setQuizToken(response);
                  setConnectPubnubQuiz(true);

                  localStorage.setItem("pubnubQuizToken", response);
               }
            });

         } catch (error) {
            console.error("Error:", error);
         }

      }

      fetchData();
   }, [matchId, props.userName, props.matchData]);

   const sendUserAnswer = async (token, data) => {
      try {
         const sendAnswersToResolver = await PubNubService.answerSend(token, data).then(
            response => {
               if (response?.apiStatus != true && data?.questionId == 100000) {
                  dispatch({
                     type: "OK_TO_RESOLVE_STATE",
                     payload: false
                  })
                  setOkToResolve(false);
               } else if (response?.apiStatus == true && data?.questionId == 100000) {
                  dispatch({
                     type: "OK_TO_RESOLVE_STATE",
                     payload: true
                  })
                  setOkToResolve(true);
               } else if (response?.apiStatus == true) {
                  dispatch({
                     type: "OK_TO_RESOLVE_STATE",
                     payload: true
                  })
                  setOkToResolve(true);
               }
            }
         )

      } catch (e) {
         console.log("User Answer Send Error: ", e)
      }
   }

   const pastQuestionChecker = (questionId) => answerSubmit.some(question => question.questionId == questionId);

   const pastExpireCheck = async () => {
      return state.socketMessageState.length > 0 && state.socketMessageState?.some(message => (message?.STATUS == BOT_STATUS.INITIALIZED));
   }
   // Function to enqueue an object to the queue
   const enqueue = (item) => {
      if (item.messageBodyType == "QUESTION" || item.messageBodyType == "RESOLVE" || item.messageBodyType == "RESULT_CARD") {
         setQuestionQueue((prevQueue) => [...prevQueue, item]);
      }
   };

   // Function to dequeue and return the latest object from the queue
   const dequeue = () => {
      if (questionQueue.length === 0) {
         return null; // Queue is empty
      }

      const latestObject = questionQueue[0];
      setQuestionQueue((prevQueue) => prevQueue.slice(1)); // Remove the consumed element
      return latestObject;
   };


   const channelSubscriber = async (channel) => {
      console.log("subscribed to :", channel)
      setSubscribeChannel(channel);
   }

   useEffect(() => {
      const authSender = async () => {
         if (authResReceived == false) {
            console.log("PubNub is open - Sending Auth Req")

            const authMessage = {
               ...initialChatMessage,
               "messageBodyType": "AUTH_REQ",
               "matchId": matchId
            }
            console.log(authMessage);
            // setSubscribeChannel("authChannel");
            // setSubscribeChannel(props.userName + "-" + matchId);
            // await channelSubscriber(props.userName + "-" + matchId);
            //   setSubscribeChannel(props.userName + "-" + matchId);

            // sendMessage(authMessage, "authChannel")
            setAuthMessage({
               "message": authMessage,
               "channel": "authChannel"
            })

            // TODO
            // dispatch({
            //    type: "CHANGE_SUBSCRIPTION_STATE",
            //    payload: true
            // })

            const subscribeMessage = {
               ...initialChatMessage,
               "userName": props.userName,
               "jwtToken": props.Token,
               "messageBodyType": "SUBSCRIBE",
               "matchId": matchId
            }

            let subscribeResponse = {};

            // if ((props.matchData.live || props.matchData.toStart) == true) {
            //    if (lateForOver) {
            //       subscribeResponse = {
            //          "STATUS": BOT_STATUS.RESPONSE,
            //          "messageBodyType": "TEXT",
            //          "message": mappedData.Late[language][0].feedback_txt,
            //          "direction": "incoming",
            //          "type": "info"
            //       }
            //    } else {
            //       subscribeResponse = {
            //          "STATUS": BOT_STATUS.RESPONSE,
            //          "messageBodyType": "TEXT",
            //          "message": mappedData.GreetSuccess[language][0].feedback_txt,
            //          "direction": "incoming"
            //       }
            //    }
            //
            // } else {
            //    subscribeResponse = {
            //       "STATUS": BOT_STATUS.RESPONSE,
            //       "messageBodyType": "TEXT",
            //       "message": mappedData.PastResults[language][0].feedback_txt,
            //       "direction": "incoming"
            //    }
            // }

            // dispatch({
            //    type: "CHANGE_SUBSCRIPTION_STATE",
            //    payload: true
            // })

            // sendMessageUnicast(subscribeMessage, props.userName + "-" + matchId)
            // await channelSubscriber(props.matchId);
            //
            // window.dataLayer.push({
            //    'event': 'QbotSubscribed',
            //    'timeStamp': Date.now()
            // });
            //
            // dispatch({
            //    type: "ADD_MESSAGES",
            //    payload: subscribeResponse
            // })
            //
            getOverAndBallData();
         }
      }
      authSender();
      // if (retryCountRef.current < MAX_RETRY_COUNT) {
      //   retryCountRef.current += 1;
      //   console.log(`Retrying... Attempt ${retryCountRef.current + 1}/${MAX_RETRY_COUNT}`);
      //   setTimeout(authSender, 100);
      // } else {
      //   console.error('Max retry attempts reached. Unable to subscribe.');
      // }
   }, [authResReceived, props.userName, matchId]);

   const sendMessage = (message, channel) => {
      console.log("sent message", message)
      console.log("sent channel", channel)
      let messageObj = {
         message: message,
         channel: channel
      }
      setMessage(messageObj);
   }
   const sendMessageUnicast = (message, channel) => {
      console.log("sent message", message)
      console.log("sent channel", channel)
      let messageObj = {
         message: message,
         channel: channel
      }
      setUnicastMessage(messageObj);
   }

   const handleMessage = async (message) => {

      if (message != null) {
         if (message.messageBodyType == "OVER_HISTORY") {
            await dispatch({
               type: "CHANGE_ALL_MESSAGE_STATUS",
               payload: {STATUS: BOT_STATUS.EXPIRED}
            })
         }
         await dispatch({
            type: "CHECK_PENDING_ANSWER_MESSAGE_STATUS"
         })
         await pastExpireCheck()
         let notExpired = state.socketMessageState.length > 0 && state.socketMessageState?.some(message => (message?.STATUS == BOT_STATUS.INITIALIZED))
         dispatch({
            type: "CHANGE_EXPIRED_STATE",
            payload: notExpired
         })
         console.log("not expired", notExpired);
         console.log(state.lastMessageExpired)
         console.log("timesUp", timesUp);

         console.log("received", message)
         if (message.messageBodyType == "GREETING" && message.authenticated == true && message != null) {
            setAuthResReceived(true);
            // setTimesUp(true)
            localStorage.setItem("socketAuth", "true");

            setLoading(false);
         }
         //TODO - Expire when new message initialized
         message = {
            ...message,
            "STATUS": (message.messageBodyType == "RESOLVE" || message.messageBodyType == "OVER_HISTORY" || message.messageBodyType == "OVER_SUMMARY") ? BOT_STATUS.ANSWERED : BOT_STATUS.INITIALIZED
         }
         if (message.messageBodyType == "RESULT_CARD") {

            await dispatch({
               type: "UPDATE_RESULT_STATE"
            })
            await dispatch({
               type: "CHANGE_ALL_MESSAGE_STATUS",
               payload: {STATUS: BOT_STATUS.EXPIRED}
            })
            const unSubscribeMessage = {
               ...initialChatMessage,
               "userName": props.userName,
               "jwtToken": props.Token,
               "matchId": matchId,
               "messageBodyType": "UNSUBSCRIBE"
            }
            sendMessageUnicast(unSubscribeMessage, props.userName + "-" + matchId)
            setUnsubscribeChannel(props.userName + "-" + matchId)
            dispatch({
               type: "CHANGE_SUBSCRIPTION_STATE",
               payload: false
            })

         }

         dispatch({
            type: "CHECK_SUBSCRIPTION_STATE"
         })
         console.log(state.subscriptionStatus)


         await dispatch({
            type: "CHECK_EXPIRED_MESSAGE_STATUS"
         })

         if ((message.messageBodyType == "QUESTION" || message.messageBodyType == "GREETING" || (message.messageBodyType == "RESOLVE" && state?.isResultCardReceived == false) || message.messageBodyType == "RESULT_CARD") || message.messageBodyType == "REJECT" || message.messageBodyType == "OVER_HISTORY" || message.messageBodyType == "OVER_SUMMARY") {
            await dispatch({
               type: "CHECK_EXPIRED_MESSAGE_STATUS"
            })
            console.log("inside 1st if", message)
            console.log(state.lastMessageExpired)
            console.log(notExpired)
            try {
               await dispatch({
                  type: "CHECK_EXPIRED_MESSAGE_STATUS"
               })

               if (timesUp == true && ((message.messageBodyType == "QUESTION" && state?.subscriptionStatus == true) || message.messageBodyType == "GREETING" || message.messageBodyType == "REJECT" || message.messageBodyType == "RESOLVE" || message.messageBodyType == "RESULT_CARD" || message.messageBodyType == "OVER_HISTORY" || message.messageBodyType == "OVER_SUMMARY")) {


                  console.log("inside 2nd if", message)
                  await dispatch({
                     type: "CHANGE_LAST_MESSAGE_STATE",
                     payload: true
                  })
                  console.log("changes lst msg", state.lastMessageExpired)
                  await dispatch({
                     type: "CHANGE_ALL_MESSAGE_STATUS",
                     payload: {STATUS: BOT_STATUS.EXPIRED}
                  })

                  setIsTyping(false);

                  await dispatch({
                     type: "ADD_MESSAGES",
                     payload: message
                  })
                  if (isSoundEnabled) {
                     console.log("play sound")
                     const sound = new Howl({
                        src: [sounds]
                     });
                     sound.play();
                  }
                  // setTimesUp(false);
               } else if (state?.subscriptionStatus) {
                  console.log("inside else", message)
                  setTempMsg(message)
                  // enqueue(message);
                  if (message?.messageBodyType == "OVER_HISTORY" || message.messageBodyType == "OVER_SUMMARY") {
                     await dispatch({
                        type: "ADD_MESSAGES",
                        payload: message
                     })
                  }
               }
               dispatch({
                  type: "CHECK_EXPIRED_MESSAGE_STATUS"
               })
            } catch (e) {
               console.log("Error parsing JSON");
               message = {
                  ...message,
                  "STATUS": BOT_STATUS.RESPONSE,
                  "messageBodyType": "TEXT",
                  "message": "Error in QBOT Data,Please refresh!",
                  "direction": "incoming"
               }
               dispatch({
                  type: "ADD_MESSAGES",
                  payload: message
               })
            }

         } else {
            console.log("1st else", message)
         }
      } else {
         console.log("Message is null: ", message);
      }

   }


   useEffect(() => {
      dispatch({
         type: "CHECK_EXPIRED_MESSAGE_STATUS"
      })
      if (state.lastMessageExpired == false) {

         setIsTyping(false);
         if (dequeue() != null && state?.subscriptionStatus) {
            if ((dequeue().messageBodyType == "QUESTION" || dequeue().messageBodyType == "GREETING" || dequeue().messageBodyType == "RESULT_CARD" || dequeue().messageBodyType == "RESOLVE" || dequeue().messageBodyType == "OVER_HISTORY")) {
               console.log("from deque")
               dispatch({
                  type: "CHANGE_ALL_MESSAGE_STATUS",
                  payload: {STATUS: BOT_STATUS.EXPIRED}
               })

               handleMessage(dequeue())
            }
         }

      }
   }, [state.lastMessageExpired])

   const handleTimesUp = (value) => {
      console.log("timesup0", value)
      setTimesUp(value);
      if (value == true) {
         dispatch({
            type: "CHECK_EXPIRED_MESSAGE_STATUS"
         })
      }

   }

// TODO
   const handleAnswerSend = async (questionId, answerId, answer, time, questionText, messageType, maxMillsEnc, sendAnswer, questionRun) => {
      console.log("send", sendAnswer, messageType)
      let lang = language;
      if (messageType == "GREETING") {
         if (sendAnswer.answer = "Yes") {
            if (lateForOver && props.matchData.live) {
               dispatch({
                  type: "ADD_MESSAGES",
                  payload: {
                     "message": mappedData.Late[lang][0].feedback_txt,
                     "messageBodyType": "TEXT",
                     "STATUS": BOT_STATUS.RESPONSE
                  }
               })
            }
            const subscribeMessage = {
               ...initialChatMessage,
               "userName": props.userName,
               "jwtToken": props.Token,
               "messageBodyType": "SUBSCRIBE",
               "matchId": matchId
            }

            dispatch({
               type: "CHANGE_SUBSCRIPTION_STATE",
               payload: true
            })

            sendMessageUnicast(subscribeMessage, props.userName + "-" + matchId)
            await channelSubscriber(props.matchId);

            window.dataLayer.push({
               'event': 'QbotSubscribed',
               'timeStamp': Date.now()
            });
            getOverAndBallData();
         } else {
            const unSubscribeMessage = {
               ...initialChatMessage,
               "userName": props.userName,
               "jwtToken": props.Token,
               "matchId": matchId,
               "messageBodyType": "UNSUBSCRIBE"
            }
            sendMessageUnicast(unSubscribeMessage, props.userName + "-" + matchId)
            setUnsubscribeChannel(props.userName + "-" + matchId)
            dispatch({
               type: "CHANGE_SUBSCRIPTION_STATE",
               payload: false
            })

         }


      } else if (messageType == "BALL_PREDICTION") {
         let data = {
            ...initialChatMessage,
            "userName": props.userName,
            "jwtToken": props.Token,
            "messageBodyType": "ANSWER",
            "matchId": props.matchData.matchId,
            "questionTxt": questionText,
            "questionRun": questionRun,
            "questionId": questionId,
            "maxAcceptance": maxMillsEnc,
            "inning": 0,
            "userAnswer": sendAnswer
         }
         console.log("=====ANSWER DATA===== ", data)
         if (props.matchData?.winningTeamId != null && state.isResultCardReceived == false) {
            let resTime = {
               "message": mappedData.ExpiredQuestion[lang][0].feedback_txt,
               "messageBodyType": "TEXT",
               "STATUS": BOT_STATUS.RESPONSE
            }
            dispatch({
               type: "CHANGE_MESSAGE_STATUS",
               payload: {questionId: questionId, STATUS: BOT_STATUS.EXPIRED}
            })
            dispatch({
               type: "CHANGE_ALL_MESSAGE_STATUS",
               payload: {STATUS: BOT_STATUS.EXPIRED}
            })
            if (timesUp == true && resTime.message != "") {
               console.log(timesUp)
               dispatch({
                  type: "ADD_MESSAGES",
                  payload: resTime
               })
            }
            setAnswerResponse(mappedData.ExpiredQuestion[lang][0].feedback_txt)

         } else if (state.isResultCardReceived == false) {
            let res = {};
            setAnswerSubmit(prevState => [...prevState, questionId])
            console.log("answer state :", sendAnswer == null)
            if (sendAnswer == null) {
               res = {
                  "message": mappedData.TimeUp[lang][0].feedback_txt,
                  "messageBodyType": "TEXT",
                  "STATUS": BOT_STATUS.RESPONSE
               }
               setAnswerResponse(mappedData.TimeUp[lang][0].feedback_txt);
            } else {
               res = {
                  "message": mappedData.Thanks[lang][0].feedback_txt,
                  "messageBodyType": "TEXT",
                  "STATUS": BOT_STATUS.RESPONSE
               }
               setAnswerResponse(mappedData.Thanks[lang][0].feedback_txt)
            }
            // dispatch({
            //    type: "CHANGE_ALL_MESSAGE_STATUS",
            //    payload: {STATUS: BOT_STATUS.EXPIRED}
            // })

            if (sendAnswer == null) {
               const timeOut = {
                  ...initialChatMessage,
                  "userName": props.userName,
                  "messageBodyType": "TIME_OUT",
                  "matchId": props.matchData.matchId,
                  "questionId": questionId,
               }

               sendMessage(timeOut, "Answers")
               // pubnub.unsubscribe("Answer")
               setUnsubscribeChannel("Answers")

               console.log("timesup2", timesUp)
               if (timesUp == true && res.message != "") {
                  console.log(timesUp)
                  dispatch({
                     type: "ADD_MESSAGES",
                     payload: res
                  })
               }

            } else {
               // const newData = {
               //   ...data,
               //   "message": questionId == 0 ? (answer == props.matchData?.teamOne?.teamId ? props.matchData?.teamOne?.name : answer == props.matchData?.teamTwo?.teamId ? props.matchData?.teamTwo?.name : answer) : answer
               //   , direction: "outgoing", "STATUS": BOT_STATUS.RESPONSE
               // }
               // // sendMessage(newData, "Answers")
               // dispatch({
               //   type: "ADD_MESSAGES",
               //   payload: newData
               // })
               // sendMessage(newData, "Answers")
               setAnswers(sendAnswer);
               let answerData = {
                  "answerListDto": sendAnswer,
                  "matchId": parseInt(matchId),
                  "questionId": questionId,
                  "questionText": questionText,
                  "tournamentId": props.matchData?.tournamentId,
                  "userName": props.userName
               }

               //6BallPrediction
               sendUserAnswer(props.Token, answerData)

               window.dataLayer.push({
                  'event': '6BallAnswered',
                  'matchId': parseInt(matchId),
                  'timeStamp': Date.now()
               });

               console.log("timesup3", timesUp)
               if (timesUp == true && res.message != "") {
                  console.log(timesUp)
                  dispatch({
                     type: "ADD_MESSAGES",
                     payload: res
                  })
               }
            }
         } else if (state.isResultCardReceived == true) {
            dispatch({
               type: "CHANGE_ALL_MESSAGE_STATUS",
               payload: {STATUS: BOT_STATUS.EXPIRED}
            })
         }
      } else {
         let data = {
            ...initialChatMessage,
            "userName": props.userName,
            "jwtToken": props.Token,
            "messageBodyType": "ANSWER",
            "matchId": props.matchData.matchId,
            "questionTxt": questionText,
            "questionRun": questionRun,
            "questionId": questionId,
            "maxAcceptance": maxMillsEnc,
            "inning": 0,
            "userAnswer": sendAnswer
         }
         console.log("=====ANSWER DATA===== ", data)
         if (props.matchData?.winningTeamId != null && state.isResultCardReceived == false) {
            let resTime = {
               "message": mappedData.ExpiredQuestion[lang][0].feedback_txt,
               "type": "TEXT",
               "STATUS": BOT_STATUS.RESPONSE
            }
            dispatch({
               type: "CHANGE_MESSAGE_STATUS",
               payload: {questionId: questionId, STATUS: BOT_STATUS.EXPIRED}
            })
            dispatch({
               type: "CHANGE_ALL_MESSAGE_STATUS",
               payload: {STATUS: BOT_STATUS.EXPIRED}
            })
            if (timesUp == true && resTime.message != "") {
               console.log(timesUp)
               dispatch({
                  type: "ADD_MESSAGES",
                  payload: resTime
               })
            }
            setAnswerResponse(mappedData.ExpiredQuestion[lang][0].feedback_txt)

         } else if (state.isResultCardReceived == false) {
            let res = {};
            setAnswerSubmit(prevState => [...prevState, questionId])
            console.log("answer state :", sendAnswer == null)
            if (sendAnswer == null) {
               res = {
                  "message": mappedData.TimeUp[lang][0].feedback_txt,
                  "type": "TEXT",
                  "STATUS": BOT_STATUS.RESPONSE
               }
               setAnswerResponse(mappedData.TimeUp[lang][0].feedback_txt);
            } else {
               res = {
                  "message": mappedData.Thanks[lang][0].feedback_txt,
                  "type": "TEXT",
                  "STATUS": BOT_STATUS.RESPONSE
               }
               setAnswerResponse(mappedData.Thanks[lang][0].feedback_txt)
            }
            dispatch({
               type: "CHANGE_MESSAGE_STATUS",
               payload: {questionId: questionId, STATUS: BOT_STATUS.ANSWERED}
            })

            if (sendAnswer == null) {
               const timeOut = {
                  ...initialChatMessage,
                  "userName": props.userName,
                  "messageBodyType": "TIME_OUT",
                  "matchId": props.matchData.matchId,
                  "questionId": questionId,
               }

               sendMessage(timeOut, "Answers")
               // pubnub.unsubscribe("Answer")
               setUnsubscribeChannel("Answers")

               console.log("timesup2", timesUp)
               if (timesUp == true && res.message != "") {
                  console.log(timesUp)
                  dispatch({
                     type: "ADD_MESSAGES",
                     payload: res
                  })
               }

            } else {
               const newData = {
                  ...data,
                  "message": questionId == 0 ? (answer == props.matchData?.teamOne?.teamId ? props.matchData?.teamOne?.name : answer == props.matchData?.teamTwo?.teamId ? props.matchData?.teamTwo?.name : answer) : answer
                  , direction: "outgoing", "STATUS": BOT_STATUS.RESPONSE
               }
               // sendMessage(newData, "Answers")
               dispatch({
                  type: "ADD_MESSAGES",
                  payload: newData
               })
               // sendMessage(newData, "Answers")
               let answerData = {
                  "answerListDto": [
                     {
                        "answer": answer,
                        "ballId": 0,
                        "inning": sendAnswer?.inning,
                        "languageCode": lang,
                        "overId": sendAnswer?.overId,
                        "points": 0,
                        "questionRun": 0,
                        "questionText": questionText
                     }
                  ],
                  "matchId": parseInt(matchId),
                  "questionId": questionId,
                  "questionText": questionText,
                  "tournamentId": props.matchData?.tournamentId,
                  "userName": props.userName
               }

               //UserQuestions
               sendUserAnswer(props.Token, answerData)

               window.dataLayer.push({
                  'event': 'QuestionAnswered',
                  'matchId': parseInt(matchId),
                  'timeStamp': Date.now()
               });

               console.log("timesup3", timesUp)
               if (timesUp == true && res.message != "") {
                  console.log(timesUp)
                  dispatch({
                     type: "ADD_MESSAGES",
                     payload: res
                  })
               }
            }
         } else if (state.isResultCardReceived == true) {
            dispatch({
               type: "CHANGE_ALL_MESSAGE_STATUS",
               payload: {STATUS: BOT_STATUS.EXPIRED}
            })
         }
      }
   }
   const msgListRef = useRef();

   useEffect(() => {
      msgListRef.current.scrollToBottom()
      let notExpired = state.socketMessageState.length > 0 && state.socketMessageState?.some(message => (message?.STATUS == BOT_STATUS.INITIALIZED))
      dispatch({
         type: "CHANGE_LAST_MESSAGE_STATE",
         payload: notExpired
      })
   }, [state.socketMessageState])

   useEffect(() => {
      // resend auth message
      const timeOut = setTimeout(() => {
         if (loading) {
            setResendAuth(true);
         }
      }, 8000);

      return () => clearTimeout(timeOut);
   }, [loading]);

   function handleMoreInfo() {
      setShowMoreInfoModal(true);
      setShowQbotMenu(false);
   }

   function handleMoreInfoClose() {
      setShowMoreInfoModal(false);
   }


   const loadingIndicator = (<div className={styles.loaderContainer} style={{textAlign: "center"}}>
         <div className="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
         </div>
      </div>
   );


   return (
      <React.Fragment>
         {(connectPubnubQuiz && (quizToken != null) && props.isAuthenticated) ?
            <PubNubAuthHandler sendMessage={authMessage} token={quizToken}
                               resendAuth={resendAuth}
                               afterResendAuth={() => {
                                  console.log("set resend auth to false")
                                  setResendAuth(false)
                               }}/>
            : ""}
         {(connectPubnubQuiz && (quizToken != null) && props.isAuthenticated) ?
            <PubNubQuizHandler matchId={matchId} userName={props.userName} onDataReceivedQuizbot={messageFromCallback} sendMessage={message} subscribe={subscribeChannel} unSubscribe={unSubscribeChannel} token={quizToken}/> : ""}
         {(connectPubnubQuiz && (quizToken != null) && props.isAuthenticated) ?
            <PubNubPredictionHandler matchId={matchId} userName={props.userName} onDataReceivedPrediction={messageFromPredictionCallback} token={quizToken}/> : ""}
         {(connectPubnubQuiz && (quizToken != null) && props.isAuthenticated) ?
            <PubNubUnicastHandler matchId={matchId} userName={props.userName} onDataReceivedQuizbot={messageFromUnicastCallback} sendMessage={unicastMessage} unSubscribe={unSubscribeChannel} token={quizToken}/> : ""}
         <div className={classNames(btnStyles.quizBoardWrapper, "App")}>

            <div className="chatbothead d-none d-md-block p-relative">
               <img src='https://storage.googleapis.com/meta11/serviceplatform/QBOTNew.webp' alt={"Qbot"} style={{width: "80px"}}/>
               <a onClick={() => setShowQbotMenu(!showQbotMenu)} className='icon-btn color-textturquiose float-right' style={{cursor: 'pointer'}}>
                  <HiOutlineCog/>
               </a>

            </div>

            <div style={{position: "relative", height: "493px", width: "100%", backgroundColor: "rgb(2, 19, 27)"}}>
               {
                  showQbotMenu ?
                     <ul className='q-bot-dropdown'>
                        <li>
                           <a className={classNames(btnStyles.QbotBtnWrapper)} onClick={handleMoreInfo}>
                                  <span
                                     className={classNames(btnStyles.QboticoWwrapper, 'color-textturquiose')}><IoMdInformationCircleOutline/></span>
                              <label>{t("PUB_NUB_QUIZ_BOT.HOW_TO_USE_QBOT")}</label>
                              <span
                                 className={classNames(btnStyles.QboticoWwrapper, "arrow-right1")}><MdOutlineKeyboardArrowRight/></span>
                           </a>
                        </li>
                        <li>
                           <a className={classNames(btnStyles.QbotBtnWrapper)}>
                              <span className={classNames(btnStyles.QboticoWwrapper, 'color-textturquiose')}><RxSpeakerModerate/></span>
                              <label>{t("PUB_NUB_QUIZ_BOT.SOUND (QBOT_SIGNAL)")}</label>
                              <span className={classNames(btnStyles.QboticoWwrapper, "arrow-right1")}>
                                    <div className={classNames(btnStyles.soundBtnWrapper)}>
                                        <div className={classNames(btnStyles.soundBtnContainer)}
                                             style={{backgroundColor: !isSoundEnabled ? "" : "#17EA85"}}>
                                            <div onClick={() => setSoundEnabled(!isSoundEnabled)}
                                                 className={classNames(!isSoundEnabled ? btnStyles.soundBtn : btnStyles.muteSoundBtn)}>
                                                <a href="#" className={classNames(btnStyles.speaker)}>
                                                    <span></span>
                                                    <b className={classNames(styles.xIcon)}>X</b>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                           </a>
                        </li>
                        <li>
                           <a className={classNames(btnStyles.QbotBtnWrapper)} onClick={hideLangWrapper}>
                              <img src={globe}/>&nbsp;&nbsp;&nbsp;
                              <label>{t("PUB_NUB_QUIZ_BOT.LANGUAGE")}</label>
                              <span className={classNames(btnStyles.QboticoWwrapper, "arrow-right1")}><MdOutlineKeyboardArrowRight/></span>
                           </a>
                           {isLangWrapperVisible && (
                              <ul className="langWrapper">
                                 <li>
                                    <label className="radioButtonlbl m-0 pe-2 w-100 p-0 mb-2 mb-sm-0">
                                       <input type="radio" name="radio" value="en" checked={language === 'en'} onChange={() => handleLanguageChange('en')}/>
                                       <span>{t("PUB_NUB_QUIZ_BOT.ENGLISH")}</span>
                                    </label>
                                    <label className="radioButtonlbl m-0 pe-2 w-100 p-0 mb-2 mb-sm-0">
                                       <input type="radio" name="radio" value="hi" checked={language === 'hi'} onChange={() => handleLanguageChange('hi')}/>
                                       <span>{t("PUB_NUB_QUIZ_BOT.HINDI")}</span>
                                    </label>
                                 </li>
                              </ul>
                           )}
                        </li>
                     </ul> : ""
               }

               <MainContainer style={{borderColor: "#1F2A36"}}>
                  <ChatContainer style={{overflowY: "auto"}}>

                     <MessageList className={classNames(btnStyles.mapessageListWrapper)}
                                  style={{backgroundColor: "#02131b", paddingTop: "5px"}}
                                  ref={msgListRef} scrollBehavior={"auto"}
                                  typingIndicator={isTyping ? <TypingIndicator content="QBOT is typing"/> : null}>
                        {loading ? loadingIndicator : <>
                           {state?.socketMessageState?.map((message, i) => {
                              if ((message?.messageBodyType == "QUESTION" || (message?.messageBodyType == "GREETING") || (message?.messageBodyType == "RESULT_CARD") || (message?.messageBodyType == "RESOLVE") || (message?.messageBodyType == "OVER_SUMMARY") || (message?.messageBodyType == "OVER_HISTORY")) && (message?.STATUS == BOT_STATUS.INITIALIZED || message?.STATUS == BOT_STATUS.ANSWERED)) {
                                 return (
                                    <>
                                       {message?.messageBodyType == "RESULT_CARD" ?
                                          <Message key={i} model={{sender: "user", direction: 'incoming', type: "html"}} className={classNames(btnStyles.msg1)}>
                                             <Message.CustomContent>
                                                <CardComponent data={message} lang={language} summaryMessages={overSummaryMessages}/>
                                             </Message.CustomContent>
                                          </Message>
                                          :
                                          <Message key={i} model={{sender: "user", direction: 'incoming', type: "html"}} className={classNames(btnStyles.msg2)}>
                                             <Message.CustomContent className={classNames(btnStyles.customContentWrapper)}>

                                                {message?.messageBodyType == "RESOLVE" ?
                                                   <CardComponent data={message} lang={language}/> :
                                                   <>
                                                      {message?.questionId === 100000 ?
                                                         <BallByBallCard okToResolve={okToResolve} cardData={message} onAnswerSelected={handleAnswerSend} disableCallback={handleTimesUp} lang={language} predictionResult={ballData} overSummary={overSummary}
                                                                         summaryMessages={overSummaryMessages}/> :

                                                         <>{message?.messageBodyType === "OVER_HISTORY" ?
                                                            <BallByBallHistory overSummary={overSummary} cardData={message} lang={language} summaryMessages={overSummaryMessages} predictionResult={ballData}/> :
                                                            <CardComponent data={message} onSubmit={handleAnswerSend} disableCallback={handleTimesUp} lang={language} summaryMessages={overSummaryMessages}/>}</>
                                                      }

                                                   </>

                                                   //   <AdaptiveCardComponent message={message} onSubmit={handleAnswerSend} card={JSON.parse(message?.adaptiveCard)} questionId={message?.questionId} type={JSON.parse(message?.adaptiveCard)?.cardType} time={message?.ttl}
                                                   //                          messageType={message?.messageBodyType} max={message?.maxAcceptance} answerList={message?.answers} disableCallback={handleTimesUp} questionRun={message?.questionRun}/>

                                                }
                                             </Message.CustomContent>
                                          </Message>
                                       }
                                    </>
                                 );
                              } else {
                                 return (
                                    <>
                                       {
                                          message?.messageBodyType == "REJECT" ? <>
                                             {

                                                message?.message != null && (message?.STATUS == BOT_STATUS.RESPONSE || message?.STATUS == BOT_STATUS.INITIALIZED) ?
                                                   <Message className={message?.type == "info" ? styles.infoMessage : ""} key={i} model={{message: mappedData.Reject[language][0].feedback_txt, direction: message?.direction}}>
                                                   </Message>
                                                   :
                                                   <></>
                                             }
                                          </> : <>
                                             {

                                                message?.message != null && (message?.STATUS == BOT_STATUS.RESPONSE || message?.STATUS == BOT_STATUS.INITIALIZED) ?
                                                   <Message className={message?.type == "info" ? styles.infoMessage : ""} key={i} model={{message: message?.message, direction: message?.direction}}>
                                                   </Message>
                                                   :
                                                   <></>
                                             }
                                          </>
                                       }
                                    </>

                                 )
                              }
                           })}
                        </>}
                     </MessageList>
                  </ChatContainer>
               </MainContainer>
            </div>
            <div className="chatbothead d-block d-md-none p-relative">
               <img src='https://storage.googleapis.com/meta11/serviceplatform/QBOTNew.webp' alt={"Qbot"} style={{width: "80px"}}/>
               <a onClick={() => setShowQbotMenu(!showQbotMenu)} className='icon-btn color-textturquiose float-right'>
                  <HiOutlineCog/>
               </a>
               {/* <div className={classNames(btnStyles.soundBtnWrapper)}>
                        <div className={classNames(btnStyles.soundBtnContainer)} style={{backgroundColor:!isSoundEnabled ? "":"#17EA85"}}>
                            <div onClick={()=> setSoundEnabled(!isSoundEnabled)} className={classNames(!isSoundEnabled ? btnStyles.soundBtn : btnStyles.muteSoundBtn )}>
                                <a  href="#" className={classNames(btnStyles.speaker)}>
                                    <span></span>
                                    <b className={classNames(styles.xIcon)}>X</b>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="chatboticon">
                        <svg onClick={handleMoreInfo} cursor="pointer" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 10V5.5M10 13.3354V13.375M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" stroke="#17EA85" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div> */}
            </div>

         </div>

         <Modal show={showMoreInfoModal} onHide={handleMoreInfoClose}>
            <Modal.Body>
               <HowToUseQBot handleMoreInfoClose={handleMoreInfoClose}/>
            </Modal.Body>
         </Modal>
      </React.Fragment>
   )
}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      refresh: state.auth.refreshToken,
      Token: state.auth.accessToken,
      userId: state.auth.userId,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      email: state.auth.email
   };
}

export default connect(mapStateToProps)(PubNubQuizBot)
