import {getConf} from "./ConfigService";
import {WALLET} from "../utilities/ConfigConstants";

export const getUSDValue = async () => {
   const BIRDEYE_API_KEY = getConf(WALLET.BIRDEYE_API_KEY);
   const BIRDEYE_WALLET_ADDRESS = getConf(WALLET.BIRDEYE_WALLET_ADDRESS);
   const options = {
      method: 'GET', headers: {'x-chain': 'solana', 'X-API-KEY': BIRDEYE_API_KEY}
   };

   try {
      const response = await fetch(`https://public-api.birdeye.so/defi/price?address=${BIRDEYE_WALLET_ADDRESS}`, options);

      if (response.ok) {
         return await response.json();
      } else {
         return null;
      }
   } catch (e) {
      console.error("Error in getUSDValue", e);
      return null;
   }
}
