import React, {useEffect, useState} from "react";
import NavbarSecondary from "../../components/NavigationBar/NavigationBar";
import delBen from "../../assets/images/delBen.webp";
import {FaSearch} from "react-icons/fa";
import {BsCheck2, BsDash, BsX} from "react-icons/bs";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Paginator from "../../components/Paginator/Paginator";
import Footer from "../../components/Footer/Footer";
import * as DigitalAssetsService from "../../services/DigitalAssetService";
import styles from "./MatchUps.module.scss";
import {Link, useLocation} from "react-router-dom";
import * as FavouriteService from "../../services/FavouriteService";
import HeartActive from "../../assets/images/HeartActive.svg";
import HeartDefault from "../../assets/images/HeartDefault.svg";
import {connect} from "react-redux";
import {formatDate, formatTime, isExistChecker} from "../../helpers/CommonFunctions";
import classNames from "classnames";
import {toast} from "react-toastify";
import {getConf} from "../../services/ConfigService";
import {MATCHUPS_PAGE} from "../../utilities/ConfigConstants";
import {useTranslation} from "react-i18next";
import GuidanceTip from "../../components/GuidanceTip/GuidanceTip";
import AdvertisementSpace from "../../components/AdvertisementSpace/AdvertisementSpace";

const MatchUps = (props) => {

    const location = useLocation();
    let SHOW_MATCH_UPS_ADVERTISEMENT_URL = getConf(MATCHUPS_PAGE.SHOW_MATCH_UPS_ADVERTISEMENT_URL);
    const userName = new URLSearchParams(location.search).get('userName');
    const currentUrl = location.pathname + location.search;


    const [teams, setTeams] = useState([]);
    let [loadingInProgress, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostsPerPage] = useState(9);
    const [totalElements, setTotalElements] = useState(0);
    const [search, setSearch] = useState("");
    const [favouriteTeams, setFavouriteTeams] = useState([]);
    const [user, setUser] = useState({});
    const [pageLang, setPageLang] = useState(localStorage.getItem('language'));


    useEffect(() => {
        setLoading(true);
        let mounted = true;
        const fetchData = async () => {
            if (!userName) {
                await DigitalAssetsService.getAllTeamsCardsAssetData(search, currentPage, postsPerPage,pageLang)
                    .then((response) => {
                        if (mounted) {
                            setTeams(response.teams);
                            setTotalElements(response.totalElements);
                            setLoading(false);
                        }
                    });
            } else if (userName) {
                await DigitalAssetsService.getFavouriteTeamCardsByUserName(userName, currentPage, postsPerPage, 'teamId,desc').then((response) => {
                    if (response.apiStatus) {
                        setTeams(response.teams);
                        setTotalElements(response.totalElements);
                        setLoading(false);
                    }
                });
            }
        };
        fetchData();
        return () => {
            mounted = false;
        };
    }, [search, postsPerPage, currentPage, userName]);

    useEffect(() => {
        const fetchDetails = async () => {
            if (props.isAuthenticated) {
                await FavouriteService.getFavourites(props.Token, "team", props.userName, 0, 100).then((response) => {
                    if (response.data.apiStatus) {
                        setFavouriteTeams(response.data.userFavourites);
                    } else if (response.status == 401) {
                        //toast.error(COMMON_TOASTS.UNAUTHORIZED_REQUEST);
                    }
                });
                favouriteTeamController();
            }
        };
        fetchDetails();
    }, [user]);

    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"});
    }, [])
    const paginate = (pageNumber, postsPerPage) => {
        setPostsPerPage(Number(postsPerPage));
        setCurrentPage(pageNumber);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    };

    const getMatchStatus = (matchStatus) => {
        if (matchStatus) {
            switch (matchStatus) {
                case "WIN":
                    return (<span className="matchIndicator greenInd"><BsCheck2/></span>);
                case "LOST":
                    return (<span className="matchIndicator purpleInd"><BsX/></span>);
                case "DRAW":
                    return (<span className="matchIndicator grayInd"><BsDash/></span>);
                default:
                    return (<span className="matchIndicator grayInd"><BsDash/></span>);
            }
        } else {
            return (<span className="matchIndicator grayInd"><BsDash/></span>);
        }
    };

    const getFirst2Letters = (teamName) => {
        const words = teamName.split(' ');
        const firstLetters = words.map(word => word.charAt(0).toUpperCase());
        return firstLetters.slice(0, 2).join('');
    }

    const favouriteTeamController = (teamName, teamID) => {
        if (isExistChecker(favouriteTeams, teamID)) {

            return (
                <Link to={currentUrl}>
                    <button onClick={() => updateFavouriteTeam(false, teamName, teamID)} className="heartLikeFill" style={{top: 15, right: 15}}>
                        <img src={HeartActive} alt="HeartActive" className="heartActive"/>
                    </button>
                </Link>
            );
        } else {
            return (
                <Link to={currentUrl}>
                    <button onClick={() => updateFavouriteTeam(true, teamName, teamID)} className="heartLike" style={{top: 15, right: 15}}>
                        <img src={HeartDefault} alt="HeartDefault" className="heartDefault"/>
                    </button>
                </Link>
            );
        }
    };

    const updateFavouriteTeam = async (isFavourite, teamName, teamId) => {
        if (teamName != undefined || teamName != null) {

            if (isFavourite == true && favouriteTeams?.length > 0) {
                setFavouriteTeams(prevState => [...prevState, {id: teamId, name: teamName}]);
            } else if (isFavourite == false && favouriteTeams?.length > 0) {
                setFavouriteTeams((current) => current.filter((team) => team.id != teamId))
            } else if (isFavourite == true && favouriteTeams?.length == 0) {
                setFavouriteTeams([{id: teamId, name: teamName}]);
            } else if (isFavourite == false && favouriteTeams?.length == 0) {

            }
            const toastId = toast.loading(`${isFavourite ? t("TOAST.ADD") : t("TOAST.REMOVE")} ${t("TOAST.MATCH_UP")} ${teamName} ${isFavourite ? t("TOAST.TO") : t("TOAST.FROM")} ${t("TOAST.FAVOURITES")}`, {autoClose: 4000, closeButton: null})
            await FavouriteService.updateFavoriteTeam(props.Token, {
                id: parseInt(teamId),
                like: isFavourite,
                name: teamName,
                userName: props.userName
            }).then((response) => {
                if (response.data.apiStatus) {
                    setUser(response.data.user);
                    toast.update(toastId, {
                        render: `${t("TOAST.SUCCESSFULLY")} ${isFavourite ? t("TOAST.ADDED") : t("TOAST.REMOVED")} ${t("TOAST.THE_MATCH_UP")} ${teamName} ${isFavourite ? t("TOAST.TO") : t("TOAST.FROM")} ${t("TOAST.FAVOURITES!")}`,
                        type: toast.TYPE.SUCCESS,
                        autoClose: 5000,
                        closeButton: null,
                        isLoading: false
                    })
                } else if (response.status == 401) {
                    setFavouriteTeams((current) => current.filter((team) => team.id != teamId))
                    toast.update(toastId, {
                        render: t("TOAST.ERROR_UPDATING_FAVOURITE_MATCH_UP"),
                        type: toast.TYPE.ERROR,
                        autoClose: 5000,
                        closeButton: null,
                        isLoading: false
                    })
                    //toast.error(COMMON_TOASTS.UNAUTHORIZED_REQUEST);
                } else {
                    setFavouriteTeams((current) => current.filter((team) => team.id != teamId))

                    toast.update(toastId, {
                        render: t("TOAST.ERROR_UPDATING_FAVOURITE_MATCH_UP"),
                        type: "error",
                        autoClose: 5000,
                        closeButton: null,
                        isLoading: false
                    })
                    // toast.error(response.data.rejectMessages[0]);
                }
            });
        } else {
            console.log("Error occurred in marking Favourite Match-Up.");
        }
    };

    const { t } = useTranslation();
    const [translationsLoaded, setTranslationsLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setTranslationsLoaded(true);
        }, 1000);
    }, []);

    const loadingIndicator = (
        <div className="w-100 text-center">
            <div style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <div className="lds-facebook">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>);
   /*guidance tip*/
   const [nextGotItIndex, setNextGotItIndex] = useState(0)
   const [tipIndexCounter, setTipIndexCounter] = useState(1)

   const gotItHandler = () => {
      setNextGotItIndex(nextGotItIndex+1)
      setTipIndexCounter(tipIndexCounter+1)
   }

   const tipCancelHandler = () => {
      setNextGotItIndex(-1)
   }
    return (
        <>
            <div className={classNames(styles.ResponceCont, "col-12", "row", "pb-2", "ps-2", "pe-2", "ps-xl-0", "pe-xl-0")}>

                <div className="col-12 p-0 row">
                    <NavbarSecondary page="matches"/>
                </div>
                {!translationsLoaded? <> {loadingIndicator} </> :
                <>
                <div className="row gx-4 mt99 p-0">
                    <div className="col-12 cil-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 p-0">
                       <h1 className="mainHeaderH1 subMainHeaderH1" style={{marginTop: 0}}>{t("MATCH_UPS.TEAMS")}</h1>
                    </div>
                    <div className="col-12 cil-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 selectWrapper p-0">
                       <GuidanceTip page="matchups" gotItIndex={0} gotItHandler={gotItHandler} nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler} tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}/>

                       <div className="fc-wrapper player-fc-wrapper float-right">
                            {userName ? <></> :
                                <InputGroup className="searchtxt playerSearch">
                                    <Form.Control style={{height: '54px'}} placeholder={t("MATCH_UPS.SEARCH")} onChange={(e) => {
                                        setSearch(e.target.value);
                                        setCurrentPage(0)
                                    }} aria-label="Search..." aria-describedby="basic-addon1"/>
                                    <InputGroup.Text id="basic-addon1">
                                        <FaSearch/>
                                    </InputGroup.Text>
                                </InputGroup>
                            }
                        </div>

                    </div>
                </div>
                <div className="row gx-4 p-0">
                    <div className="col-12 p-0">
                        <div className="row gx-0 mt40">
                            <div className="col-12 col-xxl-9 col-xl-9 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 ">
                                {loadingInProgress ?<div className="w-100 text-center">
                                    <div className={styles.loaderContainer}>
                                        <div className="lds-facebook">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div></div>
                                    :
                                    <>
                                        {teams ? (
                                            <div className="matchCards mb40">
                                                {teams?.map((team) => (
                                                    <div className="col-12 col-md-6 col-lg-4 pe-0 pe-md-3 m-0 ps-0 ps-md-2" key={team?.teamId}>
                                                        <Link to={{pathname: `/team/${team?.teamId}`, search: `returnUrl=${encodeURIComponent(location.pathname)}`}} style={{textDecoration: "none"}} className={classNames(styles.matchCardInnerWrapper)}>
                                                            <div className="teamDetail teamsTeamDetail">
                                                                {
                                                                    props.isAuthenticated ? <>
                                                                        <a>{favouriteTeamController(team?.name, team?.teamId)}</a>
                                                                    </> : <></>
                                                                }

                                                                <div className="TeamsteamLogo">
                                                                    {/*<div className={classNames(styles.shield)} style={{backgroundImage: `${team?.teamPictureURL}`}}></div>*/}
                                                                    <img className={classNames(styles.TeamLogoImg)}
                                                                         src={team?.teamPictureURL} alt={""}/>
                                                                </div>
                                                                <span className={classNames("TeamsteamName",styles.teamNameF20)} style={{textDecoration: "none", display: "flex", justifyContent: "center"}}>{team?.name && team?.name.length > 17 ? team.name.substring(0, 17) + '...' : team?.name}</span>
                                                                <span className="winsLabel">{team?.totalWinCount ? team?.totalWinCount : '0'} {t("MATCH_UPS.WINS")} •{" "}{team?.totalLossCount ? team?.totalLossCount : '0'} {t("MATCH_UPS.LOSES")}</span>
                                                                <span className="matchStatWrapper">
                                                                    {team.matchResults ? <>
                                                                        {team?.matchResults[0] ? getMatchStatus(team?.matchResults[0]) : <span className="matchIndicator ">-</span>}
                                                                        {team?.matchResults[1] ? getMatchStatus(team?.matchResults[1]) : <span className="matchIndicator ">-</span>}
                                                                        {team?.matchResults[2] ? getMatchStatus(team?.matchResults[2]) : <span className="matchIndicator ">-</span>}
                                                                        {team?.matchResults[3] ? getMatchStatus(team?.matchResults[3]) : <span className="matchIndicator ">-</span>}
                                                                        {team?.matchResults[4] ? getMatchStatus(team?.matchResults[4]) : <span className="matchIndicator">-</span>}
                                                                    </> : <>
                                                                        <div className={styles.emptyMatchStatus}>{t("MATCH_UPS.NO_PAST_MATCH_DATA_AVAILABLE")}</div>
                                                                    </>}
                                                                </span>
                                                            </div>
                                                            {/*<div className={classNames(styles.cardFooter ,styles.borderTop1, "p-0 pt-2")}>
                                                                <div className="p-1 row col-12 mb-2">
                                                                    <button className={classNames(styles.heartLike)}  style={{ right: 15}}>
                                                                    <img src={HeartDefault} alt="HeartDefault" />
                                                                    </button>
                                                                <div className="col-7 p-0  ps-1">Nationality<br/>
                                                                <div className={classNames(styles.B12text)}>British Realm</div>
                                                                </div>
                                                                <div className="col-5 p-0 pe-1">Age<br/>
                                                                <div className={classNames(styles.B12text)}>28</div>
                                                                </div>

                                                                <div className="col-7 p-0  ps-1 mt-2">Batting style<br/>
                                                                <div className={classNames(styles.B12text)}>right-hander</div>
                                                                </div>
                                                                <div className="col-5 p-0 pe-1 mt-2">Bowling style<br/>
                                                                <div className={classNames(styles.B12text)}>right hand</div>
                                                                </div>
                                                                </div>

                                                                    </div>*/}

                                                            {team.nextMatch && team.nextMatchWithTeamName ? (
                                                                <div className={classNames(styles.cardFooter, styles.borderTop2, "p-0")}>
                                                                    <div className="p-1 row col-12 mt-2 mb-2">
                                                                        <div className="col-7 p-0  ps-1">Next:<br/>
                                                                            <div className={classNames(styles.W12text)}>{" "}{formatDate(team?.nextMatch)}&nbsp;{formatTime(team?.nextMatch)}</div>
                                                                        </div>
                                                                        <div className="col-5 p-0 pe-1 ps-2 row">
                                                                            <div className="col-4 p-0"><img src={team?.nextMatchWithTeamPicURL} onError={(e) => {
                                                                                e.target.onerror = null;
                                                                                e.target.src = {delBen};
                                                                            }} width={"25px"}></img></div>
                                                                            <div className={classNames(styles.W10text, "col-6 p-0 pt-2")}>{team?.nextMatchWithTeamName ? team?.nextMatchWithTeamName : '--'}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>) : (

                                                                <div className={classNames(styles.cardFooter, styles.borderTop2, "p-2")}>
                                                                    <div className={styles.noMatchText}>{t("MATCH_UPS.NEXT_MATCH_IS_NOT_SCHEDULED_YET")}</div>
                                                                </div>)}

                                                        </Link>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                          <div className="matchCards mb40">{t("MATCH_UPS.0_MATCHUPS_FOUND")}</div>
                                        )}
                                       <GuidanceTip page="matchups" gotItIndex={1} gotItHandler={gotItHandler} nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler} tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}/>
                                       <GuidanceTip page="matchups" gotItIndex={2} gotItHandler={gotItHandler} nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler} tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}/>

                                       <div className="pageWrap">
                                            <Paginator postsPerPage={9} totalElements={totalElements} currentPage={currentPage} paginate={paginate}/>
                                        </div>
                                    </>}
                            </div>
                           <AdvertisementSpace imageUrl1={SHOW_MATCH_UPS_ADVERTISEMENT_URL}/>
                        </div>
                    </div>

                </div>
                </>}

            </div>
            {!translationsLoaded? <> {loadingIndicator} </> :
            <>
            <div  className={loadingInProgress && classNames(styles.Footer)}>
                <Footer/>
            </div>
             </>}

        </>



    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.accessToken != null || undefined,
        profileImage: state.auth.imageURL,
        userName: state.auth.userName,
        email: state.auth.email,
        Token: state.auth.accessToken,
        RefreshToken: state.auth.refreshToken,
        timeOut: state.auth.sessionTimeout,
    };
};
export default connect(mapStateToProps)(MatchUps);
