import Modal from "react-bootstrap/Modal";
import React from "react";
import {BsXLg} from "react-icons/bs";
import {Link} from "react-router-dom";
import classNames from "classnames";
import styles from "./SocialShareModal.module.scss";
import iconEmail from "../../../assets/images/iconEmail.webp";
import iconFb from "../../../assets/images/iconFb.webp";
import iconMes from "../../../assets/images/iconMes.webp";
import iconWhat from "../../../assets/images/iconWhat.webp";
import iconX from "../../../assets/images/iconX.webp";
import {useTranslation} from "react-i18next";
import {EmailShareButton, FacebookMessengerShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton} from "react-share";
import {formatNumberToKWithoutRounding, getPositionWithText, handleCopy} from "../../../helpers/CommonFunctions";
import {getDisplayName} from "../../../helpers/LeaderBoardHelpers";
import OmniPoints from "../../../assets/images/icons/24_xp.webp";

const SocialShareModal = ({isShowModal, modalCloseHandler, sharePath, currentUser, isAuthenticated, isSharingPoints}) => {

   const {t} = useTranslation();

   const shareUrl = process.env.REACT_APP_SOCIALSHARE_URL + sharePath;
   const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
   const pointShareDescription = `${t("SOCIAL_SHARE_MODAL.GUESS_WHO'S_SLAYING_ON_META_11_ITS_ME_WITH")} ${currentUser?.points} ${t("SOCIAL_SHARE_MODAL.POINTS_LOCKED_AND_LOADED_AT")} ${getPositionWithText(currentUser?.position)} ${t("SOCIAL_SHARE_MODAL.PLACE_AND_RISING! HURRY_UP_DIVE_INTO_THE_META_11_ARENA_FEEL_THE_ADRENALINE_CATCH_THE_LIVE_GAMES_AND_PREDICT_MATCH_OUTCOMES_TO_SCORE_EXCLUSIVE_PRIZES")}`;
   const matchShareDescription = t("SOCIAL_SHARE_MODAL.META_11_MATCH_ON_FIRE  CALLING_ALL_CRICKET_ENTHUSIASTS_TO_WITNESS_THE_BATTLE_LIVE  GRAB_YOUR_VIRTUAL_SEATS_AND_ENJOY_THE_THRILL_WATCH_THE_SPARKS_FLY_AND_BOUNDARIES_BREAK");

   const shareContentText = isSharingPoints ? pointShareDescription : matchShareDescription;

   const twitterHashTags = ['Meta11'];

   return <Modal show={isShowModal} size="lg" className="socialModalWidth">

      <Modal.Body className="ps-1 ps-md-5 pe-1 pe-md-5" style={{position: "relative"}}>
         <div className={classNames(styles.ellipse, styles.e29)}></div>
         <div className={classNames(styles.ellipse, styles.e30)}></div>
         <a className="closeModal" style={{cursor: 'pointer'}}>
            <BsXLg onClick={() => {
               modalCloseHandler(false)
            }}/></a>
         <p className="heading4 semi-bold upper-case italic" style={{textAlign: "center"}}>
            {isSharingPoints ? t("SOCIAL_SHARE_MODAL.SHARE_YOUR_RESULT") : t("SOCIAL_SHARE_MODAL.SHARE_MATCH")}</p>
         {isAuthenticated && currentUser ? <div className={classNames(styles.SplashBox, "col-12", "row")}>
            <div className={classNames(styles.leaderBox, "col-12", "p-0")} style={{flexDirection: "column"}}>
               <div className={classNames(styles.curcleOut2, "pt-0")}>
                  <div className={classNames(styles.upDown)}><img
                     src="https://storage.googleapis.com/meta11/serviceplatform/CrownVector.png"
                     alt="Crown Icon"/></div>
                  <div className={classNames(styles.OutCurcle)}>
                     <img src={currentUser?.profilePicUrl} className={classNames(styles.proTopUserImg)}/>
                  </div>
                  <div className={classNames(styles.curcleCount)}>{currentUser?.position}</div>
               </div>

               <Link>
                  <div
                     className={classNames("body-text2 color-gray2", "text-center mt-3")}>
                     {getDisplayName(currentUser?.firstName, currentUser?.lastName, currentUser?.userName, 36)}
                     <br/>

                     <i className="fa-sharp fa-solid fa-lock"
                        style={{
                           fontSize: "12px",
                           color: "FF0000",
                           display: !(currentUser?.isPrivate) && "none"
                        }}></i>
                  </div>
               </Link>
               <div className={classNames(styles.marks, "text-center")} style={{lineHeight: "25px"}}>
                  <img className="mr-2" src={OmniPoints}/>
                  <span>{formatNumberToKWithoutRounding(currentUser?.points, 2)}</span>
               </div>
            </div>
         </div> : <></>}
         <div className="ps-2 pe-2 row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-12 ms-auto me-auto row mb-2">
               <div className={classNames(styles.txtWrap, "col-12 col-sm-6 col-lg-8 col-xl-8  pe-0 ps-1 pe-sm-2  col-md-7 mb-2 mb-md-0")}>
                  {/*<Form.Control type="text" placeholder="Readonly input here..."/>*/}
                  <input type="text" name="name" value={shareUrl} disabled className={classNames(styles.inputHome)}/>
               </div>
               <div className={classNames(styles.btnWrap, "col-12 col-xl-4 col-lg-4 col-md-5 col-sm-6 ms-auto me-auto p-0")}>
                  <button className="lightGreenButton" style={{width: "100%"}} onClick={(event) => {
                     handleCopy(event, shareUrl, isSharingPoints ? t("SOCIAL_SHARE_MODAL.LEADERBOARD_LINK_HAS_BEEN_COPIED_TO_THE_CLIPBOARD") : t("SOCIAL_SHARE_MODAL.MATCH_LINK_HAS_BEEN_COPIED_TO_THE_CLIPBOARD"))
                  }}>{t("SOCIAL_SHARE_MODAL.COPY_LINK")}</button>
               </div>
            </div>
            <div className="col-12">

               <TwitterShareButton className="w-100" url={shareUrl} hashtags={twitterHashTags} title={shareContentText}>
                  <button className="black-social-btn d-flex justify-content-center align-items-center w-100 mb-2">
                     <img src={iconX} style={{marginRight: 10, width: 24}}/> {t("SOCIAL_SHARE_MODAL.SHARE_ON_X")}
                  </button>
               </TwitterShareButton>
               <FacebookShareButton url={shareUrl} hashtag={"#Meta11"} className="w-100">
                  <button className="black-social-btn d-flex justify-content-center align-items-center w-100 mb-2">
                     <img src={iconFb} style={{marginRight: 10, width: 24}}/> {t("SOCIAL_SHARE_MODAL.SHARE_ON_FACEBOOK")}
                  </button>
               </FacebookShareButton>
               <FacebookMessengerShareButton url={shareUrl} appId={facebookAppId} redirectUri={"https://meta11.com"} className="w-100">
                  <button className="black-social-btn d-flex justify-content-center align-items-center w-100 mb-2">
                     <img src={iconMes} style={{marginRight: 10, width: 24}}/> {t("SOCIAL_SHARE_MODAL.SHARE_VIA_MESSENGER")}
                  </button>
               </FacebookMessengerShareButton>
               <WhatsappShareButton url={shareUrl} title={shareContentText} separator='' className="w-100">
                  <button className="black-social-btn d-flex justify-content-center align-items-center w-100 mb-2">
                     <img src={iconWhat} style={{marginRight: 10, width: 24}}/> {t("SOCIAL_SHARE_MODAL.SHARE_VIA_WHATSAPP")}
                  </button>
               </WhatsappShareButton>
               <EmailShareButton url={shareUrl} subject={'Meta11 Gaming'} body={shareContentText} className="w-100">
                  <button className="black-social-btn d-flex justify-content-center align-items-center w-100 mb-2">
                     <img src={iconEmail} style={{marginRight: 10, width: 24}}/> {t("SOCIAL_SHARE_MODAL.SHARE_VIA_EMAIL")}
                  </button>
               </EmailShareButton>
            </div>
         </div>
         {/* <div className={classNames(styles.leaderBox, "col-6", "p-0")}>
                                            <div className={classNames(styles.curcleOut2)}>
                                              <div className={classNames(styles.upDown)}><img
                                                src="https://storage.googleapis.com/meta11/serviceplatform/CrownVector.png"
                                                alt="Crown Icon"/></div>
                                              <div className={classNames(styles.OutCurcle)}>
                                                {getProfileImage(firstThreeLBUsers?.[0]?.profilePicUrl,firstThreeLBUsers?.[0]?.firstName, firstThreeLBUsers?.[0]?.lastName, true)}
                                              </div>
                                              <div className={classNames(styles.curcleCount)}>1</div>
                                            </div>
                                            <Link to={firstThreeLBUsers?.[0]?.private ? '' : {
                                              pathname: `/user/${btoa(firstThreeLBUsers?.[0]?.userName)}`,
                                              search: `returnUrl=${encodeURIComponent(location.pathname)}`
                                            }}>
                                              <div
                                                className={classNames(styles.trophyTextW, "text-center", "mt-3", "mb-3")}>{getDisNameForFirst3Users(firstThreeLBUsers?.[0]?.firstName, firstThreeLBUsers?.[0]?.lastName)[0]}<br/>
                                                {getDisNameForFirst3Users(firstThreeLBUsers?.[0]?.firstName, firstThreeLBUsers?.[0]?.lastName)[1]} {firstThreeLBUsers?.[0]?.private ?
                                                  <i className="fa-sharp fa-solid fa-lock"
                                                     style={{
                                                       fontSize: "12px",
                                                       color: "FF0000"
                                                     }}></i> : <></>}
                                              </div>
                                            </Link>
                                            <div className={classNames(styles.marks, "text-center")} style={{lineHeight: "25px"}}>
                                              <i className="fa-sharp fa-solid fa-trophy"></i>
                                              <span>
                                                                    {formatNumberToK(firstThreeLBUsers?.[0]?.points, 2)}
                                                                </span>
                                            </div>
                                          </div> */}
      </Modal.Body>
   </Modal>
}

export default SocialShareModal;
