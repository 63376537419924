import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import {sequence} from "0xsequence";
import styles from './PaymentWallet.module.scss'
import {Button} from "react-bootstrap";
import {toast} from "react-toastify";
import {ethers} from "ethers";

const PaymentWallet = (props) => {

   const [wallet, setWallet] = useState();
   const [chainId, setChainId] = useState(80001);
   const [walletDetails, setWalletDetails] = useState();
   const [address, setAddress] = useState('');
   const [isConnected, setIsConnected] = useState(false);
   const [signature, setSignature] = useState('');
   const [isVerified, setIsVerified] = useState(false);

   useEffect(() => {
      setWallet(sequence.provider.Wallet);
      setWalletDetails(sequence.provider.ConnectDetails);
   }, [props])


   // async function etherSign() {
   //     try {
   //         const result = await contract.someFunction(param1, param2);
   //         console.log('Function result:', result);
   //     } catch (error) {
   //         console.error('Error calling function:', error);
   //     }
   // }


   async function connectWallet() {

      const wallet = await sequence.initWallet('AQAAAAAAAAb2PBqMmnC9WxFXd1QB0dlEsMo', {
         defaultNetwork: 'amoy' //prod->'polygon' , QA-> 'amoy'
      });
      const connectDetails = await wallet.connect({
         app: 'META11 Wallet',
         askForEmail: true,
         authorize: true,
         // And pass settings if you would like to customize further
         settings: {
            theme: "dark",
            signInOptions: [
               'email',
               'google',
               'apple'
            ],
            bannerUrl: "https://storage.googleapis.com/meta11/serviceplatform/WalletHeader.png", // 3:1 aspect ratio, 1200x400 works best
            includedPaymentProviders: ["transak"],
            defaultFundingCurrency: "matic",
            lockFundingCurrencyToDefault: true,
         }
      });

      const address = await wallet.getAddress();

      console.log(connectDetails)
      if (connectDetails.connected) {
         toast.success('Connected to the wallet successfully');
         setIsConnected(true);
         setWalletDetails(connectDetails);
         setWallet(wallet);
         setAddress(address);
      }
   }

   async function openWallet() {
      if (isConnected) {
         wallet?.openWallet();
      }
   }

   async function signMsg() {
      const signer = wallet?.getSigner();
      const msg = "Hello World!";
      const sig = await signer?.signMessage(msg);
      setSignature(sig);
   }

   async function verifySignature() {
      const provider = wallet?.getProvider();
      if (signature !== undefined && provider !== undefined && walletDetails !== undefined) {
         const isValid = await sequence.utils.isValidMessageSignature(
            address,
            "Hello World!",
            signature,
            provider,
            walletDetails?.chainId
         )
         if (isValid) {
            setIsVerified(true);
         }
      }
   }

   // async function sendTx() {
   //    const tx = {
   //       to: "0x807745ed59A4269a58ba3bd8E2a2b5Fe007Cf031",
   //       value: 1000000000000000 //currency in Wei (10-18)
   //    }
   //    console.log(walletDetails)
   //    const signer = wallet?.getSigner();
   //    const txResponse = await signer?.sendTransaction(tx, chainId)
   //    const done = await txResponse?.wait();
   //    console.log(txResponse);
   //    console.log(done);
   // }
   async function sendTx() {
      // Part of the ERC20 ABI, so we can encode a `transfer` call
      const erc20Interface = new ethers.utils.Interface([
         'function makePayment(string calldata targetGameAccount) external payable'
      ])

      const wallet = sequence.getWallet()
      const signer = wallet.getSigner()

      // const usdcContractAddress = '0xCb5B1b1D90Ed696465aEcc96189E2876A4A27e45'
      const usdcContractAddress = '0x620945b2c3a42DFDEF5530db64Ca2D10da395308'

      // const recipientAddress = '0x8b4de256180cfec54c436a470af50f9ee2813dbb'

      // Sending 1.50 USDC, note USDC has 6 decimal places
      //const amount = ethers.WeiPerEther('1.50')

      // Encode an ERC-20 token transfer to recipient of the specified amount
      //m11QA username, packageId
      const data = erc20Interface.encodeFunctionData(
         'makePayment', ["tirancm"]
      )

      // Prepare Transaction object
      const tx: sequence.transactions.Transaction = {
         to: usdcContractAddress,
         data: data,
         value: 500000000000000
      }

      // Send the transaction via the signer to the blockchain :D The signer will prompt the user
      // sign+send the transaction, and once the user confirms it will be transmitted.
      const txnResp = await signer.sendTransaction(tx)

      // We're done, print the transaction hash, and open it up in your block explorer
      console.log('transaction hash:', txnResp.hash)
   }


   return (
      <div className={styles.container}>
         <main className={styles.main}>
            {isConnected ? (
                  <>
                     <p>Connected to address: {address}</p>
                     <Button onClick={openWallet} variant="contained" size="large" style={{color: 'white'}}>Open Wallet</Button>
                     <p></p>
                     {signature ? (
                        <div>
                           <p>Signature: {signature.substring(0, 18) + '...'}</p>
                           {isVerified ? (
                              <p>Signature verified correctly.</p>
                           ) : (
                              <Button onClick={verifySignature} variant="contained" size="large" style={{color: 'white'}}>Verify Signature</Button>
                           )}
                        </div>
                     ) : (
                        <Button onClick={signMsg} variant="contained" size="large" style={{color: 'white'}}>Sign 'Hello World!'</Button>
                     )}
                     <p></p>
                     <Button onClick={sendTx} variant="contained" size="large" style={{color: 'white'}}>Send Credits to Meta11</Button>
                  </>
               ) :
               (
                  <Button onClick={connectWallet} variant="contained" size="large" style={{color: 'white'}}>Sign In</Button>
               )}

         </main>
      </div>
   )
};
const mapDispatchToProps = (dispatch) => {
   return {
      onLogout: () => dispatch(actions.logout()),
   };
};
const mapStateToProps = (state) => {
   return {
      token: state.auth.accessToken, isAuthenticated: state.auth.accessToken !== null, profileImage: state.auth.imageURL, oAuthProviderType: state.auth.oAuthProviderType, userName: state.auth.userName, name: state.auth.name
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentWallet);
