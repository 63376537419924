import classNames from "classnames";
import Classnames from "classnames";
import classnames from "classnames";
import React, {useEffect, useState} from "react";
import styles from "./Notifications.module.scss";
import NavbarSecondary from "../../components/NavigationBar/NavigationBar"
import Footer from "../../components/Footer/Footer";
import {connect} from "react-redux";
import Paginator from "../../components/Paginator/Paginator";
import {capitalize1stLetter, getConvertedTime, getNotificationTypeIcon, getTimeAgoString} from "../../helpers/CommonFunctions";
import {useNavigate} from "react-router-dom";
import * as InteractionService from "../../services/InteractionService";
import UserDataChannelHandler from "../../services/PubNubHandlers/UserDataChannelHandler";
import {useTranslation} from "react-i18next";
import * as PubNubService from "../../services/PubNubService";
import {NOTIFICATION_TYPES} from "../../utilities/Enums";

const Notifications = ({Token, userName, isAuthenticated}) => {
   const {t} = useTranslation();
   let navigate = useNavigate();
   const pageLang = localStorage.getItem('language') || 'en';

   const [isLoading, setIsLoading] = useState(true);
   const [currentPage, setCurrentPage] = useState(0);
   const [postsPerPage, setPostsPerPage] = useState(10);
   const [totalElements, setTotalElements] = useState(0);
   const [userNotifications, setUserNotifications] = useState([]);
   const [pubNubToken, setPubNubToken] = useState(null);
   const [userConnectPubnub, setUserConnectPubnub] = useState(false);
   const [subscribeChannel, setSubscribeChannel] = useState(null);
   const [unSubscribeChannel, setUnsubscribeChannel] = useState(null);


   const paginate = (pageNumber, postsPerPage) => {
      pageNumber !== currentPage && setIsLoading(true);
      setPostsPerPage(Number(postsPerPage));
      setCurrentPage(pageNumber);
   };

   const handleNewNotificationReceive = (messages) => {
      setUserNotifications((prevState) => {
         const newMessage = messages?.notifications[0];
         if (newMessage) {
            return [newMessage, ...prevState];
         }
         return prevState;
      });
   };

   useEffect(() => {
      const fetchData = async () => {
         try {
            await PubNubService.requestAccessTokenQuiz(userName, 1234, 1234, Token).then(response => {
               if (response != null) {
                  setPubNubToken(response);
                  setUserConnectPubnub(true);
               }
            });
         } catch (error) {
            console.error("Error:", error);
         }

      };
      if (isAuthenticated) {
         fetchData();
      }
   }, [Token, isAuthenticated, userName]);

   useEffect(() => {
      if (isAuthenticated) {
         setIsLoading(true);
         const getNotifications = async () => {
            await InteractionService.GetNotificationsByUserName(userName.toLowerCase(), Token, currentPage, 'notifiedTime,desc', postsPerPage, pageLang)
               .then((response) => {
                  if (response?.data?.apiStatus) {
                     setUserNotifications(response?.data?.notifications);
                     setIsLoading(false);
                     setTotalElements(response?.data?.totalElements);
                  }
               })
         }
         getNotifications();
      }
   }, [userName, Token, isAuthenticated, pageLang, currentPage, postsPerPage]);

   useEffect(() => {
      if (!localStorage.getItem('accessToken')) {
         navigate("/login");
      }
   }, [userName, Token]);

   const loadingIndicator = (<div className="text-center">
      <div className="lds-facebook">
         <div></div>
         <div></div>
         <div></div>
      </div>
   </div>);

   return (<div className={classNames(styles.bodyLeaderBoardOld, "ps-2 pe-2 ps-xxl-0 pe-xxl-0")}>
      {isAuthenticated && <>
         {(userConnectPubnub && pubNubToken != null) &&
            <UserDataChannelHandler handleNewNotificationReceive={handleNewNotificationReceive}
                                    handelWalletDataRecived={(data) => {
                                       console.log("handelWalletDataReceived", data);
                                    }}
                                    pubNubToken={pubNubToken}
                                    subscribe={subscribeChannel}
                                    onUnsubscribe={unSubscribeChannel}/>}
      </>}
      <div className={classNames(styles.pageWrapper, styles.TransactionsPage)}>
         <div
            className={classNames(styles.ResponceCont, "col-12", "row", "pb-2", "ps-2", "pe-2", "ps-xl-0", "pe-xl-0")}>
            <div className="col-12 p-0 row">
               <NavbarSecondary page="events"/>
            </div>
            <div className="container p-0">
               <div className="row gx-0">
                  <div className="col-md-12 col-xl-12">
                     <div className="row gx-0 mt-1">
                        <div className="col-12 ">
                           <h2 className={classNames(styles.sectionTitle, "mt-4 mb-4 w-100 ccccc")}>
                              Notifications
                           </h2>
                           <div className="col-12 p-0 mt-3">
                              {isLoading ? loadingIndicator : <>
                                 <div className="row p-0">
                                    <div
                                       className={Classnames(styles.FantasyHistoryTable, styles.transactionsTable, 'z-1')}>
                                       {userNotifications?.length === 0 ?
                                          <div className={Classnames(styles.NoFantasyHistoryData)}>
                                             No notification available.
                                          </div> : <>
                                             <div className={Classnames(styles.TableWrapper)}>
                                                <table className={'w-100'}>
                                                   <thead>
                                                   <tr>
                                                      <th></th>
                                                      <th>Type</th>
                                                      <th>Description</th>
                                                      <th>Time</th>
                                                   </tr>
                                                   </thead>
                                                   <tbody>
                                                   {userNotifications?.map((notification, index) => (
                                                      <tr key={index} style={{height: "30px"}}>
                                                         <td>
                                                            <img src={getNotificationTypeIcon(notification?.notificationType)}/>
                                                         </td>
                                                         <td>{NOTIFICATION_TYPES[notification?.notificationType]}</td>
                                                         <td>{capitalize1stLetter(notification?.message)}</td>
                                                         <td>{getTimeAgoString(getConvertedTime(notification?.notifiedTime))}</td>
                                                      </tr>))}

                                                   </tbody>
                                                </table>
                                             </div>
                                             {totalElements > 0 &&
                                                <div className={classnames(styles.PaginatorRow, "row mt-4")}>
                                                   <Paginator postsPerPage={postsPerPage} totalElements={totalElements}
                                                              currentPage={currentPage}
                                                              paginate={paginate}/>
                                                </div>}
                                          </>}
                                    </div>
                                 </div>
                              </>}
                           </div>
                        </div>
                     </div>
                  </div>

               </div>
            </div>
         </div>


         <Footer/>
      </div>
   </div>);
}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null && state.auth.accessToken !== undefined,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      Token: state.auth.accessToken,
   };
};

export default connect(mapStateToProps)(Notifications);
