import classNames from "classnames";
import styles from "../../containers/Leaderboard/LeaderBoard.module.scss";
import {Link} from "react-router-dom";
import {formatNumberToKWithoutRounding, getPlayerShowingName, getSprtsValueFromPoints, getUsdValueFromPoints, toolTipProvider} from "../../helpers/CommonFunctions";
import React, {useEffect, useState} from "react";
import {getBonusPoint, getPosition, getProfileImage, getUpDownSameIconByDirection, pointsPerGame} from "../../helpers/LeaderBoardHelpers";
import {useTranslation} from "react-i18next";
import {getConf} from "../../services/ConfigService";
import {WALLET} from "../../utilities/ConfigConstants";
import OmniPoints from "../../assets/images/icons/24_xp.webp";

const LeaderBoardRow = ({leader, userNameOfCurrentUser, usdValue, selectedRadio, getGreenOrPurpleCap, location, handleViewTeamBtn,xpToSprtsRatio}) => {
   const [showMain, setShowMain] = useState(false);
   const [playerName, setPlayerName] = useState("");
   const toggleMain = () => {
      setShowMain(!showMain);
   };
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   useEffect(() => {
      setPlayerName(getPlayerShowingName(leader))
   }, [leader])

   const getPoints = () => {
      setShowMain(!showMain);
   };

   const positionToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.POSITION"), "top")
   const namePlayersToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.NAME_PLAYERS"), "top")
   const totalPointsToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.TOTAL_POINTS"), "top")
   const gamesPlayedToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.GAMES_PLAYED"), "top")
   const fantasyPointsToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.FANTASY_POINTS"), "top")
   const usdValueToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.USD_VALUE"), "top")
   const pointsForMatchToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.POINTS_FOR_MATCH"), "top")
   // const questionAnsweredToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.QBOT_QUESTION_ANSWERED"), "top")
   // const questionCorrectToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.QBOT_QUESTION_CORRECT"), "top")
   const questionIncorrectToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.QBOT_QUESTION_INCORRECT"), "top")
   const ratioToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.RATIO"), "top")
   // const totalPredictionsToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.TOTAL_PREDICTIONS_TOOLTIP"), "top")
   // const correctPredictionsToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.CORRECT_PREDICTIONS_TOOLTIP"), "top")

   return (
      <tr key={leader.userName}
          className={leader?.userName === userNameOfCurrentUser ? classNames(styles.leaderBoardHigh) : undefined}
          style={{position: "relative"}}>
         <td className={classNames(styles.multirow)}>
            <div>
               {positionToolTip(
                  <label className={classNames(styles.CorrectPresentCell, "CorrectPresent")}>
                     <>
                        <i>{getPosition(leader?.position)}</i>
                     </>
                  </label>, true)}
            </div>
         </td>
         <td className={classNames(styles.multirow)}>
            <div>
               {toolTipProvider(t("LEADER_BOARD_ROW.YOU_CANN'T_VISIT_A_PRIVATE_USER'S_PROFILE"), 'right', <div>
                  <Link to={leader.isPrivate && (leader?.userName != userNameOfCurrentUser) ? '' : {
                     pathname: `/user/${btoa(leader.userName)}`,
                     search: `returnUrl=${encodeURIComponent(location.pathname)}`
                  }} style={{textDecoration: 'none', cursor: `${leader?.isPrivate && (leader?.userName != userNameOfCurrentUser) ? "default" : "pointer"}`}}>
                     {getProfileImage(leader?.profilePicUrl, playerName.split(' ')[0], playerName.split(' ')[1])}
                  </Link>
                     <label className="CorrectPresent">
                        <>
                           {playerName}
                           {(selectedRadio == 'tournament') &&
                              <label className={classNames(styles.CorrectPresentCell, "CorrectPresent")}>
                                 {getBonusPoint(leader?.position, leader?.points)}
                              </label>}
                        </>
                     </label>
               </div>, (leader.isPrivate && (leader?.userName != userNameOfCurrentUser)))}

            </div>
         </td>
         <td className={classNames(styles.multirow)}>
            <div className="row p-0">
               <div className="col-2 p-0 mr-2">
                  <img className="" src={OmniPoints}/>
               </div>
               <div className={classNames(styles.ViewTeamBtnCell, "float-right text-end col-5 p-0 greenText d-block")}>
                  {leader?.points > 999 ?
                     <div className={'flex-row gap-2'}>
                        {toolTipProvider(leader?.points, 'top',
                           <label
                              className="CorrectPresent">{formatNumberToKWithoutRounding(leader?.points)}</label>, true)}
                        {/* {leader?.userName === userNameOfCurrentUser && (selectedRadio == 'tournament') &&
                           <button className={classNames(styles.ViewTeamBtn)} onClick={handleViewTeamBtn}>
                              {t("LEADER_BOARD_ROW.TEAM")}
                           </button>} */}
                     </div> : <>
                        <label className="CorrectPresent">{formatNumberToKWithoutRounding(leader?.points)}</label>
                        {/* {leader?.userName === userNameOfCurrentUser && (selectedRadio == 'tournament') &&
                           <button className={classNames(styles.ViewTeamBtn)} onClick={handleViewTeamBtn}>{t("LEADER_BOARD_ROW.TEAM")}</button>} */}
                     </>}
               </div>
               <div className="col-2 p-0">
                  {getUpDownSameIconByDirection(leader?.direction)}
               </div>
            </div>
         </td>
         {selectedRadio === 'tournament' &&
            <td className={classNames(styles.multirow)}>
               {usdValueToolTip(<label className="CorrectPresent">
                  {getUsdValueFromPoints(leader?.points, xpToSprtsRatio, usdValue)}
               </label>, true)}
            </td>
         }
         <td className={classNames(styles.multirow)}>
            {usdValueToolTip(<label className="CorrectPresent">
               {getSprtsValueFromPoints(leader?.points, xpToSprtsRatio)}
            </label>, true)}
         </td>
         {selectedRadio === 'global' &&
            <td className={classNames(styles.multirow)}>
               <label className="CorrectPresent">
                  {leader?.userName === userNameOfCurrentUser ?
                     <div className={'flex-row gap-2'}>
                        {toolTipProvider(leader?.fantasyPoints, 'top', <label className="CorrectPresent">{formatNumberToKWithoutRounding(leader?.fantasyPoints) || 0}</label>, true)}
                     </div> :
                     <>
                        {toolTipProvider(leader?.fantasyPoints, 'top',
                           <label className="CorrectPresent">{formatNumberToKWithoutRounding(leader?.fantasyPoints) || 0}</label>, true)}
                     </>
                  }
               </label>
            </td>}
         <td className={classNames(styles.multirow)}>
            <label className="CorrectPresent"><>{leader?.matches || 0}</>
            </label>
         </td>
         <td className={classNames(styles.multirow)}>
            {toolTipProvider((pointsPerGame(leader?.matches, leader?.points)), 'top',
               <label className="CorrectPresent">
                  <>{formatNumberToKWithoutRounding(pointsPerGame(leader?.matches, leader?.points))}</>
               </label>, true)}
         </td>
         {selectedRadio === 'tournament' &&
            <td>
               <div className="Boostount">
                  <div className="BoostIndicatorIcon">
                     <svg version="1.1" id="Layer_1" x="0px" y="0px"
                          viewBox="0 0 50 50">
                        <path className="BoostIconPath" d="M46.5,1.3c0.2,0,0.3,0,0.5,0c0,0.1-0.1,0.1-0.1,0.2C39.9,7.8,33,14.2,26.1,20.6c0,0,0,0.1,0,0.1
                     c3,0.8,6,1.6,8.9,2.4c0,0,0,0.1,0,0.1C24.4,31.8,13.8,40.6,3.2,49.3c0.1-0.2,0.2-0.3,0.4-0.5C8.8,42,14,35.2,19.3,28.3
                     c0-0.1-0.1-0.1-0.1-0.2c-2.6-0.9-5.2-1.9-7.7-2.8c0.2-0.2,0.3-0.3,0.5-0.5c6-7.2,11.9-14.3,17.9-21.5C35.3,2.7,40.9,2,46.5,1.3z"/>
                     </svg>
                  </div>
                  <div className="BoostIndicatorIcon">
                     X <span> 3</span>
                  </div>
               </div>

            </td>
         }
         <td>
               {leader?.userName === userNameOfCurrentUser && (selectedRadio == 'tournament') &&
                           <button className={classNames(styles.ViewTeamBtn)} onClick={handleViewTeamBtn}>
                              {t("LEADER_BOARD_ROW.TEAM")}
                           </button>}

         </td>
         {/*<td className={classNames(styles.multirow)}>*/}
         {/*   {questionAnsweredToolTip(<label className="CorrectPresent">{getPosition(leader?.questionFaced) || 0}</label>, true)}*/}
         {/*</td>*/}
         {/*<td className={classNames(styles.multirow)}>*/}
         {/*   {questionCorrectToolTip(<label className="CorrectPresent"><>{getPosition(leader?.rightAnswers) || 0}</>*/}
         {/*   </label>, true)}*/}
         {/*</td>*/}
         {/*<td className={classNames(styles.multirow)}>*/}
         {/*   {totalPredictionsToolTip(<label className="CorrectPresent"><>{getPosition(leader?.predictionsFaced) || 0} </>*/}
         {/*   </label>, true)}*/}
         {/*</td>*/}
         {/*<td className={classNames(styles.multirow)}>*/}
         {/*   {correctPredictionsToolTip(<label className="CorrectPresent"><>{getPosition(leader?.predictionsCorrect) || 0} </>*/}
         {/*   </label>, true)}*/}
         {/*</td>*/}
         {/*<div className={classNames(styles.accordionBtnWrapper)}>*/}
         {/*   {showMain && (*/}
         {/*      <i onClick={toggleMain} className={classNames(styles.accordionBtnUp, "fa fa-caret-up")} aria-hidden="true"></i>*/}
         {/*   )}*/}
         {/*   {!showMain && (*/}
         {/*      <i onClick={toggleMain} className={classNames(styles.accordionBtnDown, "fa fa-caret-down")} aria-hidden="true"></i>*/}
         {/*   )}*/}
         {/*</div>*/}
      </tr>
   )
}
export default LeaderBoardRow;
