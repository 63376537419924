import React, {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import * as TournamentService from "../../services/TournamentService";
import {MATCH_CARD_TYPES} from "../../utilities/Constants";
import {checkUserFantasySubscription} from "../../services/FantasyService";
import {connect} from "react-redux";
import styles from "../HomeV2/HomeV2.module.scss";
import classNames from "classnames";

const Latest = (props) => {
   let navigate = useNavigate();
   const [lang] = useState(localStorage.getItem("language"));

const toastUpdate = (toastId, render, type) => {
   toast.update(toastId, {
      render: render,
      type: type,
      autoClose: 5000,
      closeButton: null,
      isLoading: false
   })
}

   const handleCheckUserFantasySubscription = useCallback(async (tournamentId) => {
      try {
         const response = await checkUserFantasySubscription(tournamentId, props.userName, props.token);
         if (response.apiStatus && response?.isSubscribed) {
            return response?.isSubscribed;
         } else if (response.apiStatus) {
            return false;
         }
         return null;
      } catch (error) {
         console.error("handleCheckUserFantasySubscription", error);
      }
   }, [props.token, props.userName]);

   const handleTournamentAvailable = useCallback(async (latestTournament, toastId) => {
      // Check if user is subscribed to fantasy. If not redirect to fantasy team creation page
      const isFantasySubscribed = await handleCheckUserFantasySubscription(latestTournament.id);
      if (isFantasySubscribed === false) {
         toastUpdate(toastId, 'Lets create a fantasy team before entering the tournament...', toast.TYPE.SUCCESS)
         setTimeout(() => {
            if (latestTournament?.latestMatchId) {
               navigate(`/fantasy-team/${latestTournament.id}`, {
                  state: {
                     tournamentDetails: latestTournament, returnPath: `/match/${latestTournament?.latestMatchId}`
                  }
               });
            } else {
               navigate(`/fantasy-team/${latestTournament.id}`, {
                  state: {
                     tournamentDetails: latestTournament,
                  }
               });
            }
         }, 1000);
      } else {
         if (latestTournament?.latestMatchId) {
            toastUpdate(toastId, 'Live match is available.Taking you to the match.', toast.TYPE.SUCCESS)
         } else {
            toastUpdate(toastId, 'No live match available.Taking you to the tournament.', toast.TYPE.SUCCESS)
         }
         setTimeout(() => {
            if (latestTournament?.latestMatchId) {
               navigate(`/match/${latestTournament?.latestMatchId}`);
            } else {
               navigate(`/${latestTournament.tournamentType?.toLowerCase()}/${latestTournament?.id}`);
            }
         }, 1000);
      }
   }, [handleCheckUserFantasySubscription, navigate])

   useEffect(() => {
      const navigateToLatestMatch = async () => {
         let latestTournament = {};
         const toastId = toast.loading(`Checking for the latest tournament..`);

         await TournamentService.getAllTournaments('', 0, 1, "active,startTime,desc", MATCH_CARD_TYPES.ALL, lang, props.token).then((response) => {
            if (response?.apiStatus && response.totalElements !== 0) {
               latestTournament = response.tournaments?.[0];
            } else {
               toastUpdate(toastId, `Error occurred in taking you to the latest match. Please try again later..`, toast.TYPE.ERROR)

               setTimeout(() => {
                  navigate(`/tournaments`);
               }, 1000);
            }
         })

         if (latestTournament) {
            sessionStorage.setItem('redirectPath', `/${latestTournament?.tournamentType?.toLowerCase()}/${latestTournament?.id}`);
         } else {
            sessionStorage.setItem('redirectPath', `/tournaments`);
         }

         if (!props.token) {
            toastUpdate(toastId, 'Please Login to participate in tournament.', toast.TYPE.INFO)

            setTimeout(() => {
               navigate(`/login`);
            }, 1000);

         } else {
            if (latestTournament) {
               if (latestTournament.tournamentTicketOmniCredits <= 0) {

                  if (latestTournament.active === true || latestTournament.toStart === true) {
                     await handleTournamentAvailable(latestTournament, toastId);
                  }
               } else {
                  try {
                     const response = await TournamentService.checkTournamentRegistration(latestTournament.id, props.userName);
                     if (response.apiStatus) {
                        if (!response.available) {
                           toastUpdate(toastId, 'You haven\'t subscribed to this tournament..Taking you back to Tournaments', toast.TYPE.ERROR)

                           setTimeout(() => {
                              navigate("/tournaments");
                           }, 1000);

                        } else {
                           await handleTournamentAvailable(latestTournament, toastId);
                        }
                     } else {
                        toastUpdate(toastId, 'Error occurred in taking you to the latest tournament.Please try again later..', toast.TYPE.ERROR)

                        setTimeout(() => {
                           navigate("/tournaments");
                        }, 1000);
                     }
                  } catch (error) {
                     console.log(error);
                     throw error;
                  }
               }
            } else {
               toastUpdate(toastId, 'No latest tournament available, Taking you to the Tournaments', toast.TYPE.ERROR)

               setTimeout(() => {
                  navigate("/tournaments");
               }, 1000);
            }
         }
      }

      navigateToLatestMatch();
   }, [handleTournamentAvailable, lang, navigate, props.token, props.userName]);

   return (<div className={classNames(styles.homeContainer)}>
      <div className="w-100 text-center">
         <div className={styles.loaderContainer}>
            <div className="lds-facebook">
               <div></div>
               <div></div>
               <div></div>
            </div>
         </div>
      </div>
   </div>);
};

const mapStateToProps = (state) => {
   return {
      token: state.auth.accessToken,
   };
};

export default connect(mapStateToProps)(Latest);
