import React, {useEffect, useState} from "react";
import Classnames from "classnames";
import styles from "./PlayerInfoModal.module.scss";
import {getPlayerProfileById} from "../../../services/DigitalAssetService";
import {playerRoleStyleController} from "../../../helpers/CommonFunctions";
import {getAllPlayersByTournamentId} from "../../../services/FantasyService";
import {t} from "i18next";

const PlayerInfoModal = ({playerId, tournamentId, allTournamentPlayers, handleModalClose}) => {
   const pageLang = localStorage.getItem('language');

   const [isLoading, setIsLoading] = useState(true);
   const [playerInfo, setPlayerInfo] = useState(null);
   const [allPlayersObj, setAllPlayersObj] = useState({});

   const roundOff = (value) => {
      return Math.round(value * 100) / 100;
   }

   useEffect(() => {
      const fetchPlayerInfo = async () => {
         getPlayerProfileById(playerId, pageLang)
            .then((response) => {
               if (response?.apiStatus === true) {
                  setPlayerInfo(response?.playerProfileDTO);
                  setIsLoading(false);
               }
            })
            .catch((error) => {
               console.log(error);
               setIsLoading(false);
            });
      }

      fetchPlayerInfo();
   }, [pageLang, playerId]);

   useEffect(() => {
      const fetchAllPlayers = async () => {
         let allPlayers = [];

         if (allTournamentPlayers && allTournamentPlayers.length > 0) {
            allPlayers = allTournamentPlayers;
         } else if (tournamentId) {
            const response = await getAllPlayersByTournamentId(tournamentId)

            if (response.apiStatus && response.players) {
               allPlayers = response.players;
            }
         }

         const playersObj = allPlayers.reduce((acc, player) => {
            acc[player.playerId] = player;
            return acc;
         }, {});
         setAllPlayersObj(playersObj);
      }

      fetchAllPlayers();
   }, [allTournamentPlayers, tournamentId]);

   const loadingIndicator = (<div className={Classnames(styles.LoaderWrapper)}>
      <div className="lds-facebook">
         <div></div>
         <div></div>
         <div></div>
      </div>
   </div>);

   return (<div
      className={Classnames("modal fade show", styles.modalBlur, styles.PaymentConfirmmodal, styles.HowDoesBotWorkMmodal, styles.PlayerInforModalMainWrapper)}
      id="exampleModalCenter"
      tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"
      style={{display: "block"}}>
      <div className={Classnames(styles.CreditPurchaseModal, "modal-dialog modal-dialog-centered")} role="document">
         {/* <div className={Classnames("modal-content", styles.modalContentContainer)}
              style={{backgroundImage: `url(${playerInfo?.actionCutImgUrl})`}}> */}
         <div className={Classnames("modal-content", styles.modalContentContainer)}>
            <div className={Classnames(styles.PlayerActionImage)} style={{backgroundImage: `url(${playerInfo?.actionPicUrl}`}}></div>
            <button className={Classnames(styles.closeButton, "close z-3")} type="button" onClick={handleModalClose}
                    data-dismiss="modal" aria-label="Close">
                   <span className={styles.closeButton} aria-hidden="true">
                      <img alt='Close Icon' src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/>
                   </span>
            </button>
            {/* <img src={dd}/> */}
            <div className={Classnames(styles.TeamBackgroundLogo)} style={{backgroundImage: `url(${allPlayersObj[playerId]?.teamPictureURL})`}}></div>
            <div className={Classnames(styles.UserProfileModalWrapper)}>
               <div className={Classnames(styles.ellipse, styles.eMulticolorFull, styles.eMulticolorFull1)}></div>
               <div className={Classnames(styles.ellipse, styles.e392)}></div>
               <div className={Classnames(styles.ellipse, styles.eMulticolorFull, styles.eMulticolorFull2)}></div>
               {isLoading ? loadingIndicator : <div className="row p-0">
                  <div className={Classnames(styles.UserProfileModal, "col-md-12")}>
                     <div className={Classnames(styles.TitleWrapper)}>
                        <h3>{playerInfo.playerName},<span>{playerInfo.age} Yrs</span></h3>
                     </div>
                     <div className={Classnames(styles.InfoBody)}>
                        <div className={Classnames(styles.PlayerSummary1)}>
                           <div className={Classnames(styles.InforRow, "row")}>
                              <div className="col">
                                 <div className={Classnames(styles.Title)}>{t("FANTASY.TEAM")}:</div>
                                 <span>{allPlayersObj[playerId]?.teamName}</span>
                              </div>
                              <div className="col">
                                 <div>{t("FANTASY.MATCHES")}:</div>
                                 <span>{playerInfo.playerStat?.matches}</span>
                              </div>
                           </div>

                           <div className={Classnames(styles.InforRow, "row")}>
                              <div className="col">
                                 <div>{t("FANTASY.STRIKE_RATE")}:</div>
                                 <span>{roundOff(playerInfo.playerStat?.strikeRate)}</span>
                              </div>
                              <div className="col">
                                 <div>{t("FANTASY.TOTAL_RUNS")}:</div>
                                 <span>{playerInfo.playerStat?.runs}</span>
                              </div>
                           </div>

                           <div className={Classnames(styles.InforRow, "row")}>
                              <div className="col">
                                 <div>{t("FANTASY.WIN_PERCENTAGE")}:</div>
                                 <span>{roundOff(playerInfo.playerStat?.winPercentage)}%</span>
                              </div>
                              <div className="col">
                                 <div>{t("FANTASY.TOTAL_WICKETS")}:</div>
                                 <span>{playerInfo.playerStat?.wickets}</span>
                              </div>
                           </div>

                           <div className={Classnames(styles.InforRow, "row")}>
                              <div className="col">
                                 <div>{t("FANTASY.TOTAL_SIXES")}:</div>
                                 <span>{playerInfo.playerStat?.sixes}</span>
                              </div>
                              <div className="col">
                                 <div>{t("FANTASY.TOTAL_FOURS")}:</div>
                                 <span>{playerInfo.playerStat?.fours}</span>
                              </div>
                           </div>

                        </div>

                        <div className={Classnames(styles.PlayerSummary2)}>
                           <div className={Classnames(styles.InforRow, "row")}>
                              <div className="col-md-12">
                                 <div className={Classnames(styles.PlayerSummary2ColWrapper)}><span>{t("FANTASY.SHARPNESS")}</span> <span>{playerInfo.playerAbilities?.intellect}/10</span></div>
                                 <div className={Classnames(styles.PlayerSummary2ColWrapper)}><span>{t("FANTASY.AGILITY")}</span> <span>{playerInfo.playerAbilities?.dexterity}/10</span></div>
                                 <div className={Classnames(styles.PlayerSummary2ColWrapper)}><span>{t("FANTASY.STRENGTH")}</span> <span>{playerInfo.playerAbilities?.strength}/10</span></div>
                              </div>
                              <div className={Classnames(styles.LastRow)}>

                                 <div>
                                    <div>{t("FANTASY.PLAYING_ROLE")}</div>
                                    <span>{playerRoleStyleController(playerInfo.playingRole, pageLang)}</span>
                                 </div>
                                 <div>
                                    <div>{t("FANTASY.BATTING_STYLE")}</div>
                                    <span>{playerRoleStyleController(playerInfo.battingStyle, pageLang)}</span>
                                 </div>

                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>}

               {/* <div className={Classnames(styles.ModalButtonRowWrapper,"row p-0")}>
                  <div className={Classnames(styles.ModalButtonRow, "row p-0")}>
                     <button className="lightGreenButton" onClick={handleModalClose}>
                        <span>{t("FANTASY.CLOSE")}</span>
                     </button>
                  </div>
               </div> */}

            </div>
            <div className={Classnames(styles.GradientRow)}>
               <div className={Classnames(styles.FooterGradient)}>
                  <div className={Classnames(styles.ModalButtonRowWrapper, "row p-0")}>
                     <div className={Classnames(styles.ModalButtonRow, "row p-0")}>
                        <button className="lightGreenButton" onClick={handleModalClose}>
                           <span>{t("FANTASY.CLOSE")}</span>
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>)
}

export default PlayerInfoModal;
