import React, {useEffect} from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import "./App.scss";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import LoginV2 from "./containers/Login/Login";
import {connect} from "react-redux";
import * as actions from "./store/actions";
import "./assets/css/global.css";
import "./assets/css/elements.css";
import "./assets/css/responsive.scss";
import CreateNewPassword from "./containers/CreateNewPassword/CreateNewPassword";
import PasswordRestoreSuccess from "./containers/PasswordRestoreSuccess/PasswordRestoreSuccess";
import Register from "./containers/Register/Register";
import RegistrationSuccess from "./containers/RegistrationSuccess/RegistrationSuccess";
import PasswordRestoreExpired from "./containers/PasswordRestoreSuccess/PasswordRestoreExpired";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateAccountV2 from "./containers/CreateAccount/CreateAccount";
import ScheduledMatch from "./containers/ScheduledMatch/ScheduledMatch";
import Match from "./containers/Match/Match";
import PlayerProfile from "./containers/PlayerProfile/PlayerProfile";
import TeamProfile from "./containers/TeamProfile/TeamProfile";
import {v4 as uuidv4} from 'uuid';
import MatchUpsV2 from "./containers/MatchUps/MatchUps";
import MatchesV2 from "./containers/Matches/Matches";
import PlayersV2 from "./containers/Players/Players";
import PrivacyPolicyPage from "./containers/PrivacyPolicy/PrivacyPolicyPage";
import TermsAndConditions from "./containers/TermsAndConditions/TermsAndConditions";
import LeaderBoard from "./containers/Leaderboard/LeaderBoard";
import {pubnubLiveTokenValidator, pubnubMatchesTokenValidator} from "./helpers/CommonFunctions";
import UserProfileV2 from "./containers/UserProfile/UserProfile";
import StreamChat from "./components/StreamChat/StreamChat";
import Error404 from "./containers/Error404/Error404"
import OngoingTournaments from "./containers/Tournament/OngoingTournaments";
import LeagueTournament from "./containers/Tournament/LeagueTournamentPage/LeagueTournament";
import KnockoutTournament from "./containers/Tournament/KnockoutTournamentPage/KnockoutTournament";
import TwitchStream from "./components/TwitchStream/TwitchStream";
import PaymentWallet from "./components/PaymentWallet/PaymentWallet";
import LeagueKnockoutTournament from "./containers/Tournament/LeagueKnockoutTournament/LeagueKnockoutTournament";
import HomeV2 from "./containers/HomeV2/HomeV2";
import FantasyTeam from "./containers/CreateFantasyTeam/CreateFantasyTeam";
import 'react-chatbot-kit/build/main.css'
import ChatBot from "./components/ChatBot/ChatBot";
import FantasyTeams from "./containers/FantasyTeams/FantasyTeams";
import Transactions from "./containers/Transactions/Transactions";
import Latest from "./containers/Latest/Latest";
import Notifications from "./containers/Notifications/Notifications";

import LeaderBoardLineChart from "./components/LeaderBoardLIneChart/LeaderBoardLIneChart"
const App = (props) => {

   useEffect(() => {
      if (localStorage.getItem("uuid") == null) {
         localStorage.setItem("uuid", uuidv4())
      }
      pubnubLiveTokenValidator(props.userName)
      pubnubMatchesTokenValidator();
   }, []);


   return (<div>

      <ToastContainer
         position="top-right"
         autoClose={5000}
         hideProgressBar={false}
         newestOnTop={false}
         closeOnClick
         rtl={false}
         pauseOnFocusLoss={false}
         draggable={false}
         pauseOnHover={false}
         theme="dark"
         limit={3}/>
      {/*<TagHelmet/>*/}
      <BrowserRouter>
         <Routes>
            <Route path="/home" exact element={<Navigate to="/"/>}/>
            <Route path="/" exact element={<HomeV2/>}/>
            <Route path="/login" exact element={<LoginV2/>}/>
            <Route path="/create-account" exact element={<CreateAccountV2/>}/>
            <Route path="/create-account-success" exact element={<RegistrationSuccess/>}/>
            <Route path="/register" exact element={<Register/>}/>
            <Route path="/reset" exact element={<CreateNewPassword/>}/>
            <Route path="/restore-success" exact element={<PasswordRestoreSuccess/>}/>
            <Route path="/restore-expired" exact element={<PasswordRestoreExpired/>}/>
            <Route path="/user/:userName" exact element={<UserProfileV2/>}/>
            <Route path="/privacy-policy" exact element={<PrivacyPolicyPage/>}/>
            <Route path="/terms-and-conditions" exact element={<TermsAndConditions/>}/>
            <Route path="/teams" exact element={<MatchUpsV2/>}/>
            <Route path="/scheduled-match/:matchId" exact element={<ScheduledMatch/>}/>
            <Route path="/matches" exact element={<MatchesV2/>}/>
            <Route path="/match/:matchId" exact element={<Match/>}/>
            <Route path="/player/:playerId" exact element={<PlayerProfile/>}/>
            <Route path="/players" exact element={<PlayersV2/>}/>
            <Route path="/leaderboard" exact element={<LeaderBoard/>}/>
            <Route path="/team/:teamId" exact element={<TeamProfile/>}/>
            <Route path="/not-found" exact element={<Error404/>}/>
            <Route path="*" element={<Navigate to="/not-found"/>}/>

            <Route path="/tournaments" exact element={<OngoingTournaments/>}/>
            <Route path="/league/:tournamentId" exact element={<LeagueTournament/>}/>
            <Route path="/knockout/:tournamentId" exact element={<KnockoutTournament/>}/>
            <Route path="/league-knockout/:tournamentId" exact element={<LeagueKnockoutTournament/>}/>
            <Route path="/stream" exact element={<TwitchStream/>}/>

            <Route path="/fantasy-teams" exact element={<FantasyTeams/>}/>
            <Route path="/fantasy-team/:tournamentId" exact element={<FantasyTeam/>}/>

            <Route path="/wallet" exact element={<PaymentWallet/>}/>
            <Route path="/chat" exact element={<ChatBot/>}/>
            <Route path="/transactions" exact element={<Transactions/>}/>
            <Route path="/latest" exact element={<Latest/>}/>
            <Route path="/notifications" exact element={<Notifications/>}/>
            <Route path="/line" exact element={<LeaderBoardLineChart/>}/>
         </Routes>
      </BrowserRouter>
      {props.Token == undefined || props.Token == null || !props.isAuthenticated || props.userName == null || props.userName == undefined ? "" : <StreamChat/>}

   </div>);
};

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null, refresh: state.auth.refreshToken, Token: state.auth.accessToken, profileImage: state.auth.imageURL, userName: state.auth.userName, name: state.auth.name
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      onLogout: () => dispatch(actions.logout()), onRefreshAuth: (accessToken, refreshToken) => dispatch(actions.refreshSession(accessToken, refreshToken)),
   };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
