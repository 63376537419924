import React, {useEffect, useRef, useState} from "react";
import styles from "./HomeV2.module.scss";
import NavbarSecondary from "../../components/NavigationBar/NavigationBar";
import classNames from "classnames";
import leftStar from "../../assets/images/Pngtreeline802581.webp";
import plusSign from "../../assets/images/vectorplus.webp";
import minusSign from "../../assets/images/vectorMinus.webp";
import Footer from "../../components/Footer/Footer";
import {connect} from "react-redux";
import {Link, useLocation, useNavigate} from "react-router-dom";
import * as EmailSubscriptionService from "../../services/EmailSubscriptionService"
import {toast} from "react-toastify";
import * as actions from "../../store/actions";
import {handleDeviceScreenSize} from "../../helpers/CommonFunctions";
import {getConf} from "../../services/ConfigService";
import {METALUX_TIMER} from "../../utilities/ConfigConstants";
import {useTranslation} from "react-i18next";
import * as TournamentService from "../../services/TournamentService";
import MetaluxTimer from "../../components/MetaluxTimer/MetaluxTimer";
import GuidanceTip from "../../components/GuidanceTip/GuidanceTip";
import SequenceLoginModal from "../../components/Wallet/SequenceLoginModal/SequenceLoginModal";
import JoinNow from "../../assets/images/join_now.webp";
import Step2 from "../../assets/images/step2.webp";
import Step3 from "../../assets/images/step3.webp";
import Step4 from "../../assets/images/steps4.webp";
import LockIcon from "../../assets/images/lock.webp";
import Sprts from "../../assets/images/SPRTS_img.webp";
import {MATCH_CARD_TYPES, SITE_LANGUAGE, TOURNAMENT_TYPES} from "../../utilities/Constants";
import * as BlogsService from "../../services/BlogsService";
import VSIcon from "../../assets/images/vs.webp";
import CreditIcon from "../../assets/images/icons/24_sprts_token.webp";
import RM1 from "../../assets/images/RM1.webp";
import RM2 from "../../assets/images/RM2.webp";
import RM3 from "../../assets/images/RM3.webp";
import RM4 from "../../assets/images/RM4.webp";
import RM5 from "../../assets/images/RM5.webp";
import Styles from "../Tournament/OngoingTournaments.module.scss";
import {AUTHENTICATION_TOASTS} from "../../utilities/ToastMessages";
import TournamentTimer from "../Tournament/TournamentTimer";
import PaymentConfirmModal from "../../components/Wallet/Purchase/PaymentConfirmModal/PaymentConfirmModal";
import * as PubNubService from "../../services/PubNubService";
import FeaturedTournamentsHandler from "../../services/PubNubHandlers/FeaturedTournamentsHandler";
import {checkUserFantasySubscription} from "../../services/FantasyService";
import HomePageGuidancePopUp from "../../components/Modals/HomePageGuidancePopUp/HomePageGuidancePopUp";
import FantasyPointsTable from "../../components/FantasyPointsTable/FantasyPointsTable";

function HomeV2(props) {
   const location = useLocation();
   const targetDivRef = useRef(null);
   const [plus, setPlus] = useState(true);
   const [plusOne, setPlusOne] = useState(true);
   const [plusTwo, setPlusTwo] = useState(true);
   const [plusThree, setPlusThree] = useState(true);
   const [plusFour, setPlusFour] = useState(true);
   const [plusFive, setPlusFive] = useState(true);
   const [plusSix, setPlusSix] = useState(true);
   const [plusSeven, setPlusSeven] = useState(true);
   const [metaTimer, setMetaTimer] = useState("");
   const [email, setEmail] = useState("");
   const [tournamnetEndTimer, setTournamnetEndTimer] = useState("");
   const [latestTournament, setLatestTournament] = useState(null);
   const {t, i18n} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);
   const [lang, setLang] = useState(localStorage.getItem("language"));
   const [translationKey, setTranslationKey] = useState(0);
   const [screenSize, setScreenSize] = useState(window.innerWidth);
   const [isSequenceLoginModalShow, setIsSequenceLoginModalShow] = useState(false);
   const [liveTournaments, setLiveTournaments] = useState([]);
   const [scheduledTournaments, setScheduledTournaments] = useState([]);
   const [totalElements, setTotalElements] = useState(-1);
   const [totalTestimonialCount, setTotalTestimonialCount] = useState(0);
   const [testimonialBlog, setTestimonialBlog] = useState([]);
   const [isBlogsLoading, setIsBlogsLoading] = useState(false);
   const [testimonialPageCount, setTestimonialPageCount] = useState(0);
   const [isRegistrationLoading, setIsRegistrationLoading] = useState(false);
   const [tournamentDetails, setTournamentDetails] = useState(null);
   const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [isFeaturedTournamentsLoading, setIsFeaturedTournamentsLoading] = useState(false);
   let uuid = localStorage.getItem("uuid");
   const [connectPubnub, setConnectPubnub] = useState(false);
   const [pubNubToken, setPubNubToken] = useState(null);
   const [subscribeChannel, setSubscribeChannel] = useState(null);
   const [unSubscribeChannel, setUnsubscribeChannel] = useState(null);
   let navigate = useNavigate();
   const faqRef = useRef(null);
   const [showHomePageGuidancePopUp, setShowHomePageGuidancePopUp] = useState(false);
   const [instructionStep, setInstructionStep] = useState("step1");

   const [state, setState] = useState({
      plus: true,
      plusOne: true,
      plusTwo: true,
      plusThree: true,
      plusFour: true,
      plusFive: true,
      plusSix: true,
      plusSeven: true,
      plusEight: true,
      plusNine: true,
      plusTen: true,
      plusEleven: true,
      plusTwelve: true,
      plusThirteen: true,
      plusFourteen: true,
      email: "",
      disable: true,
      showSignupModal: true,
      previouslyLogged: localStorage.getItem("previouslyLogged"),
      timeLeft: {minutes: 0, hours: 0, days: 0, seconds: 0},
   });

   const handleModalClose = () => {
      setState((prevState) => ({
         ...prevState,
         showSignupModal: false,
      }));
   };

   const handleSequenceLoginModalClose = () => {
      setIsSequenceLoginModalShow(false)
   }

   const handleHomePageGuidancePopUpClose = () => {
      setShowHomePageGuidancePopUp(false);
   }

   const handleHomePageGuidancePopUpOpen = (data) => {
      setInstructionStep(data);
      setShowHomePageGuidancePopUp(true);
   }

   useEffect(() => {
      const handleResize = () => {
         setScreenSize(handleDeviceScreenSize(window.innerWidth));
      }

      window.addEventListener('resize', handleResize);

      return () => {
         window.removeEventListener('resize', handleResize)
      }
   }, [screenSize]);

   // useEffect(() => {
   //   const fetchTournamentDetails = async () => {
   //     return await TournamentService.getLatestTournament().then((response) => {
   //       if (response.apiStatus) {
   //         if (getConf(METALUX_TIMER.SHOW_METALUX_TIMER)) {
   //           return response.tournamentDTOList[response?.tournamentDTOList?.length - 1];
   //         }
   //       } else {
   //         return {
   //           "id": 1,
   //           "name": "string",
   //           "active": true,
   //           "startTime": "2023-01-01T00:00:00.277193Z",
   //           "endTime": "2023-01-01T00:00:00.828Z"
   //         };
   //       }
   //     });
   //   };
   //
   //   const fetchData = async () => {
   //     const tournamentDetails = await fetchTournamentDetails();
   //     setMetaTimer(tournamentDetails.startTime);
   //     setTournamnetEndTimer(tournamentDetails.endTime);
   //   };
   //
   //   fetchData();
   // }, []);

   useEffect(() => {
      setIsFeaturedTournamentsLoading(true);
      const fetchOngoingTournaments = async () => {
         setTranslationsLoaded(false);

         await TournamentService.getAllTournaments('', 0, 2, "active,startTime,desc", MATCH_CARD_TYPES.ALL, lang, props.Token).then((response) => {
            if (response?.apiStatus && response.totalElements != 0) {
               setLatestTournament(response.tournaments?.[0]);
               setTotalElements(response.totalElements);
               filterTournaments(response);
               setIsFeaturedTournamentsLoading(false); //make this true
               // window.scrollTo({top: 0, behavior: 'smooth'});//TODO
               setTranslationsLoaded(true);
            } else if (response.totalElements == 0) {
               setTotalElements(0);
               setTranslationsLoaded(true);
               setIsFeaturedTournamentsLoading(false);
            }
         })
      }

      fetchOngoingTournaments();
   }, [lang, props.Token]);

   useEffect(() => {
      setIsBlogsLoading(true);
      const getAllTestimonials = async () => {
         await BlogsService.getAllTestimonials(testimonialPageCount, 'last_modified_date_time,desc', 1, lang).then((response) => {
            if (response?.data?.apiStatus && response?.data?.totalElements != 0) {
               setTotalTestimonialCount(response?.data?.totalElements);
               setTestimonialBlog(response?.data?.testimonials[0]);
               setIsBlogsLoading(false);
            } else if (response.totalElements == 0) {
               setTotalElements(0);
               setIsBlogsLoading(false);
            }
         })
         setIsBlogsLoading(false);
      }
      getAllTestimonials();
   }, [testimonialPageCount]);

   useEffect(() => {

      const fetchData = async () => {
         try {
            const response = await PubNubService.requestAccessTokenMatches(uuid, props.Token).then(response => {
               if (response != null) {
                  setPubNubToken(response);
                  setConnectPubnub(true);
               }
            });

         } catch (error) {
            console.error("Error:", error);
         }

      };
      fetchData();
   }, [uuid]);

   const messageFromFeaturedTournamentsHandlerCallback = (message) => {
      try {
         let json = JSON.parse(message);
         filterTournaments(json.body);

      } catch (error) {
         console.error("JSON parsing error:", error);
      }
   }


   const filterTournaments = (response) => {
      if (response.tournaments) {
         setScheduledTournaments(response?.tournaments?.filter((tournament) => tournament.active === false && tournament.toStart === true));
         setLiveTournaments(response?.tournaments?.filter((tournament) => tournament.active === true && tournament.toStart === false));
      }
   };

   useEffect(() => {
      const fetchTournamentDetails = async () => {

         let tournementId = getConf(METALUX_TIMER.METALUX_TIMER_TOURNAMENTID);
         return await TournamentService.getTournamentById(tournementId).then((response) => {
            if (response.apiStatus) {
               if (getConf(METALUX_TIMER.SHOW_METALUX_TIMER)) {
                  return response.tournament;
               }
            } else {
               return {
                  "id": 1,
                  "name": "string",
                  "active": true,
                  "startTime": "2023-01-01T00:00:00.277193Z",
                  "endTime": "2023-01-01T00:00:00.828Z"
               };
            }
         });
      };

      const fetchData = async () => {
         const tournamentDetails = await fetchTournamentDetails();
         setMetaTimer(tournamentDetails.startTime);
         setTournamnetEndTimer(tournamentDetails.endTime);
      };

      fetchData();
   }, []);

   function scrollToDiv() {
      if (targetDivRef.current) {
         // targetDivRef.current.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
         window.scrollTo({
            top: targetDivRef.current.offsetTop,
            behavior: 'smooth'
         });
      }
   };

   function handleEmailChange(event) {
      setEmail(event.target.value);
   };

   const subscribeEmail = () => {
      if (email !== "") {
         EmailSubscriptionService.SubscribeUserToNewsLetter(email).then((response) => {
            if (response.status === 200) {
               toast.success(t("TOAST.YOU_HAVE_SUCCESSFULLY_SUBSCRIBED_TO_THE_META_1_NEWSLETTER"));
            }
            setEmail("");
         });
      }
   };

   useEffect(() => {
      if (!lang) {
         setLang('en');
      }
      const translations = i18n.store.data[lang]; // Use optional chaining
      const keysLength = translations ? Object.keys(translations).length : 0;
      setTranslationKey(keysLength);
   }, [lang]);

   useEffect(() => {
      setTimeout(() => {
         if (translationKey > 0) {
            setTranslationsLoaded(true);
         }
      }, 1000);

   }, [translationKey]);

   // scroll to a section according to the url
   useEffect(() => {
      if (location.hash === "#faq" && faqRef.current) {
         const timer = setTimeout(() => {
            // faqRef.current.scrollIntoView({behavior: 'instant', block: 'nearest', inline: 'start'});
            window.scrollTo({
               top: faqRef.current.offsetTop,
               behavior: 'instant'
            });
         }, 1000);

         return () => {
            clearTimeout(timer);
         }
      }
   }, [location.hash, translationsLoaded]);

   const loadingIndicator = (
      <div className="w-100 text-center">
         <div className={styles.loaderContainer}>
            <div className="lds-facebook">
               <div></div>
               <div></div>
               <div></div>
            </div>
         </div>
      </div>);

   /*guidance tip*/
   const [nextGotItIndex, setNextGotItIndex] = useState(0)
   const [tipIndexCounter, setTipIndexCounter] = useState(1)

   const gotItHandler = () => {
      setNextGotItIndex(nextGotItIndex + 1)
      setTipIndexCounter(tipIndexCounter + 1)
   }

   const tipCancelHandler = () => {
      setNextGotItIndex(-1)
   }


   const handlePreviousTestimonial = () => {
      setTestimonialPageCount((prevState) => prevState - 1);
   }

   const handleNextTestimonial = () => {
      setTestimonialPageCount((prevState) => prevState + 1);
   }

   const getTournamentType = (tournamentType) => {
      if (tournamentType === TOURNAMENT_TYPES.LEAGUE) {
         return t("ONGOING_TOURNAMENTS.LEAGUE");
      } else if (tournamentType === TOURNAMENT_TYPES.KNOCKOUT) {
         return t("ONGOING_TOURNAMENTS.KNOCKOUT");
      } else if (tournamentType === TOURNAMENT_TYPES.LEAGUE_KNOCKOUT) {
         return t("ONGOING_TOURNAMENTS.LEAGUE_KNOCKOUT");
      }
   }

   const scheduledTournamentCard = (tournamentObj) => (
      <div
         className={classNames(styles.Tournament, styles.ScheduleTournament, tournamentObj.tournamentTicketOmniCredits === 0 ? styles.FreeTournament : '')}
         style={{backgroundImage: tournamentObj.logoUrl ? `url(${tournamentObj.logoUrl})` : "url('https://storage.googleapis.com/meta11/serviceplatform/Daily_Matches_back.png')"}}>
         <div className={classNames(styles.Row1)}>
            <div className={classNames(styles.Col1)}>
               <h4>{getTournamentType(tournamentObj?.tournamentType)}</h4>
               <h3>{tournamentObj.name}</h3>
            </div>
            <div className={classNames(styles.Col2)}>
               {/* -------countdown--------- */}

               <div className={classNames(styles.nextTournamentWrapper)}>

                  <div className={classNames(styles.countdown)}>
                     <TournamentTimer metaTimer={tournamentObj?.startTime} tournamnetEndTimer={tournamentObj?.endTime}/>
                     {/*<div>*/}
                     {/*   <div className={classNames(styles.cercalBack)}>*/}
                     {/*      <div className={classNames(styles.cercal)}>*/}
                     {/*         <span>02</span>*/}
                     {/*      </div>*/}
                     {/*   </div>*/}
                     {/*   <span>DAYS</span>*/}
                     {/*</div>*/}
                     {/*<div>*/}
                     {/*   <div className={classNames(styles.cercalBack)}>*/}
                     {/*      <div className={classNames(styles.cercal)}>*/}
                     {/*         <span>08</span>*/}
                     {/*      </div>*/}
                     {/*   </div>*/}
                     {/*   <span>HOURS</span>*/}
                     {/*</div>*/}
                     {/*<div>*/}
                     {/*   <div className={classNames(styles.cercalBack)}>*/}
                     {/*      <div className={classNames(styles.cercal)}>*/}
                     {/*         <span>10</span>*/}
                     {/*      </div>*/}
                     {/*   </div>*/}
                     {/*   <span>MINUTES</span>*/}
                     {/*</div>*/}
                  </div>
               </div>
               {/* -------countdown--------- */}
            </div>
         </div>

         <div className={classNames(styles.FooterRow)}>
            <div className={classNames(styles.BtnRow)}>
               {tournamentObj.tournamentTicketOmniCredits ?
                  <div className={classNames(styles.creditsWrapper)}>
                     <div className={classNames(styles.credits)}>
                        <label>{t("HOME_PAGE.FEE")}</label><b> : </b>
                        <img src={CreditIcon}/>
                        <span>{tournamentObj.tournamentTicketOmniCredits ?? '0'}</span>
                     </div>
                  </div> :
                  <button style={{pointerEvents: 'none'}}
                          className={classNames(styles.grayButton, "grayButton")}>{t("HOME_PAGE.FREE")}</button>
               }

               {tournamentButtonController(tournamentObj)}
               {/*<button className={classNames(styles.lightGreenButton, "lightGreenButton")}>*/}
               {/*   <img src={LockIcon} className={classNames(styles.LockIcon)}/>*/}
               {/*   <span>Enter here</span>*/}
               {/*</button>*/}

            </div>
         </div>
      </div>
   )

   const liveTournamentCard = (tournamentObj) => (
      <div className={classNames(styles.Tournament, styles.LiveTournament)}
           style={{backgroundImage: tournamentObj.logoUrl ? `url(${tournamentObj.logoUrl})` : "url('https://storage.googleapis.com/meta11/serviceplatform/Daily_Matches_back.png')"}}>
         <div className={classNames(styles.ssss)}>
            <div className={classNames(styles.Row1)}>
               <div className={classNames(styles.Col1)}>
                  <h4>{getTournamentType(tournamentObj?.tournamentType)}</h4>
                  <h3>{tournamentObj.name}</h3>
               </div>
               <div className={classNames(styles.Col2)}>
                  <label className={classNames(styles.liveTagRow)}>
                     {/* <span></span>{t("HOME_PAGE.LIVE")} */}
                     <div className={classNames(styles.PlayNowLiveTagContainer)}>
                        <div className={classNames(styles.PlayNowLiveTagWrapper)}>
                        </div>
                        <span className={classNames(styles.PlayNowLiveTag, "matchStatslbl-")}>
                              <span className={classNames(styles.BlinkingDot, "livedot blinking")}></span>
                              <div className={classNames(styles.LiveTag)}>{t("HOME_PAGE.PLAY_NOW")}</div>
                           </span>
                     </div>
                  </label>
               </div>
            </div>

            <div className={classNames(styles.Row2)}>
               <div className={classNames(styles.Col1)}>
                  {tournamentObj?.match && tournamentObj?.match[0]?.name ?
                     <div className={classNames(styles.TwoPlayersWrapper)}>
                        <div className={classNames(styles.TwoPlayers)}>
                           <div className={classNames(styles.PlayerWrapper)}>
                              <div className={classNames(styles.Player)}>
                                 <div className={classNames(styles.PlayerThumbWrapper)}>
                                    <img src={tournamentObj?.match[0]?.teamPictureURL}/>
                                 </div>
                                 <span>{tournamentObj?.match[0]?.name}</span>
                              </div>
                           </div>
                           <label><img src={VSIcon}/></label>
                           <div className={classNames(styles.PlayerWrapper)}>
                              <div className={classNames(styles.Player)}>
                                 <div className={classNames(styles.PlayerThumbWrapper)}>
                                    <img src={tournamentObj?.match[1]?.teamPictureURL}/>
                                 </div>
                                 <span>{tournamentObj?.match[1]?.name}</span>
                              </div>
                           </div>
                        </div>
                     </div> : <></>}
               </div>
               <div className={classNames(styles.Col2)}>
                  <div className={classNames(styles.BtnRow)}>
                     {/* <Link to={"/tournaments"} className={classNames(styles.grayButton, "grayButton")}>Free</Link> */}
                     {tournamentObj.tournamentTicketOmniCredits ?
                        <div className={classNames(styles.creditsWrapper)}>
                           <div className={classNames(styles.credits)}>
                              <label>{t("HOME_PAGE.FEE")}</label><b> : </b>
                              <img src={CreditIcon}/>
                              <span>{tournamentObj.tournamentTicketOmniCredits ?? '0'}</span>
                           </div>
                        </div> :
                        // <Link to={"/tournaments"} style={{pointerEvents: 'none'}} className={classNames(styles.grayButton, "grayButton")}>Free</Link>
                        <button style={{pointerEvents: 'none'}}
                                className={classNames(styles.grayButton, "grayButton")}>{t("HOME_PAGE.FREE")}</button>
                     }
                     {tournamentButtonController(tournamentObj)}
                     {/*<Link to={"/tournaments"} className={classNames(styles.lightGreenButton, "lightGreenButton")}>*/}
                     {/*   <span>Enter here</span>*/}
                     {/*</Link>*/}
                  </div>
               </div>
            </div>
         </div>
      </div>
   )

   const tournamentButtonController = (tournamentObj) => {
      if (props.isAuthenticated === true) {
         if (tournamentObj.tournamentTicketOmniCredits) {
            return (
               <button className={classNames(styles.lightGreenButton, "lightGreenButton")}
                       onClick={() => showPurchaseModal(tournamentObj)}>
                  <img src={LockIcon} className={classNames(styles.LockIcon)}/>
                  <span>{t("HOME_PAGE.ENTER_HERE")}</span>
               </button>
            )
         } else if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.KNOCKOUT) {
            return (
               <button className={classNames(styles.lightGreenButton, "lightGreenButton")}
                       onClick={() => handleGoToTournamentButtonClick(`/knockout/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj)}>
                  {t("ONGOING_TOURNAMENTS.GO_TO_TOURNAMENT")}
               </button>
            )
         } else if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.LEAGUE) {
            return (
               <button className={classNames(styles.lightGreenButton, "lightGreenButton")}
                       onClick={() => handleGoToTournamentButtonClick(`/league/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj)}>
                  {t("ONGOING_TOURNAMENTS.GO_TO_TOURNAMENT")}
               </button>
            )
         } else if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.LEAGUE_KNOCKOUT) {
            return (
               <button className={classNames(styles.lightGreenButton, "lightGreenButton")}
                       onClick={() => handleGoToTournamentButtonClick(`/league-knockout/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj)}>
                  {t("ONGOING_TOURNAMENTS.GO_TO_TOURNAMENT")}</button>
            )
         } else if (tournamentObj.tournamentTicketOmniCredits === 0) {
            if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.KNOCKOUT) {
               return (
                  <button className={classNames(styles.lightGreenButton, "lightGreenButton")}
                          onClick={() => handleGoToTournamentButtonClick(`/knockout/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj)}>
                     {t("ONGOING_TOURNAMENTS.GO_TO_TOURNAMENT")}
                  </button>
               )
            } else if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.LEAGUE) {
               return (
                  <button className={classNames(styles.lightGreenButton, "lightGreenButton")}
                          onClick={() => handleGoToTournamentButtonClick(`/league/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj)}>
                     {t("ONGOING_TOURNAMENTS.GO_TO_TOURNAMENT")}</button>
               )
            } else if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.LEAGUE_KNOCKOUT) {
               return (
                  <button className={classNames(styles.lightGreenButton, "lightGreenButton")}
                          onClick={() => handleGoToTournamentButtonClick(`/league-knockout/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj)}>
                     {t("ONGOING_TOURNAMENTS.GO_TO_TOURNAMENT")}
                  </button>
               )
            }
         }
      } else {
         if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.KNOCKOUT) {
            return (
               <button className={classNames(styles.lightGreenButton, "lightGreenButton")}
                       onClick={() => handleGoToTournamentButtonClick(`/knockout/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj)}>
                  {t("ONGOING_TOURNAMENTS.GO_TO_TOURNAMENT")}
               </button>
            )
         } else if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.LEAGUE) {
            return (
               <button className={classNames(styles.lightGreenButton, "lightGreenButton")}
                       onClick={() => handleGoToTournamentButtonClick(`/league/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj)}>
                  {t("ONGOING_TOURNAMENTS.GO_TO_TOURNAMENT")}
               </button>
            )
         } else if (tournamentObj?.tournamentType === TOURNAMENT_TYPES.LEAGUE_KNOCKOUT) {
            return (
               <button className={classNames(styles.lightGreenButton, "lightGreenButton")}
                       onClick={() => handleGoToTournamentButtonClick(`/league-knockout/${tournamentObj?.id}`, `returnUrl=${encodeURIComponent(location.pathname)}`, tournamentObj)}>
                  {t("ONGOING_TOURNAMENTS.GO_TO_TOURNAMENT")}
               </button>
            )
         }
      }
   }

   const handleCheckUserFantasySubscription = async (tournamentId) => {
      try {
         const response = await checkUserFantasySubscription(tournamentId, props.userName, props.Token);
         if (response.apiStatus && response?.isSubscribed) {
            return response?.isSubscribed;
         } else if (response.apiStatus) {
            return false;
         }
         return null;
      } catch (error) {
         console.error("handleCheckUserFantasySubscription", error);
      }
   };

   const handleTournamentAvailable = async (toastId) => {
      // Check if user is subscribed to fantasy. If not redirect to fantasy team creation page
      const isFantasySubscribed = await handleCheckUserFantasySubscription(latestTournament.id);
      if (isFantasySubscribed === false) {

         toast.update(toastId, {
            render: `Lets create a fantasy team before entering the tournament..`,
            type: toast.TYPE.SUCCESS,
            autoClose: 5000,
            closeButton: null,
            isLoading: false
         })
         setTimeout(() => {
            if (latestTournament?.latestMatchId) {
               navigate(`/fantasy-team/${latestTournament.id}`, {
                  state: {
                     tournamentDetails: latestTournament, returnPath: `/match/${latestTournament?.latestMatchId}`
                  }
               });
            } else {
               navigate(`/fantasy-team/${latestTournament.id}`, {
                  state: {
                     tournamentDetails: latestTournament,
                  }
               });
            }
         }, 1000);
      } else {
         if (latestTournament?.latestMatchId) {
            toast.update(toastId, {
               render: `Live match is available.Taking you to the match.`,
               type: toast.TYPE.SUCCESS,
               autoClose: 5000,
               closeButton: null,
               isLoading: false
            })
         } else {
            toast.update(toastId, {
               render: `No live match available.Taking you to the tournament.`,
               type: toast.TYPE.SUCCESS,
               autoClose: 5000,
               closeButton: null,
               isLoading: false
            })
         }
         setTimeout(() => {
            if (latestTournament?.latestMatchId) {
               navigate(`/match/${latestTournament?.latestMatchId}`);
            } else {
               navigate(`/${latestTournament.tournamentType?.toLowerCase()}/${latestTournament?.id}`);
            }
         }, 1000);
      }
   }

   const checkTournamentRegistration = async () => {

      const toastId = toast.loading(`Checking for the latest tournament..`);

      if (latestTournament) {
         sessionStorage.setItem('redirectPath', `/${latestTournament.tournamentType.toLowerCase()}/${latestTournament?.id}`);
      } else {
         sessionStorage.setItem('redirectPath', `/tournaments`);
      }

      if (!props.Token) {
         toast.update(toastId, {
            render: `Please Login to participate in tournament.`,
            type: toast.TYPE.INFO,
            autoClose: 5000,
            closeButton: null,
            isLoading: false
         })

         setTimeout(() => {
            navigate(`/login`);
         }, 1000);

      } else {
         if (latestTournament) {
            if (latestTournament.tournamentTicketOmniCredits <= 0) {

               if (latestTournament.active === true || latestTournament.toStart === true) {
                  await handleTournamentAvailable(toastId);
               }
            } else {
               try {
                  const response = await TournamentService.checkTournamentRegistration(latestTournament.id, props.userName);
                  if (response.apiStatus) {
                     if (!response.available) {
                        toast.update(toastId, {
                           render: `You haven't subscribed to this tournament..Taking you back to Tournaments`,
                           type: toast.TYPE.ERROR,
                           autoClose: 5000,
                           closeButton: null,
                           isLoading: false
                        })

                        setTimeout(() => {
                           navigate("/tournaments");
                        }, 1000);

                     } else {
                        await handleTournamentAvailable(toastId);
                     }
                  } else {
                     toast.update(toastId, {
                        render: `Error occurred in taking you to the latest tournament.Please try again later..`,
                        type: toast.TYPE.ERROR,
                        autoClose: 5000,
                        closeButton: null,
                        isLoading: false
                     })

                     setTimeout(() => {
                        navigate("/tournaments");
                     }, 1000);
                  }
               } catch (error) {
                  console.log(error);
                  throw error;
               }
            }
         } else {
            toast.update(toastId, {
               render: `No latest tournament available, Taking you to the Tournaments`,
               type: toast.TYPE.ERROR,
               autoClose: 5000,
               closeButton: null,
               isLoading: false
            })

            setTimeout(() => {
               navigate("/tournaments");
            }, 1000);
         }
      }
   }

   async function showPurchaseModal(tournament) {
      if (!tournament?.geoBlockedForUser) {
         const toastId = toast.loading(t("ONGOING_TOURNAMENTS.CHECKING_YOUR_REGISTRATION_STATUS"));
         setIsRegistrationLoading(true);
         await checkTournamentRegistration(tournament.id, props.userName).then((resp) => {
            if (resp.apiStatus) {
               if (resp.available) {
                  toast.update(toastId, {
                     render: `${t("ONGOING_TOURNAMENTS.REGISTRATION_DONE_REDIRECTING_TO_THE_TOURNAMENT")}`,
                     type: toast.TYPE.SUCCESS,
                     autoClose: 5000,
                     closeButton: null,
                     isLoading: false
                  })

                  setTimeout(() => {
                     if (tournament?.tournamentType === TOURNAMENT_TYPES.LEAGUE) {
                        window.location.href = `/league/${tournament?.id}?returnUrl=${encodeURIComponent(location.pathname)}`;
                     } else if (tournament?.tournamentType === TOURNAMENT_TYPES.KNOCKOUT) {
                        window.location.href = `/knockout/${tournament?.id}?returnUrl=${encodeURIComponent(location.pathname)}`;
                     } else if (tournament?.tournamentType === TOURNAMENT_TYPES.LEAGUE_KNOCKOUT) {
                        window.location.href = `/league-knockout/${tournament?.id}?returnUrl=${encodeURIComponent(location.pathname)}`;
                     }
                  }, 1000);
               } else if (tournament.active === false && tournament.toStart === false) {

                  toast.update(toastId, {
                     render: `${t("ONGOING_TOURNAMENTS.TOURNAMENT_IS_COMPLETED_CANT_ENTER_THE_TOURNAMENT_NOW")}`,
                     type: toast.TYPE.ERROR,
                     autoClose: 5000,
                     closeButton: null,
                     isLoading: false
                  })

                  setIsRegistrationLoading(false);
               } else {
                  toast.update(toastId, {
                     render: `${t("ONGOING_TOURNAMENTS.PLEASE_ENROLL_TO_THE_TOURNAMENT_BEFORE_ENTERING")}`,
                     type: toast.TYPE.INFO,
                     autoClose: 5000,
                     closeButton: null,
                     isLoading: false
                  })

                  setTournamentDetails(tournament)
                  setIsPurchaseModalOpen(true);
                  setIsRegistrationLoading(false);
               }
            } else {
               toast.error(AUTHENTICATION_TOASTS.TOURNAMENT_IS_BLOCKED)
            }
         });
      }
   }

   const handleGoToTournamentButtonClick = async (pathname, search, tournament) => {
      if (!tournament?.geoBlockedForUser) {
         const tournamentUrl = `${pathname}?${search}`;
         const isFantasySubscribed = await handleCheckUserFantasySubscription(tournament.id);
         if (tournament?.tournamentTicketOmniCredits > 0 && !props.Token) {
            sessionStorage.setItem('redirectPath', `/fantasy-team/${tournament.id}`);
            toast.error(t("ONGOING_TOURNAMENTS.PLEASE_SIGN_IN_TO_ACCESS_THE_PAID_TOURNAMENT"));
            navigate("/login");
         } else if (new Date(tournamentDetails?.startTime) < new Date()) {
            const isFantasySubscribed = await handleCheckUserFantasySubscription(tournament.id);
            if (isFantasySubscribed === false) {
               navigate(`/fantasy-team/${tournament.id}`, {state: {tournamentDetails: tournament}});
               return;
            }
            window.location.href = tournamentUrl;
         } else {
            window.location.href = tournamentUrl;
         }
      } else {
         toast.error(AUTHENTICATION_TOASTS.TOURNAMENT_IS_BLOCKED)
      }
   };


   function closeModal() {
      setIsPurchaseModalOpen(false);
      toast.dismiss();
   }

   const renderAllTournaments = () => {
      if (isLoading) {
         return loadingIndicator;
      }

      return totalElements === 0 ? (
         <div className={Styles.loaderContainer}>
            <div className={classNames(Styles.NoTournamentsFound, "gray-text font18")}>
               {t("ONGOING_TOURNAMENTS.NO_TOURNAMENTS_FOUND")}
            </div>
         </div>
      ) : (
         <>
            {liveTournaments?.map((tournamentObj) => liveTournamentCard(tournamentObj))}
            {scheduledTournaments?.map((tournamentObj) => scheduledTournamentCard(tournamentObj))}
         </>
      )
   }

   return (
      <div>

         <div className={classNames(styles.ellipse, styles.e25)}></div>
         <div className={classNames(styles.ellipse, styles.e26)}></div>
         <div className={classNames(styles.ellipse, styles.e27)}></div>

         <div className={classNames(styles.ellipse, styles.e29)}></div>
         {/*<div className={classNames(styles.ellipse30)}></div>*/}
         <div className={classNames(styles.rightLightning)}>
            <img src={leftStar} alt={""}/>
         </div>

         <div className={classNames(styles.homeContainer)}>
            {(connectPubnub && pubNubToken != null) ?
               <FeaturedTournamentsHandler onRecieved={messageFromFeaturedTournamentsHandlerCallback}
                                           pubNubToken={pubNubToken} subscribe={subscribeChannel}
                                           onUnsubscribe={unSubscribeChannel}/> : <></>}
            <div
               className={classNames(styles.ResponceCont, "col-12", "p-0", "row", "ps-2", "pe-2", "ps-xxl-0", "pe-xxl-0")}>
               {getConf(METALUX_TIMER.GUIDANCE_TIP_SHOW) ?
                  <>
                     <GuidanceTip page="home" gotItIndex={0} gotItHandler={gotItHandler} nextGotItNum={nextGotItIndex}
                                  tipCancelHandler={tipCancelHandler} tipIndexCounter={tipIndexCounter}
                                  setTipIndexCounter={setTipIndexCounter} screenSize={screenSize}/>
                     <GuidanceTip page="home" gotItIndex={1} gotItHandler={gotItHandler} nextGotItNum={nextGotItIndex}
                                  tipCancelHandler={tipCancelHandler} tipIndexCounter={tipIndexCounter}
                                  setTipIndexCounter={setTipIndexCounter} screenSize={screenSize}/>
                     <GuidanceTip page="home" gotItIndex={2} gotItHandler={gotItHandler} nextGotItNum={nextGotItIndex}
                                  tipCancelHandler={tipCancelHandler} tipIndexCounter={tipIndexCounter}
                                  setTipIndexCounter={setTipIndexCounter} screenSize={screenSize}/>
                  </> : <></>}

               <NavbarSecondary page="events"/>

               <div className={classNames(styles.lineWaveImgWrapper)}>
                  {/*<img src={lineWave}/>*/}
               </div>
            </div>
            {!translationsLoaded ? <> {loadingIndicator} </> : <>
               <div className={classNames(styles.mainImgBg, "col-12 pt-0 pb-0 pb-md-5 row")}
                    style={{maxWidth: "1500px"}}>
                  <div
                     className={classNames(styles.ResponceCont, "col-12", "p-0", "row", "ps-2", "pe-2", "ps-xxl-0", "pe-xxl-0 pt-0  pb-0 pb-md-5")}>
                     <div className={classNames(styles.section1Container, "row no-gutter relative-pos p-0")}>
                        {/*<div className={classNames(styles.mainHeadingWrapper, "p-0")}>*/}

                        <div className={classNames("p-0 row container ")}>
                           {/*<div className={classNames(styles.mainHeader)}>*/}
                           <div
                              className={classNames(styles.mainH1Wrapper, "col-12 col-xl-6 col-lg-6 col-md-7 col-sm-12 col-xs-12 order-1 order-md-1 mt-0 mt-md-4 p-0 ms-2 me-2 me-md-0 ms-md-0")}>
                              <h1 className={classNames(styles.mainH1, "heading1 gradient-color pt-4")}>
                                 <div className={classNames(styles.blueFlash)}></div>
                                 {/* {t("HOME_PAGE.EVERYBODY_IS_A_WINNER_IN_META_11")} */}
                                 {t("HOME_PAGE.PLAY_FANTASY")}
                                 <br/>
                                 {t("HOME_PAGE.CRICKET_WIN_CRYPTO")}
                                 <span className="headingWhite86">
                                 {/* {t("HOME_PAGE.DAILY_FREE_TO_PLAY_MATCHES")} */}
                                    {t("HOME_PAGE.ITS_FAST_FUN_AND_FREE")}
                                 </span>

                                 {/* <div className={classNames(styles.timeNontText, "heading1Muted heading1Muted regular italic")} style={{WebkitTextFillColor: "#ffffff"}}>
                                    <label>{t("HOME_PAGE.6_AM - 8_PM_IST")}</label>
                                 </div> */}
                              </h1>
                              <p className="color-gray2">
                                 {t("HOME_PAGE.STEP_INTO_VIRTUAL_FANTASY_CRICKET_WITH_META_11_BUILD_YOUR_DREAM_TEAM_CHALLENGE_FRIENDS_AND_WIN_BIG_WITH_GROUNDBREAKING_AI_ATHLETES_JOIN_NOW_FOR_AN_UNMATCHED_CRICKET_EXPERIENCE")}
                              </p>
                              <div className={classNames(styles.startButtonWrapper, "mt-4 text-start")}>
                                 {props.Token === undefined || props.Token === null ?
                                    <Link to={"/register"} style={{color: "#000000"}}
                                          className="lightGreenButton me-2 me-md-3">
                                       {t("HOME_PAGE.REGISTER")}
                                    </Link> :
                                    (latestTournament) ?
                                       <Link onClick={checkTournamentRegistration}
                                             className="lightGreenButton me-2 me-md-3">
                                          {t("HOME_PAGE.CREATE_YOUR_TEAM")}
                                          {/* {t("HOME_PAGE.PLAY_NOW")} */}
                                       </Link> :
                                       <Link to={"/tournaments"} className="lightGreenButton me-2 me-md-3">
                                          {t("HOME_PAGE.CREATE_YOUR_TEAM")}
                                          {/* {t("HOME_PAGE.PLAY_NOW")} */}
                                       </Link>

                                 }

                                 <button onClick={scrollToDiv} className="GrayButton">
                                    <text>
                                       {t("HOME_PAGE.HOW_TO_PLAY")}
                                       {/* {t("HOME_PAGE.WATCH_TRAILER")} */}
                                    </text>
                                 </button>
                              </div>
                           </div>
                           <div className="col-md-5 order-md-2">
                           </div>
                           <div
                              className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 text-end order-2 order-md-2 p-0 mt-4 d-md-none WWWWWW">
                              <img src="https://storage.googleapis.com/meta11/serviceplatform/home3-1.webp" alt={""}
                                   style={{width: "100%"}}/>
                           </div>
                        </div>
                     </div>
                  </div>

               </div>
               <>
                  {metaTimer ?
                     <>
                        {getConf(METALUX_TIMER.GUIDANCE_TIP_SHOW) ?
                           <GuidanceTip page="home" gotItIndex={3} gotItHandler={gotItHandler}
                                        nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler}
                                        tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}
                                        screenSize={screenSize}/>
                           : <></>}
                        {getConf(METALUX_TIMER.SHOW_METALUX_TIMER) ?
                           <MetaluxTimer metaTimer={metaTimer} tournamnetEndTimer={tournamnetEndTimer}/> : <></>}
                     </> :
                     <></>
                  }
               </>
               {/* HowItWorks */}
               <div className={classNames(styles.StepwizardRowWrapper)}>
                  <div className={classNames(styles.StepwizardRow, "container row gx-0 mt-5 pt-5")}>
                     <div className={classNames(styles.ellipse, styles.e28)}></div>


                     <div className="col-12">
                        <div className={classNames(styles.HowItWorks)}>
                           <h1 className={classNames("heading2 pt-4 pe-4 gradient-color", "mb-4")}>
                              {t("HOME_PAGE.ITS_EASY_TO_START_PLAYING_META_11")}
                           </h1>
                           {/* <p>{t("HOME_PAGE.JOIN_MILLIONS_IN_THE_ELECTRIFYING_WORLD_OF_META_11_COMPETE_IN_DAILY_CONTESTS_BUILD_YOUR_DREAM_TEAM_AND_WIN_BIG")}</p> */}
                           <p>
                              {t("HOME_PAGE.JOIN_MILLIONS_IN_THE_ELECTRIFYING_WORLD_OF_META_11")}<br/>{t("HOME_PAGE.COMPETE_IN_DAILY_CONTESTS_BUILD_YOUR_DREAM_TEAM_AND_WIN_BIG")}
                           </p>
                           <div className={classNames(styles.Stepwizard)}>
                              <ul>
                                 <li style={{cursor: 'default'}}>
                                    <div>
                                       <div
                                          className={classNames(styles.ellipse, styles.eMulticolorFull, styles.E1)}></div>
                                       <div
                                          className={classNames(styles.ellipse, styles.eMulticolorFull, styles.E2)}></div>
                                       <div
                                          className={classNames(styles.ellipse, styles.eMulticolorFull, styles.E3)}></div>
                                       <span>
                                          <div>1</div>
                                       </span>
                                       <img src={JoinNow}/>
                                       <h3>{t("HOME_PAGE.REGISTER")}</h3>
                                    </div>
                                 </li>
                                 <li style={{cursor: 'default'}}>
                                    <div>
                                       <div
                                          className={classNames(styles.ellipse, styles.eMulticolorFull, styles.E1)}></div>
                                       <div
                                          className={classNames(styles.ellipse, styles.eMulticolorFull, styles.E2)}></div>
                                       <span>
                                          <div>2</div>
                                       </span>
                                       <img src={Step2}/>
                                       <h3>{t("HOME_PAGE.CREATE_YOUR_FANTASY_TEAM")}</h3>
                                    </div>
                                 </li>
                                 <li style={{cursor: 'default'}}>
                                    <div>
                                       <div
                                          className={classNames(styles.ellipse, styles.eMulticolorFull, styles.E1)}></div>
                                       <div
                                          className={classNames(styles.ellipse, styles.eMulticolorFull, styles.E2)}></div>
                                       <span>
                                          <div>3</div>
                                       </span>
                                       <img src={Step3}/>
                                       <h3>{t("HOME_PAGE.EARN_FANTASY_POINTS_AND_CLIMB_THE_LEADERBOARD")}</h3>
                                    </div>
                                 </li>
                                 <li style={{cursor: 'default'}}>
                                    <div>
                                       <div
                                          className={classNames(styles.ellipse, styles.eMulticolorFull, styles.E1)}></div>
                                       <div
                                          className={classNames(styles.ellipse, styles.eMulticolorFull, styles.E2)}></div>
                                       <span>
                                          <div>4</div>
                                       </span>
                                       <img src={Step4}/>
                                       <h3>{t("HOME_PAGE.CONVERT_POINTS_TO_$SPRTS_TOKENS")}</h3>
                                    </div>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className={classNames(styles.ellipse, styles.e39)}></div>
               </div>

               {/* Testimonials */}
               {/* <div className="container row gx-0 mt-5 pt-5">
                  <div className="col-12">
                     <div className={classNames(styles.Testimonials)}>

                        <h1 className={classNames("heading2 pt-4 pe-4 gradient-color", "mb-4")}>Testimonials</h1>
                        <div className={classNames(styles.Testimonial)}>
                           <div className="container">

                              <div className={classNames(styles.joinComunitySection)}
                                   style={{textAlign: "center", position: "relative"}}>
                                 <span className={classNames(styles.leftShock)}></span>
                                 <span className={classNames(styles.rightShock)}></span>

                                 <div className={classNames(styles.TestimonialWrapper)}>

                                    <img className={classNames(styles.Quote)} src={Quote}/>
                                    <p>
                                       {isBlogsLoading ? <Puff height={'2em'} strokeWidth={5} speed={2}/> :
                                          <>
                                             {testimonialBlog.message}
                                          </>
                                       }
                                    </p>
                                    <div className={classNames(styles.Footer)}>
                                       <div className={classNames(styles.Row1)}>
                                          <button className={classNames(styles.ArrowLeft)} onClick={handlePreviousTestimonial}><i class="fa fa-long-arrow-left" aria-hidden="true"></i></button>
                                          <div className={classNames(styles.ProfilePic)} style={{background: `url(${testimonialBlog.picUrl ?? ''})`}}>

                                          </div>
                                          <button className={classNames(styles.ArrowRight)} onClick={handleNextTestimonial}><i class="fa fa-long-arrow-right" aria-hidden="true"></i></button>
                                       </div>
                                       <div className={classNames(styles.Row2)}>
                                          <h4>{testimonialBlog.firstname ?? '' + testimonialBlog.lastname ?? ''}</h4>
                                          <p>Over $1M Won Weekly!</p>
                                       </div>
                                    </div>

                                 </div>
                              </div>
                           </div>

                        </div>
                     </div>
                  </div>
               </div> */}

               {/* <div className="row gx-0 mt-4">
                  <div className="col-12" style={{position: "relative"}}>
                     <div className={classNames(styles.ellipse, styles.e28)}></div>
                     <div className="container mb-0 mb-sm-5 p-0">
                        <div className="row gx-0" style={{position: "relative"}}>
                           <div className={classNames(styles.section1EndGradient)}></div>
                           <div className="row p-0">

                              <div className={classNames(styles.kumasContent, "col-12")}>
                                 <div className="transCard- bluePane- p-2 p-sm-5 row">
                                    <div className="col-md-6">
                                       <div className="container">
                                          <h2>{t("HOME_PAGE.KUMAR_SANGAKKARA")}</h2>
                                          <p className={classNames("body-text2")}>
                                             {t("HOME_PAGE.META_11_CO_FOUNDER, FORMER_SRI_LANKAN_CAPTAIN, AND_ICC_HALL_OF_FAMER")}
                                          </p>
                                          <p className={classNames("body-text1 p-1")}>
                                             {t("HOME_PAGE.CRICKET_HAS_EVOLVED_OVER_THE_YEARS, AND_NOW, WITH_THE_FUSION_OF_AI_AND_CUTTING_EDGE_TECHNOLOGIES, META_11_INVITES_YOU_TO_BE_PART_OF_THE_ACTION_UNITING_2_5_BILLION_FANS_WORLDWIDE")}
                                          </p>
                                       </div>
                                    </div>
                                    <div className="col-md-6">
                                       <img src="https://storage.googleapis.com/meta11/serviceplatform/sangahomepage.webp" alt={"Mr.Kumar Sangakkara"}/>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div> */}

               {/* <div className="row gx-0 mb-0 mt-0 mt-sm-5">
                  <div className="col-12" style={{position: "relative"}}> */}
               {/* <div className={classNames(styles.ellipse, styles.e37)}></div>
                     <div className={classNames(styles.ellipse, styles.e38)}></div>
                     <div className={classNames(styles.ellipse, styles.e39)}></div> */}
               {/* <div className={classNames(styles.section3Background)}></div> */}
               {/* <div className="gradientSphear"></div> */}
               {/* <div className="container">
                        <div className={classNames("row d-flex", "p-0")}>
                           <div className="col-12 col-md-5 col-lg-5 col-xl-5 d-flex order-2 order-md-1" style={{alignItems: "center", flexDirection: "column", position: "relative",}}>
                              <img src="https://storage.googleapis.com/meta11/serviceplatform/blue-pers-block.webp" alt={"Blue Pers"} style={{width: "100%", maxWidth: "384px"}}/>

                           </div>
                           <div className="col-12  col-md-7 col-lg-7 col-xl-7 d-flex order-1 order-md-2" style={{flexDirection: "column", alignItems: "start", justifyContent: "center",}}>
                              <img src="https://storage.googleapis.com/meta11/serviceplatform/metaBIgLogo.webp" alt={"META11"} className={classNames(styles.bigLogo)}/>
                              <p className={classNames("body-text1")} style={{maxWidth: "704px"}}>
                                 {t("HOME_PAGE.STEP_INTO_THE_FIRST_INSTALMENT_OF_A_RE_IMAGINED_WORLD_OF_FANTASY_SPORTS_GAMING.")}
                                 {t("HOME_PAGE.META_11_FANTASY_IS_REVOLUTIONIZING_HOW_YOU_ENJOY_FANTASY_CRICKET.")}
                                 {t("HOME_PAGE.TEST_YOUR_SKILLS_AND_PREDICT_OUTCOMES_BETWEEN_LIVING, BREATHING_DIGITAL_AI_ATHLETES. WITH_A_FOCUS_ON_FAST_TO_FUN_AND_BEING")}{" "}
                                 <b>
                                    <i>{t("HOME_PAGE.FREE_TO_PLAY_AND_WIN_REWARDS")}</i>
                                 </b>
                                 , {t("HOME_PAGE.WE’RE_OPENING_THE_WORLD_OF_FANTASY_CRICKET_GAMING_TO_ANYONE, ANYWHERE.")}.
                              </p>
                           </div>
                        </div>
                     </div> */}
               {/* </div>

               </div> */}
               {/* <div className={classNames(styles.ellipse, styles.e40)}></div> */}

               {/* FeaturedTournaments */}
               <div className={classNames(styles.FeaturedTournamentsRowWrapper)}>
                  <div className="container row gx-0 mt-5">
                     <div className="col-12">
                        <div className={classNames(styles.FeaturedTournaments)}>
                           <h1
                              className={classNames("heading2 pt-4 pe-4 gradient-color", "mb-4")}>{t("HOME_PAGE.FEATURED_TOURNAMENTS")}</h1>
                           <div className={classNames(styles.TournamentsWrapper, "row")}>
                              {isFeaturedTournamentsLoading ?
                                 // featured loader
                                 <div style={{textAlign: "center"}}>
                                    <div className={classNames(styles.LoaderIconWrapper, "lds-facebook")}>
                                       <div></div>
                                       <div></div>
                                       <div></div>
                                    </div>
                                 </div>
                                 : renderAllTournaments()}
                           </div>
                           <div className={classNames(styles.BtnRow, "row mt-5")}>
                              <Link to={"/tournaments"}
                                    className={classNames(styles.ViewAllTournaments, "lightGreenButton me-2 me-md-4")}>{t("HOME_PAGE.VIEW_ALL_TOURNAMENTS")}</Link>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div className={classNames(styles.HowToPlayRow, "row gx-0")} ref={targetDivRef}>
                  <div className={classNames(styles.ellipse, styles.e38)}></div>
                  <div className={classNames(styles.ellipse, styles.e38, styles.e38II)}></div>
                  <div className={classNames(styles.ellipse, styles.e40)}></div>
                  <div className="col-12" style={{position: "relative"}}>
                     {/* <div className={classNames(styles.ellipse, styles.e41)}></div> */}
                     <div className="container">
                        <div className="row gx-0 relative-pos">
                           <div className="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
                           <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                              <div className={classNames(styles.HowToPlay)}>
                                 <h1
                                    className={classNames("heading2 pt-4 pe-4 gradient-color txt-c", "mb-4")}>{t("HOME_PAGE.HOW_TO_PLAY")}</h1>
                                 {/* <p>{t("HOME_PAGE.READY_TO_CONQUER_META_11_WATCH_OUR_TUTORIAL_MASTER_YOUR_MOVES_AND_LEAP_INTO_ACTION_CHALLENGE_OTHERS_MAKE_SMART_PREDICTIONS_OUTWIT_YOUR_RIVALS_AND_WIN_BIG")}</p> */}
                                 <p>
                                    {t("HOME_PAGE.READY_TO_CONQUER_META_11_WATCH_OUR_TUTORIAL_MASTER_YOUR_MOVES_AND_LEAP_INTO_ACTION")}<br/> {t("HOME_PAGE.CHALLENGE_OTHERS_MAKE_SMART_PREDICTIONS_OUTWIT_YOUR_RIVALS_AND_WIN_BIG")}
                                 </p>
                              </div>
                              <video controls autoPlay playsInline muted loop
                                     style={{width: "100%", maxWidth: "100%", aspectRatio: 854 / 480}}>
                                 <source
                                    src={lang === SITE_LANGUAGE.ENGLISH ? 'https://storage.googleapis.com/meta11/serviceplatform/videos/compressed_htp.mp4' :
                                       'https://storage.googleapis.com/meta11/serviceplatform/videos/compressed_htp.mp4'}
                                    type="video/mp4"/>
                              </video>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               {/* <div className="row gx-0" style={{position: "relative", zIndex: 1}}>
                  <div className="col-12" style={{position: "relative"}}> */}
               {/* <div className={classNames(styles.yellowMainBack)}></div>
                     <div className={classNames(styles.yellowGradientBot)}></div> */}
               {/* <div className={classNames(styles.yellowRightSphere)}></div> */}

               {/* <div className="container mt-5">
                        <div className="row d-flex p-0">
                           <div className="col-12 col-md-6 d-flex order-1" style={{flexDirection: "column", alignItems: "start", justifyContent: "center"}}>
                              <h1 className={classNames("heading2 pt-4 pe-4 gradient-color", "mb-4")}>{t("HOME_PAGE.FAST_FUN_FREE")}</h1>
                              <p className={classNames("body-text1")} style={{maxWidth: "600px"}}>
                                 {t("HOME_PAGE.META_11_FANTASY_IS_WHERE_FAST_TO_FUN_EXPERIENCES_AWAIT_CRICKET_FANS_AND_GAMERS_OF_ALL_LEVELS. WITH_A_FOCUS_ON_DELIVERING_MATCHES_AT_SPEED")}
                                 {t("HOME_PAGE.PLAYERS_CAN_JUMP_RIGHT_INTO_THE_ACTION_AND_DISCOVER_A_NEW_WORLD_OF_ESPORTS")}
                                 {t("HOME_PAGE.BEST_OF_ALL, META_11_FANTASY_IS_COMPLETELY_FREE_TO_PLAY_AND_OPEN_TO_ANYONE")}
                                 {t("HOME_PAGE.WHETHER_YOU'RE_A_SEASONED_GAMER_OR_NEW_TO_THE_WORLD_OF_FANTASY_SPORTS, COME_BE_PART_OF_THE_EXCITING_GAMEPLAY_THAT_IS_LITERALLY_WORTH_YOUR_WHILE!")}
                              </p>
                              {props.isAuthenticated ? <Link to={"/tournaments"} className="lightGreenButton marginTop42" style={{marginRight: 10}}>
                                    {t("HOME_PAGE.PLAY_NOW")}
                                 </Link> :
                                 <Link to={"/register"} className="lightGreenButton marginTop42" style={{marginRight: 10}}>
                                    {t("HOME_PAGE.GET_STARTED")}
                                 </Link>
                              }
                           </div>
                           <div className="col-12 col-md-6 d-flex order-2" style={{alignItems: "center", flexDirection: "column", position: "relative",}}>
                              <div className={classNames(styles.yellowGradient01)}></div>
                              <div className={classNames(styles.yellowGradient02)}></div>
                              <div className={classNames(styles.yellowGradient03)}></div>
                              <div className={classNames(styles.yellowBatBlur)}></div>
                              <img src={"https://storage.googleapis.com/meta11/serviceplatform/MasonHomePage.webp"} style={{width: "100%", maxWidth: "444px"}} alt={""}/>
                           </div>
                        </div>
                     </div> */}
               {/* </div>
               </div> */}

               {/* <div className={classNames(styles.aiAthletesRow, "row gx-0")} style={{position: "relative", zIndex: 0}}>
                  <div className={classNames(styles.sectionPadding, "col-12")} style={{position: "relative"}}>
                     <div className={classNames(styles.ladyBack)}></div>

                     <div className={classNames(styles.ellipse, styles.e28)} style={{width: "80vw", top: -99, opacity: 0.6}}></div>
                     <div className={classNames(styles.ResponceCont2)}>
                        <div className="row d-flex p-0">
                           <div className="col-12 col-md-5 col-lg-5 col-xl-5 d-flex  order-2 order-md-1 " style={{alignItems: "center", flexDirection: "column",}}>
                              <img src={"https://storage.googleapis.com/meta11/serviceplatform/PinkGirl.webp"} alt={""} className={classNames(styles.faqIMG)} style={{maxWidth: "548px"}}/>

                           </div>
                           <div className="col-12 col-md-7 col-lg-7 col-xl-7 d-flex order-1 order-md-2" style={{flexDirection: "column", alignItems: "start", flexWrap: "wrap", justifyContent: "center"}}>
                              <div className={classNames("heading2 pt-4 gradient-color", "mb-4 ms-0 row col-10")} style={{paddingLeft: "3px", width: "auto"}}>{t("HOME_PAGE.AI_ATHLETES — FUTURE_SPORTING_HEROES")}</div>
                              <p className={classNames("body-text1")} style={{maxWidth: "704px"}}>
                                 {t("HOME_PAGE.CLONED_IN_OUR_INCUBATOR, EACH_OF_OUR_AI_ATHLETES_WERE_INITIALLY_TRAINED_USING_REAL_WORLD_CRICKETING_DATA.")}
                                 {t("HOME_PAGE.EACH_OF_THESE_ATHLETES_HAVE_THEIR_OWN_BATTING_AND_BOWLING_AVERAGES, TOP_SCORES_AND_OTHER_STATISTICS.")}
                                 {t("HOME_PAGE.THEY_IMPROVE_THE_MORE_THEY_PLAY_AND_THE_MORE_THEY_LEARN, JUST_LIKE_REAL_WORLD_CRICKETERS.")}
                                 {t("HOME_PAGE.IMMERSE_YOURSELF_IN_THE_NEXT_GENERATION_OF_GAMING_EXPERIENCES.")}
                                 {t("HOME_PAGE.HARNESS_YOUR_SKILL_TO_ACCURATELY_PREDICT_OUTCOMES_AND_RISE_TO_THE_TOP_OF_THE_LEADERBOARDS_AND_WIN.")}
                              </p>
                              {props.isAuthenticated ? <Link to={"/tournaments"} className="lightGreenButton marginTop42" style={{marginRight: 10}}> {t("HOME_PAGE.PLAY_NOW")}</Link> :
                                 <Link to={"/register"} className="lightGreenButton marginTop42" style={{marginRight: 10}}> {t("HOME_PAGE.JOIN_NOW")}</Link>}


                           </div>
                        </div>
                     </div>
                  </div>
               </div> */}

               {/* <div className="row p-0">
                  <div className={classNames(styles.starryback, "col-12 pb-5 mt-5")}>


                     <div className={classNames(styles.leftRay)}>
                        <img src={"https://storage.googleapis.com/meta11/serviceplatform/neonflashLeft.webp"} alt={""}/>
                     </div>
                     <div className={classNames(styles.rightRay)}>

                        <img src={"https://storage.googleapis.com/meta11/serviceplatform/neonflashRignt.webp"} alt={""}/>
                     </div>


                     <div className={classNames("col-12  mt-5 mb-5 ms-auto me-auto")} style={{maxWidth: "1016px"}}>
                        <h1 className={classNames("heading2 pt-4 gradient-color", "mb-4 text-start text-sm-start text-md-center")}>
                           <div className={classNames(styles.blueFlash2)}></div>
                           {t("HOME_PAGE.RICH_STORY_WORLD")}
                        </h1>
                        <p className={classNames("body-text1 text-start text-sm-start text-md-center")}>
                           {t("HOME_PAGE.IN_THE_YEAR_2277, EARTH_IS_A_REALM_OF_BOUNDLESS_INTRIGUE.")}
                           {t("HOME_PAGE.RENEWABLE_ENERGY_FUELS_PROGRESS, YET_THE_ELIXIR_OF_GREEN_POWER_FALTERS_IN_THE_FACE_OF_VANISHING_RARE_EARTH_MINERALS.")}
                           {t("HOME_PAGE.A_COSMIC_ODYSSEY_UNFOLDS_AS_HUMANITY'S_GAZE_TURNS_SKYWARD, SEEKING_SUSTENANCE_IN_THE_STARS. ")}
                           {t("HOME_PAGE.THE_DANCE_OF_ASTEROID_MINING_STUMBLES_THROUGH_EXPLOSIVE_MISHAPS_AND_ECONOMIC_TURMOIL.")}
                           {t("HOME_PAGE.AMID_UNCERTAINTY, A_REVELATION_IGNITES_A_NEW_ERA_THE_BRITISH_EMPIRE_UNVEILS_VAST, UNTAPPED_RARE_EARTH_RESERVES_BURIED_BENEATH_THE_EARTH'S_CRUST, REKINDLING_HOPE_FOR_GENERATIONS.")}
                           {t("HOME_PAGE.AS_THIS_LUMINOUS_PROMISE_DAWNS, META_11_EMERGES—AN_ELECTRIFYING_SPORT_WHERE_OMNI_LINKED_AVATARS_WIELD_CRICKET'S_LEGACY_AND_CULTURAL_PROWESS, FUELED_BY_THE_SAME_NEWFOUND_ABUNDANCE.")}
                           {t("HOME_PAGE.AMID_EARTH'S_ENIGMA, META_11'S_GLADIATORS_RISE, ECHOING_A_WORLD'S_YEARNING_FOR_VICTORY_AND_DESTINY.")}
                        </p>
                     </div>

                  </div>

               </div> */}


               {/* <div className="row col-12 d-flex p-0 mb-5" style={{position: "relative"}}>
                  <div className={classNames(styles.redManEllips)}></div>
                  <div className={classNames(styles.redManEllipsRed)} style={{bottom: -90}}></div>

                  <div className={classNames(styles.ResponceCont2, "col-12  ms-auto me-auto row d-flex")}>

                     <div className="col-12 col-md-6 col-lg-5 p-0 d-flex order-2 order-md-1" style={{flexDirection: "column", alignItems: "start", justifyContent: "center",}}>
                        <img src={"https://storage.googleapis.com/meta11/serviceplatform/pers3block2.webp"} alt={""} style={{width: "100%"}}/>

                     </div>
                     <div className="col-12 col-md-6 d-flex order-1 order-md-2" style={{flexDirection: "column", alignItems: "start", justifyContent: "center",}}>

                        <h1 className={classNames("heading2 pt-4 gradient-color", "mb-4")}>{t("HOME_PAGE.PUT_YOURSELF_IN_THE_GAME")}</h1>
                        <p className={classNames("body-text1 ")} style={{maxWidth: "704px"}}>
                           {t("HOME_PAGE.EXPERIENCE_THE_BLURRING_BOUNDARIES_OF_POSSIBILITY_IN_CRICKET.")}
                           {t("HOME_PAGE.CONNECT_WITH_TEAMMATES_AND_FELLOW_GAMERS. REWRITE_THE_RULES_OF_SPORTS_GAMING, EMBRACE_THE_FUTURE, AND_EMBARK_ON_A_JOURNEY_OF_CAMARADERIE.")}
                        </p>
                        <p className={classNames("body-text1")} style={{maxWidth: "704px"}}>
                           {t("HOME_PAGE.JOIN_THE_COMMUNITY_AND_BECOME_A_PIONEER_IN_THE_NEXT_GENERATION_OF_FANTASY_CRICKET_GAMING_TODAY.")}
                        </p>
                        {props.isAuthenticated ? <Link to={"/tournaments"} style={{color: "#000000", marginRight: 10}} className="lightGreenButton marginTop42 me-2 me-md-4">{t("HOME_PAGE.MATCHES")}</Link> :
                           <Link to={"/register"} style={{color: "#000000", marginRight: 10}} className="lightGreenButton marginTop42 me-2 me-md-4">{t("HOME_PAGE.SIGN_UP_NOW")}</Link>}

                     </div>
                  </div>
                  <div className={classNames(styles.redMansphere)}></div>
               </div> */}


               {/* Sprts Token */}
               <div className={classNames(styles.SprtsTokenRowWrapper)}>
                  <div className="container row gx-0 mt-3 pt-2">
                     <div className="col-12">
                        <div className={classNames(styles.SprtsToken)}>
                           <div className={classNames(styles.Col1)}>
                              <img src={Sprts}/>
                           </div>
                           <div className={classNames(styles.Col2)}>
                              <div>
                                 <h1
                                    className={classNames("heading2 pt-4 pe-4 gradient-color", "mb-4")}>{t("HOME_PAGE.FUEL_YOUR_FANDOM_WITH_$SPRTS")}</h1>
                                 <Link to={"https://sprts.io/"} target="_blank"
                                       className="lightGreenButton me-2 me-md-4">{t("HOME_PAGE.LEARN_MORE")}</Link>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className={classNames(styles.ellipse, styles.eMulticolorFull)}></div>
                  <div className={classNames(styles.ellipse, styles.ePinkFull)}></div>
               </div>

               {/* Road Map */}
               <div className={classNames(styles.RoadMapRowWrapper)}>
                  <div className="container row gx-0 mt-5 pt-5">
                     <div className="col-12">
                        <div className={classNames(styles.RoadMap)}>
                           <h1 className="heading2 pt-4 pe-4 gradient-color mb-4 txt-c">{t("HOME_PAGE.ROAD_MAP")}</h1>
                           <div className={classNames(styles.timeline)}>
                              <div className={classNames(styles.container, styles.left)}>
                                 <div className={classNames(styles.Topborder)}></div>
                                 <div className={classNames(styles.content, styles.RightBorder)}>
                                    <img src={RM1} alt=""/>
                                    <h2>{t("HOME_PAGE.Q_22024")}</h2>
                                    <p>{t("HOME_PAGE.USERS_EARN_$SPRTS_TOKEN_WITH_META_11_FANTASY_TOKEN_WHITE_PAPER_AND_ROADMAP_DROP")}</p>
                                 </div>
                              </div>
                              <div className={classNames(styles.container, styles.right)}>
                                 <div className={classNames(styles.Topborder)}></div>
                                 <div className={classNames(styles.content, styles.LeftBorder)}>
                                    <img src={RM2} alt=""/>
                                    <h2>{t("HOME_PAGE.Q_32024")}</h2>
                                    <p>{t("HOME_PAGE.TOKEN_DROP_REWARDING_LOYAL_PLAYERS_INCENTIVISING_GROWTH_AND_RETENTION_META_11_FANTASY_POWER_PLAY_INTRODUCED_WITH_IN_GAME_PURCHASES_OF_PLAYER_CARDS")}</p>
                                 </div>
                              </div>
                              <div className={classNames(styles.container, styles.left)}>
                                 <div className={classNames(styles.Topborder)}></div>
                                 <div className={classNames(styles.content, styles.RightBorder)}>
                                    <img src={RM3} alt=""/>
                                    <h2>{t("HOME_PAGE.q4_2024")}</h2>
                                    <p>{t("HOME_PAGE.AVATAR_OWNERSHIP_ON_CHAIN_CREATE_OWN_TRAIN_COMPETE_INTRODUCING_THE_ULTIMATE_GAME_OF_SKILL_AND_STRATEGY_AVATAR_TRAINING_GROUND_SEASON_PASS_ACCESSORIES_AND_MORE")}</p>
                                 </div>
                              </div>
                              <div className={classNames(styles.container, styles.right)}>
                                 <div className={classNames(styles.Topborder)}></div>
                                 <div className={classNames(styles.content, styles.LeftBorder)}>
                                    <img src={RM4} alt=""/>
                                    <h2>{t("HOME_PAGE.Q1_2025")}</h2>
                                    <p>{t("HOME_PAGE.INTRODUCING_THE_NEXT_SPORT_STARTING_WITH_MMA_UFC_THEN_BASEBALL_SOCCER_GOLF_ETC_BUILDING_OUT_THE_SPORTS_OMNIVERSE_ONE_SPORT_AT_A_TIME")}</p>
                                 </div>
                              </div>
                              <div className={classNames(styles.container, styles.left)}>
                                 <div className={classNames(styles.Topborder)}></div>
                                 <div className={classNames(styles.content, styles.RightBorder)}>
                                    <img src={RM5} alt=""/>
                                    <h2>{t("HOME_PAGE.Q3_2025")}</h2>
                                    <p>{t("HOME_PAGE.UTILITY_MORE_ACCESSORY_SALES_RARE_AVATARS_UNLOCKS_DROPS_PEER_TO_PEER_MARKETPLACE_UGC_TOURNAMENTS_MATCHES_AND_GAME_MODES_AVATAR_CLONING_LAB_PEER_TO_PEER_LEASING_TOKEN_STAKING_FEDERATION_DAO")}</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className={classNames(styles.ellipse, styles.ePinkFull)}></div>
               </div>

               <div ref={faqRef} className={classNames(styles.faqTitleWrapper, "row gx-0 relative-pos pt-5 p-md-0")}>
                  <div className="col-12">
                     <div className="container mt129 pe-0 pe-md-2">
                        <div className="row">
                           <div className="col-7 col-md-7 d-flex p-0"
                                style={{flexDirection: "column", alignItems: "start", justifyContent: "center",}}>
                              <text className={classNames("heading2 pt-4 gradient-color", "d-flex align-items-center")}
                                    style={{paddingLeft: 5}}>
                                 {t("HOME_PAGE.FREQUENTLY")}
                                 <br/> {t("HOME_PAGE.ASKED")} <br/> {t("HOME_PAGE.QUESTIONS")}
                              </text>
                           </div>
                           <div className="col-5 col-md-5 pe-0 pe-md-2 p-0">
                              <img src={"https://storage.googleapis.com/meta11/serviceplatform/FAQ-yellow.webp"}
                                   alt={""} className={classNames(styles.faqIMG, "pull-right w-100 p-0")}
                                   style={{maxWidth: "499px"}}/>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row gx-0 relative-pos mb-3 mb-md-0">
                  <div className="col-12" style={{position: "relative"}}>
                     <div className={classNames(styles.ellipse, styles.e37, styles.Faq)}></div>
                     <div className={classNames(styles.ellipse, styles.e39, styles.Faq)}></div>
                     <div className={classNames(styles.redManEllipsRed, styles.Faq)}></div>
                     <div className="container">
                        <div className="row">
                           <div
                              className={classNames(styles.faqListContainer, "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12")}>
                              <div className={classNames(styles.faqListWrapper)}
                                   onClick={() => setState({plus: !state.plus})}>
                                 <div className="faqHeading">
                                    <h1 className={classNames(styles.FaqFonts)} onClick={() => setPlus(!plus)}>
                                       <font>{t("HOME_PAGE.WHAT_IS_META_11")}</font>{" "}
                                       {plus ?
                                          <span onClick={() => setPlus(!plus)} className="openedFaq"><img src={plusSign}
                                                                                                          alt={""}/></span> :
                                          <span className="closedFaq" onClick={() => setPlus(!plus)}><img
                                             src={minusSign} alt={""}/></span>}
                                    </h1>
                                 </div>
                                 <div className="faqDetail"
                                      style={plus ? {display: "none"} : {display: "block"}}>
                                    <p className={classNames("body-text2 color-gray2 pb-3")}>
                                       {t("HOME_PAGE.META_11_IS_A_GROUNDBREAKING_CRICKET_GAME_PLAYED_IN_THE_DIGITAL_REALM_WHERE_EVERY_CRICKETER_IS_AN_AIDRIVEN_ATHLETE")}
                                    </p>
                                 </div>
                              </div>
                              <div className={classNames(styles.faqListWrapper)} onClick={() => setPlusOne(!plusOne)}>
                                 <div className="faqHeading">
                                    <h1 className={classNames(styles.FaqFonts)}>
                                       <font>{t("HOME_PAGE.WHAT_IS_BEHAVIOL")}</font>{" "}
                                       {plusOne ? <span
                                             onClick={() => setPlusOne(!plusOne)}
                                             className="openedFaq">
                                                        <img src={plusSign} alt={""}/>
                                                    </span> :
                                          <span className="closedFaq" onClick={() => setPlusOne(!plusOne)}><img
                                             src={minusSign} alt={""}/></span>}
                                    </h1>
                                 </div>
                                 <div className="faqDetail"
                                      style={plusOne ? {display: "none"} : {display: "block"}}>
                                    <p className={classNames("body-text2 color-gray2 pb-3")}>
                                       {t("HOME_PAGE.BEHAVIOL_IS_THE_INNOVATIVE_TECHNOLOGY_COMPANY_BEHIND_THE_DEVELOPMENT_OF_META_11_REVOLUTIONISING_THE_WAY_CRICKET_IS_PLAYED_AND_EXPERIENCED")}
                                    </p>
                                 </div>
                              </div>
                              <div className={classNames(styles.faqListWrapper)} onClick={() => setPlusTwo(!plusTwo)}>
                                 <div className="faqHeading">
                                    <h1 className={classNames(styles.FaqFonts)}>
                                       <font>{t("HOME_PAGE.WHAT_ARE_META_11_GAMES")}</font>
                                       {plusTwo ? <span
                                          onClick={() => setPlusTwo(!plusTwo)}
                                          className="openedFaq">
                                                        <img alt={""} src={plusSign}/>
                                                    </span> : <span className="closedFaq"
                                                                    onClick={() => setPlusTwo(!plusTwo)}><img
                                          src={minusSign} alt={""}/></span>}
                                    </h1>
                                 </div>
                                 <div className="faqDetail"
                                      style={plusTwo ? {display: "none"} : {display: "block"}}>
                                    <p className={classNames("body-text2 color-gray2 pb-3")}>
                                       {t("HOME_PAGE.META_11_STAYS_TRUE_TO_ELEMENTS_OF_TRADITIONAL_CRICKET_HOWEVER_META_11_HAS_BEEN_CUSTOMISED_TO_BE_COMPLETED_WITHIN_10_MINUTES")}
                                       {t("HOME_PAGE.EACH_TEAM_HAS_3_AI_POWERED_ALLROUNDERS_AND_THE_TEAM_THAT_SCORES_THE_MOST_RUNS_WINS_JUST_LIKE_REAL_PLAYERS_AI_CRICKETERS_IMPROVE_ORGANICALLY_THE_MORE_THEY_PLAY")}
                                    </p>
                                 </div>
                              </div>
                              <div className={classNames(styles.faqListWrapper)}
                                   onClick={() => setPlusThree(!plusThree)}>
                                 <div className="faqHeading">
                                    <h1 className={classNames(styles.FaqFonts)}>
                                       <font>{t("HOME_PAGE.WHAT_IS_META_11_FANTASY")}</font>
                                       {plusThree ? <span
                                          onClick={() => setPlusThree(!plusThree)}
                                          className="openedFaq">
                                                        <img src={plusSign} alt={""}/>
                                                    </span> : <span className="closedFaq"
                                                                    onClick={() => setPlusThree(!plusThree)}><img
                                          src={minusSign} alt={""}/></span>}
                                    </h1>
                                 </div>
                                 <div className="faqDetail"
                                      style={plusThree ? {display: "none"} : {display: "block"}}>
                                    <p className={classNames("body-text2 color-gray2 pb-3")}>
                                       {t("HOME_PAGE.FANTASY_GAMES_TYPICALLY_INVOLVE_SELECTING_VIRTUAL_TEAMS_OF_REAL_PLAYERS_IN_META_11_FANTASY_CHOOSE_VIRTUAL_TEAMS_OF_VIRTUAL_AI_PLAYERS_INCLUDING_A_CAPTAIN_AND_VICE_CAPTAIN_WIN_XP_POINTS_DAILY_THAT_UNLOCK_SPRTS_OUR_IN_GAME_UTILITY_TOKEN")}</p>
                                    <p className={classNames("body-text2 color-gray2 pb-3")}>
                                       {t("HOME_PAGE.FANTASY_TEAMS_HAVE_TO_BE_SELECTED_DAILY")}
                                    </p>
                                    <p className={classNames("body-text2 color-gray2 pb-3")}>
                                       {t("HOME_PAGE.POINTS_CAN_BE_EARNED_FROM_FANTASY_GAMES_AS_FOLLOWS_AND_ARE_UPDATED_IN_GAME")}
                                    </p>
                                    <FantasyPointsTable/>
                                 </div>
                              </div>
                              <div className={classNames(styles.faqListWrapper)} onClick={() => setPlusFour(!plusFour)}>
                                 <div className="faqHeading">
                                    <h1 className={classNames(styles.FaqFonts)}>
                                       <font>{t("HOME_PAGE.WHAT_IS_$SPRTS")}</font>
                                       {plusFour ? <span
                                          onClick={() => setPlusFour(!plusFour)}
                                          className="openedFaq">
                                                        <img src={plusSign} alt={""}/>
                                                    </span> : <span className="closedFaq"
                                                                    onClick={() => setPlusFour(!plusFour)}><img
                                          src={minusSign} alt={""}/></span>}
                                    </h1>
                                 </div>
                                 <div className="faqDetail"
                                      style={plusFour ? {display: "none"} : {display: "block"}}>
                                    <p className={classNames("body-text2 color-gray2 pb-3")}>
                                       {t("HOME_PAGE.THE_$SPRTS_TOKEN_IS_THE_IN_GAME_CURRENCY_FOR_META_11_AND_SUBSEQUENT_AI_POWERED_SPORTING_GAMES_CREATED_BY_BEHAVIOL_$SPRTS_TOKEN_HAS_BEEN_CREATED_BY_SPORTS_LAB_LTD_AND_WILL_BE_RELEASED_IN_Q_3_2024")}
                                    </p>
                                 </div>
                              </div>
                              <div className={classNames(styles.faqListWrapper)}
                                   onClick={() => setPlusSeven(!plusSeven)}>
                                 <div className="faqHeading">
                                    <h1 className={classNames(styles.FaqFonts)}>
                                       <font>{t("HOME_PAGE.CAN_I_MAKE_MONEY_FROM_$SPRTS")}</font>
                                       {plusSeven ? <span
                                          onClick={() => setPlusSeven(!plusSeven)}
                                          className="openedFaq">
                                                        <img src={plusSign} alt={""}/>
                                                    </span> : <span className="closedFaq"
                                                                    onClick={() => setPlusSeven(!plusSeven)}><img
                                          src={minusSign} alt={""}/></span>}
                                    </h1>
                                 </div>
                                 <div className="faqDetail"
                                      style={plusSeven ? {display: "none"} : {display: "block"}}>
                                    <p className={classNames("body-text2 color-gray2 pb-3")}>
                                       {t("HOME_PAGE.IN_2024_YOU_WILL_BE_ABLE_TO_WITHDRAW_$SPRTS_TOKENS_VIA_THIRD_PARTY_PLATFORMS_AND_CASH_OUT")}
                                    </p>
                                 </div>
                              </div>
                              <div className={classNames(styles.faqListWrapper)} onClick={() => setPlusFive(!plusFive)}>
                                 <div className="faqHeading">
                                    <h1 className={classNames(styles.FaqFonts)}>
                                       <font>{t("HOME_PAGE.HOW_DO_I_EARN_$SPRTS_IN_META_11_NOW")}</font>
                                       {plusFive ? <span
                                          onClick={() => setPlusFive(!plusFive)}
                                          className="openedFaq">
                                                        <img src={plusSign} alt={""}/>
                                                    </span> : <span className="closedFaq"
                                                                    onClick={() => setPlusFour(!plusFive)}><img
                                          src={minusSign} alt={""}/></span>}
                                    </h1>
                                 </div>
                                 <div className="faqDetail"
                                      style={plusFive ? {display: "none"} : {display: "block"}}>
                                    <p className={classNames("body-text2 color-gray2 pb-3")}>
                                       {t("HOME_PAGE.IN_STANDARD_GAMES_USERS_CAN_PLAY_META_11_FOR_FREE_AND_EARN_XP_POINTS_WHICH_CONVERTS_TO_$SPRTS")}
                                    </p>
                                 </div>
                              </div>
                              <div className={classNames(styles.faqListWrapper)} onClick={() => setPlusSix(!plusSix)}>
                                 <div className="faqHeading">
                                    <h1 className={classNames(styles.FaqFonts)}>
                                       <font>{t("HOME_PAGE.WHAT_CAN_I_DO_WITH_$SPRTS_IN_THE_FUTURE")}</font>
                                       {plusSix ? <span
                                          onClick={() => setPlusSix(plusSix)}
                                          className="openedFaq">
                                                        <img src={plusSign} alt={""}/>
                                                    </span> : <span className="closedFaq"
                                                                    onClick={() => setPlusSix(!plusSix)}><img
                                          src={minusSign} alt={""}/></span>}
                                    </h1>
                                 </div>
                                 <div className="faqDetail"
                                      style={plusSix ? {display: "none"} : {display: "block"}}>
                                    <p className={classNames("body-text2 color-gray2 pb-3")}>
                                       {t("HOME_PAGE.CONVERT_$SPRTS_TO_INGAME_PLAYER_CARDS_WHICH_GIVES_YOU_ACCESS_TO_POWER_PLAY_GAMES_THE_XP_POINTS_ON_OFFER_WILL_BE_MAGNIFIED_WHICH_WILL_CONVERT_TO_MORE_$SPRTS")}
                                    </p>
                                    <p className={classNames("body-text2 color-gray2 pb-3")}>
                                       {t("HOME_PAGE.STAKE_$SPRTS_FOR_GREATER_REWARDS")}
                                    </p>
                                    <p className={classNames("body-text2 color-gray2 pb-3")}>
                                       {t("HOME_PAGE.$SPRTS_CAN_BE_EXCHANGED_TO_OWN_TRAIN_MANAGE_AND_UPGRADE_DIGITAL_CRICKETERS_FORM_TEAMS_WITH_FRIENDS_AND_CHALLENGE_OTHERS_TO_CONTESTS_AND_MUCH_MORE")}
                                       {t("HOME_PAGE.STAKE_$SPRTS_FOR_GREATER_REWARDS")}
                                       {t("HOME_PAGE.SEE_OUR")}
                                       <a href="https://behaviol.gitbook.io/meta11/" target="_blank" style={{
                                          textDecoration: 'none',
                                          color: 'white'
                                       }}> {t("HOME_PAGE.WHITE_PAPER")} </a>
                                       {t("HOME_PAGE.FOR_FURTHER_DETAILS")}
                                       {t("HOME_PAGE.$SPRTS_AND_ASSETS_PURCHASED_IN_META_11_CAN_BE_USED_IN_FUTURE_GAMES_WITHIN_THE_BEHAVIOL_SPORTS_UNIVERSE")}
                                    </p>
                                 </div>
                              </div>


                              <div className={classNames(styles.faqListWrapper)}
                                   onClick={() => setState({plus: !state.plus})}>
                                 <div className="faqHeading">
                                    <h1 className={classNames(styles.FaqFonts)} onClick={() => setPlus(!plus)}>
                                       <font>{t("HOME_PAGE.WHERE_IS_THE_WHITEPAPER")}</font>{" "}
                                       {plus ?
                                          <span onClick={() => setPlus(!plus)} className="openedFaq"><img src={plusSign}
                                                                                                          alt={""}/></span> :
                                          <span className="closedFaq" onClick={() => setPlus(!plus)}><img
                                             src={minusSign} alt={""}/></span>}
                                    </h1>
                                 </div>
                                 <div className="faqDetail"
                                      style={plus ? {display: "none"} : {display: "block"}}>
                                    <p className={classNames("body-text2 color-gray2 pb-3")}>
                                       {t("HOME_PAGE.FIND_THE_META_11_WHITEPAPER")}
                                       <a href="https://behaviol.gitbook.io/meta11"
                                          target="_blank" style={{
                                          textDecoration: 'none',
                                          color: 'white'
                                       }}> {t("HOME_PAGE.HERE")} </a>
                                    </p>
                                 </div>
                              </div>

                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               {/*<div className={classNames(styles.JoinComunityRowWrapper)}>*/}
               {/*   <div className="row gx-0 relative-pos pt-0 pt-md-5 p-md-0">*/}
               {/*      <div className="col-12" style={{position: "relative"}}>*/}
               {/*         <div className={classNames(styles.ellipse, styles.e28)}*/}
               {/*              style={{width: "55vw", top: -135, opacity: 0.4}}></div>*/}
               {/*         <div className="container">*/}

               {/*            <div className={classNames(styles.joinComunitySection)}*/}
               {/*                 style={{textAlign: "center", position: "relative"}}>*/}
               {/*               <span className={classNames(styles.leftShock)}></span>*/}
               {/*               <span className={classNames(styles.rightShock)}></span>*/}
               {/*               /!*<img src={GradientTopBorder} />*!/*/}
               {/*               <h1*/}
               {/*                  className={classNames("heading2 pt-4 gradient-color", "mb-4")}>{t("HOME_PAGE.CLAIM_YOUR_EARLY_ACCESS!")}</h1>*/}
               {/*               <p className={classNames("body-text1 mb-4")} style={{color: "#95A6B7"}}>*/}
               {/*                  {" "}*/}
               {/*                  {t("HOME_PAGE.BE_THE_FIRST_TO_CONQUER_META_11'S_PAY_2_PLAY, WHERE_VICTORY_BRINGS_REWARDS. START_YOUR_CHAMPION'S_JOURNEY_TODAY")}</p>*/}
               {/*               <div className={classNames(styles.BtnRowWrapper)}>*/}
               {/*                  <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 ms-auto me-auto row">*/}

               {/*                     <div*/}
               {/*                        className={classNames(styles.txtWrap, "col-12 col-sm-6 col-lg-8 col-xl-8 ps-0 ps-md-4 pe-0 sp-sm-2 pe-sm-2  col-md-7 mb-2 mb-md-0")}>*/}
               {/*                        /!*<Form.Control type="text" placeholder="Readonly input here..."/>*!/*/}
               {/*                        <input value={email}*/}
               {/*                               onChange={handleEmailChange} type="text" name="name"*/}
               {/*                               placeholder={t("HOME_PAGE.EMAIL_ADDRESS")}*/}
               {/*                               className={classNames(styles.inputHome)}/>*/}
               {/*                     </div>*/}
               {/*                     <div*/}
               {/*                        className={classNames(styles.btnWrap, "col-12 col-xl-4 col-lg-4 col-md-5 col-sm-6 ms-auto me-auto p-0")}>*/}
               {/*                        <button className="lightGreenButton" style={{width: "100%"}}*/}
               {/*                                onClick={subscribeEmail}*/}
               {/*                                disabled={email && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) !== null ? false : true}>{t("HOME_PAGE.SUBSCRIBE_NOW")}</button>*/}
               {/*                     </div>*/}
               {/*                  </div>*/}
               {/*               </div>*/}
               {/*            </div>*/}
               {/*         </div>*/}
               {/*      </div>*/}
               {/*   </div>*/}
               {/*   <div className={classNames(styles.ellipse, styles.eMulticolorFull)}></div>*/}
               {/*</div>*/}
               <Footer/>
            </>}
         </div>

         {isSequenceLoginModalShow ? <SequenceLoginModal handleClose={handleSequenceLoginModalClose}/>
            : <></>
         }
         {showHomePageGuidancePopUp ?
            <HomePageGuidancePopUp closeModal={handleHomePageGuidancePopUpClose} instructionStep={instructionStep}/>
            : <></>
         }

         {/*use this modal to style */}
         {/*{this.state.showSignupModal && !this.props.isAuthenticated && !localStorage.getItem("previouslyLogged") ?*/}
         {/*  <div className={classnames("modal fade show", Styles.modalBlur)} id="exampleModalCenter"*/}
         {/*       tabIndex="-1" role="dialog"*/}
         {/*       aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{display: "block"}}>*/}
         {/*    <div className="modal-dialog modal-dialog-centered" role="document">*/}
         {/*      <div className={classnames("modal-content", Styles.modalContentCustom, "p-0")} style={{position:"relative"}}>*/}
         {/*        <a className={classNames(styles.closeBtn)}><BsXLg onClick={() => this.handleModalClose()}/></a>*/}
         {/*        <div className="row g-0 m-0 Sign-up-to-secure">*/}
         {/*          <div className="col-12 col-sm-6">*/}
         {/*              <div className={classNames(styles.popPicWrapper)}>*/}
         {/*                  <img src={popupPicHome}/>*/}
         {/*              </div>*/}
         {/*          </div>*/}
         {/*          <div className="col-12 col-sm-6 p-3 d-flex text-center flex-d flex-column justify-content-center">*/}
         {/*              <h1 className={classNames(styles.mainHeaderH1, styles.text20, "text-center")}>*/}
         {/*              Sign up to secure your place in META11*/}
         {/*              </h1>*/}
         {/*              <p className={classNames(styles.gray12, "text-center")}>Free to Play and Win Cricket Tournament</p>*/}
         {/*              <h1 className={classNames(styles.whiteHearderText, "text-center ")}>*/}
         {/*              Coming 26th September 2023, 12pm IST*/}
         {/*              </h1>*/}
         {/*              <div className="col-12 p-0 text-center">*/}
         {/*                  {this.props.isAuthenticated ? <Link href="" className="lightGreenButton popBtn mt-3" to={"/matches"}>Play Now</Link> :*/}
         {/*              <Link href="" className="lightGreenButton popBtn mt-3" to={"/register"}>Join Us</Link>*/}
         {/*                }*/}
         {/*              </div>*/}
         {/*          </div>*/}
         {/*      </div>*/}
         {/*      </div>*/}
         {/*    </div>*/}
         {/*  </div> : <></>}*/}


         {isPurchaseModalOpen ?
            <PaymentConfirmModal handleClose={closeModal} tournamentDetails={tournamentDetails}/> : <></>
         }

      </div>

   );

}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      refresh: state.auth.refreshToken,
      Token: state.auth.accessToken,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      onLogout: () => dispatch(actions.logout()),
      onRefreshAuth: (accessToken, refreshToken) => dispatch(actions.refreshSession(accessToken, refreshToken)),
   };
};
export default connect(mapStateToProps, mapDispatchToProps)(HomeV2)
