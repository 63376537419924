import React, {useEffect, useState} from "react";
import styles from "./Matches.module.scss";
import classNames from "classnames";
import {FaSearch} from "react-icons/fa";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Paginator from "../../components/Paginator/Paginator";
import {MATCH_CARD_TYPES, SOCKET_MESSAGES} from "../../utilities/Constants";
import * as StatisticsService from "../../services/StatisticsService";
import {Link, useLocation} from "react-router-dom";
import {formatDate, formatTime} from "../../helpers/CommonFunctions";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {getConf} from "../../services/ConfigService";
import {MATCH_PAGE} from "../../utilities/ConfigConstants";
import PubNubMatchesHandler from "./PubNubMatchesHandler";
import * as PubNubService from "../../services/PubNubService";
import {useTranslation} from "react-i18next";
import shareIcon from "../../assets/images/icons/share_icon.svg";
import SocialShareModal from "../Modals/SocialShareModal/SocialShareModal";
import GuidanceTip from "../../components/GuidanceTip/GuidanceTip";

const TournamentMatches = (props) => {
   const location = useLocation();

   const [loadingInProgress, setLoading] = useState(true);
   const [currentPage, setCurrentPage] = useState(parseInt(new URLSearchParams(location.state).get('currentPage')) || 0);
   const [postsPerPage, setPostsPerPage] = useState(9);
   const [totalElements, setTotalElements] = useState(-1);
   const [liveMs, setliveMs] = useState([]);
   const [completedMs, setcompletedMs] = useState([]);
   const [scheduledMs, setScheduledMs] = useState([]);
   const [connectPubnub, setConnectPubnub] = useState(false);
   const [token, setToken] = useState(null);
   const [isOpenSocialShareModal, setIsOpenSocialShareModal] = useState(false)
   const [selectedRadioBtnValue, setSelectedRadioBtnValue] = useState(new URLSearchParams(location.search).get('type') || 'all');
   const [searchQuery, setSearchQuery] = useState('');
   const [subscribeChannel, setSubscribeChannel] = useState(null);
   const [pushSubscriptionReqState, setPushSubscriptionReqState] = useState(
      {
         type: MATCH_CARD_TYPES.ALL,
         subscriptionTypes: [SOCKET_MESSAGES.MATCH_CARD_DETAILS]
      });
   const [unSubscribeChannel, setUnsubscribeChannel] = useState(null);
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);
   const [lang, setLang] = useState(localStorage.getItem("language"));


   let uuid = localStorage.getItem("uuid");
   const [socialShareUrl, setSocialShareUrl] = useState(null);
   const [nextGotItIndex, setNextGotItIndex] = useState(0)
   const [tipIndexCounter, setTipIndexCounter] = useState(1)

   const gotItHandler = () => {
      setNextGotItIndex(nextGotItIndex + 1)
      setTipIndexCounter(tipIndexCounter + 1)
   }
   const tipCancelHandler = () => {
      setNextGotItIndex(-1)
   }

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 1000);
   }, []);

   useEffect(() => {
      const urlParams = new URLSearchParams(location.search);
      const typeParam = urlParams.get('type');
      if (typeParam) {
         setSelectedRadioBtnValue(typeParam);
      }
   }, [location.search]);

   useEffect(() => {

      const fetchData = async () => {
         console.log("run1");
         if (localStorage.getItem("pubnubToken") == null) {
            console.log("run");
            try {
               const response = await PubNubService.requestAccessTokenMatches(uuid, props.Token).then(response => {
                  if (response != null) {
                     setToken(response);
                     setConnectPubnub(true);
                     console.log(response);
                     localStorage.setItem("pubnubToken", response);
                  }
               })
               // pubnub.setAuthKey(response); // Update PubNub authKey
            } catch (error) {
               console.error("Error:", error);
            }
         } else {
            setConnectPubnub(true);
            setToken(localStorage.getItem("pubnubToken"));
            // setToken(localStorage.getItem("pubnubToken"));
            // pubnub.setAuthKey(localStorage.getItem("pubnubToken")); // Set the token from local storage
         }
      };
      fetchData();
   }, [uuid]);
   // let socket;
   let liveMatches = [];
   let completedMatches = [];
   let scheduledMatches = [];
   let SHOW_MATCH_ADVERTISEMENT_URL = getConf(MATCH_PAGE.SHOW_MATCH_ADVERTISEMENT_URL);


   useEffect(() => {
      if (selectedRadioBtnValue == MATCH_CARD_TYPES.ALL && currentPage == 0) {
         setPushSubscriptionReqState({
            type: MATCH_CARD_TYPES.ALL,
            subscriptionTypes: [SOCKET_MESSAGES.MATCH_CARD_DETAILS],
         });
         setSubscribeChannel("Matches-" + props.tournamentId);
         setUnsubscribeChannel("Live-" + props.tournamentId);
      } else if (selectedRadioBtnValue == MATCH_CARD_TYPES.LIVE && currentPage == 0) {
         setPushSubscriptionReqState({
            type: MATCH_CARD_TYPES.LIVE,
            subscriptionTypes: [SOCKET_MESSAGES.MATCH_CARD_DETAILS],
         });
         setSubscribeChannel("Live-" + props.tournamentId);
         setUnsubscribeChannel("Matches-" + props.tournamentId);
      } else {
         setSubscribeChannel("null");
         setUnsubscribeChannel("Matches-" + props.tournamentId);
         setUnsubscribeChannel("Live-" + props.tournamentId);
      }

      const fetchData = async () => {
         if (currentPage != 0 || selectedRadioBtnValue != MATCH_CARD_TYPES.LIVE || selectedRadioBtnValue != MATCH_CARD_TYPES.ALL) {
            setUnsubscribeChannel("Matches-" + props.tournamentId);
            setUnsubscribeChannel("Live-" + props.tournamentId);
         }
         setLoading(true);
         await StatisticsService.getAllMatchCardsByTournamentId(searchQuery, currentPage, postsPerPage, "isLive,isScheduled,matchId,desc", selectedRadioBtnValue, props.tournamentId, lang, props.Token)
            .then((response) => {
               if (response.apiStatus && response.totalElements != 0) {
                  setTotalElements(response.totalElements);
                  filterMatches(response);
                  window.scrollTo({top: 0, behavior: 'smooth'});
                  setLoading(false);
               } else if (response.totalElements == 0) {
                  setTotalElements(0);
                  setLoading(false);
               }
            });
      };
      fetchData();
   }, [selectedRadioBtnValue, searchQuery, currentPage]);

   // useEffect(() => {
   //     if (socket && currentPage == 0 && searchQuery == '') {
   //         if (selectedRadioBtnValue == MATCH_CARD_TYPES.ALL || selectedRadioBtnValue == MATCH_CARD_TYPES.LIVE) {
   //             socket.addEventListener("open", () => {
   //                 console.log("WebSocket connection is open: Matches Page");
   //                 socket.send(JSON.stringify(pushSubscriptionReqState));
   //                 setLoading(false);
   //             });
   //
   //             socket.onmessage = (event) => {
   //                 const data = JSON.parse(event.data);
   //                 if (data.bodyType == SOCKET_MESSAGES.MATCH_CARD_DETAILS) {
   //                     filterMatches(data.body);
   //                 } else {
   //                     setLoading(false);
   //                 }
   //             };
   //             socket.addEventListener("close", () => {
   //                 console.log("WebSocket connection is closed: Matches Page");
   //             });
   //         }
   //     } else if (currentPage != 0) {
   //         if (selectedRadioBtnValue != MATCH_CARD_TYPES.ALL || selectedRadioBtnValue != MATCH_CARD_TYPES.LIVE) {
   //             closeSocket();
   //         }
   //     }
   // }, [pushSubscriptionReqState]);

   const paginate = (pageNumber, postsPerPage) => {
      setPostsPerPage(Number(postsPerPage));
      setCurrentPage(pageNumber);
   };


   const filterMatches = (response) => {
      if (response.matches) {
         if (response?.matches?.length > 0) {
            setliveMs(response?.matches?.filter((match) => match.live === true && match.toStart === false));
            setScheduledMs(response?.matches?.filter((match) => match.live === false && match.toStart === true));
            setcompletedMs(response?.matches?.filter((match) => match.live === false && match.toStart === false));
         }
      }
   };

   // const closeSocket = () => {
   //     if (socketState) {
   //         console.log("Closing WebSocket connection: Matches Page");
   //         socketState.close();
   //     }
   // };

   const handleRadioBtnValue = (event) => {
      setCurrentPage(0)
      setSelectedRadioBtnValue(event.target.value);
   };

   const handleSearchChange = (event) => {
      setCurrentPage(0)
      setSearchQuery(event.target.value)
   };

   function getHighlightStylesCompleted(matchObj, isCompletedMatch) {
      if (matchObj?.winnerId == matchObj?.team1Id) {
         return "scoreLbl";
      } else if (matchObj?.winnerId != matchObj?.team1Id) {
         return isCompletedMatch ? "scoreLbl emptyCompleted" : "scoreLbl empty";
      } else if (!matchObj?.winnerId) {
         return isCompletedMatch ? "scoreLbl emptyCompleted" : "scoreLbl empty";
      }
   }

   function getHighlightStylesCompleted2(matchObj, isCompletedMatch) {
      if (matchObj?.winnerId == matchObj?.team2Id) {
         return "scoreLbl";
      } else if (matchObj?.winnerId != matchObj?.team2Id) {
         return isCompletedMatch ? "scoreLbl emptyCompleted" : "scoreLbl empty";
      } else if (!matchObj?.winnerId) {
         return isCompletedMatch ? "scoreLbl emptyCompleted" : "scoreLbl empty";
      }
   }

   function getHighlightStylesLive(matchObj) {
      return matchObj?.battingTeamId == matchObj?.team1Id ? "scoreLbl" : "scoreLbl empty";
   }

   function getHighlightStylesLive2(matchObj) {
      return matchObj?.battingTeamId == matchObj?.team2Id ? "scoreLbl" : "scoreLbl empty";
   }

   const overCount = (teamOvers, teamBalls) => {

      const overs = teamBalls !== 6
         ? teamOvers - 1
         : teamOvers

      const balls = teamBalls !== 6
         ? teamBalls
         : 0
      return overs + "." + balls;

   }

   const getProcessedMessage = (str) => {
      if (!str) return '';
      if (str.length <= 17) return str;

      const words = str.split(' ');
      const lastWord = words.pop();

      const remainingLength = 17 - lastWord.length - 4;

      let remainingMessage = '';

      for (let word of words) {
         if (remainingMessage.length + word.length + 1 <= remainingLength) {
            remainingMessage += (remainingMessage ? ' ' : '') + word;
         } else {
            let availableLength = remainingLength - remainingMessage.length;
            if (availableLength > 0) {
               remainingMessage += (remainingMessage ? ' ' : '') + word.slice(0, availableLength);
            }
            break;
         }
      }

      return `${remainingMessage.trim()}... ${lastWord}`.trim();
   };
   const socialShareModalHandler = (value, matchId) => {
      setIsOpenSocialShareModal(value)
      setSocialShareUrl(`/match/${matchId}`);
   }
   const liveMatchCard = (matchObj) => (
      <div className={classNames(styles.matchCard, styles.LiveMatchCard, "col-12 col-sm-6 col-md-6 col-lg-4 ps-sm-2 pe-sm-2 ps-lg-0 pe-lg-4")} key={matchObj.matchId}>
         <Link to={{
            pathname: `/match/${matchObj.matchId}`,
            search: `returnUrl=${encodeURIComponent(location.pathname)}&currentPage=${currentPage}&type=${selectedRadioBtnValue}`
         }} className="matchCardInnerWrapper pt-1 pb-2" style={{textDecoration: "none"}}>
            <div className={classNames(styles.matchInfoHeaderContainer, "matchInfoHeader")}>
               <div className={classNames(styles.row1, styles.ddd)}>
                  {/* <span className="matchStatslbl liveLblPink">
                            <span className="livedot blinking"></span>
                           {t("MATCHES.PLAY_NOW")}aa
                        </span> */}
                  <div className="TournamentMatchesLiveTag">
                     <div className={classNames(styles.PlayNowLiveTagContainer, "PlayNowLiveTagContainer")}>
                        <div className={classNames(styles.PlayNowLiveTagWrapper)}></div>
                        <span className={classNames(styles.PlayNowLiveTag, "PlayNowLiveTag matchStatslbl-")}>
                              <span className={classNames(styles.BlinkingDot, "BlinkingDot livedot blinking")}></span>
                              <div className={classNames(styles.LiveTag)}>{t("MATCHES.LIVE_NOW")}</div>
                           </span>
                     </div>
                  </div>
                  <div className={classNames(styles.shareBtnWrapper)} onClick={() => {
                     socialShareModalHandler(true, matchObj.matchId)
                  }}>
                     <Link to={'/matches'} onClick={e => e.preventDefault()}>
                        <img src={shareIcon} alt={""}/>
                     </Link>
                  </div>
               </div>
               <div className={classNames(styles.row2)}>
                        <span className="matchDate">
                            <label className="mtDate">{matchObj?.matchStartTime ? formatDate(matchObj?.matchStartTime) : '--'}</label>
                            <label className="mtTime">
                                {matchObj?.matchStartTime ? t("MATCHES.STARTED_AT") + formatTime(matchObj?.matchStartTime) : '--'}</label>
                        </span>
               </div>
               {/* <span className="matchStatslbl liveLblPink">
                        <span className="livedot blinking"></span>
                        {t("matches.live")}
                    </span> */}
               {/* <span className="matchDate">
                        <label className="mtDate">{matchObj?.matchStartTime ? formatDate(matchObj?.matchStartTime) : '--'}</label>
                        <label className="mtTime">
                            {matchObj?.matchStartTime ? 'Started at ' + formatTime(matchObj?.matchStartTime) : '--'}</label>
                    </span> */}
               {/* <div className={classNames(styles.shareBtnWrapper)}>
                        <img src={shareIcon} />
                    </div> */}
            </div>
            <div className="teamDetail">
                    <span className="teamLogo">
                        <Link to={{
                           pathname: `/team/${matchObj?.team1Id}`,
                           search: `returnUrl=${encodeURIComponent(location.pathname)}`
                        }}>
                            <img src={matchObj?.team1PicURL} alt=""/>
                        </Link>
                    </span>
               {/*style={{color: playerHistory[0]?.winningTeam.includes(playerHistory[0]?.team?.name) ? "#17EA85" : "#95A6B7"}}*/}
               <span className="teamName">{matchObj?.team1Name ? matchObj?.team1Name : "TBA"}</span>
               <span className={classNames(styles.teamScoreWrapper, "teamScore")}>{matchObj?.team1Runs != null ? (
                     <label className={getHighlightStylesLive(matchObj)}>{matchObj?.team1Runs}/{matchObj?.team1Wickets}</label>) :
                  (<label className={getHighlightStylesLive(matchObj)}>0/0</label>)}
                  {matchObj?.team1Overs || matchObj?.team1Balls ? (
                     <label
                        className="oversLbl">({overCount(matchObj?.team1Overs, matchObj?.team1Balls)})</label>
                  ) : (
                     <label className="oversLbl empty">(0.0)</label>
                  )}
                    </span>
            </div>
            <div className="teamDetail">
                    <span className="teamLogo">
                        <Link to={{
                           pathname: `/team/${matchObj.team2Id}`,
                           search: `returnUrl=${encodeURIComponent(location.pathname)}`
                        }}>
                            <img src={matchObj?.team2PicURL} alt=""/>
                        </Link>
                    </span>
               <span className="teamName">{matchObj?.team2Name ? matchObj?.team2Name : "TBA"}</span>
               <span className={classNames(styles.teamScoreWrapper, "teamScore")}>{matchObj?.team2Runs != null ? (
                  <label className={getHighlightStylesLive2(matchObj)}>{matchObj?.team2Runs}/{matchObj?.team2Wickets}</label>
               ) : (<label className={getHighlightStylesLive2(matchObj)}>0/0</label>)}
                  {matchObj?.team2Overs || matchObj?.team2Balls ? (
                     <label className="oversLbl">({overCount(matchObj?.team2Overs, matchObj?.team2Balls)})</label>
                  ) : (<label className="oversLbl empty">(0.0)</label>)}
                    </span>
            </div>
         </Link>
      </div>
   );
   const scheduleMatchCard = (matchObj) => (
      <div className={classNames(styles.matchCard, "col-12 col-sm-6 col-md-6 col-lg-4 ps-sm-2 pe-sm-2 ps-lg-0 pe-lg-4")} key={matchObj.matchId}>
         <Link to={{
            pathname: `/match/${matchObj.matchId}`,
            search: `returnUrl=${encodeURIComponent(location.pathname)}&currentPage=${currentPage}&type=${selectedRadioBtnValue}`
         }} className="matchCardInnerWrapper pt-1 pb-2" style={{textDecoration: "none"}}>
            <div className={classNames(styles.matchInfoHeaderContainer, "matchInfoHeader")}>
               <div className={classNames(styles.row1)}>
                        <span className="matchStatslbl upcomeGreen">
                            <a href={`/match/${matchObj.matchId}`} style={{textDecoration: "none"}}>{t("MATCHES.UPCOMING")}</a>
                        </span>
                  <div className={classNames(styles.shareBtnWrapper)} onClick={() => {
                     socialShareModalHandler(true, matchObj.matchId)
                  }}>
                     <Link to={'/matches'} onClick={e => e.preventDefault()}>
                        <img src={shareIcon} alt={""}/>
                     </Link>
                  </div>

               </div>
               <div className={classNames(styles.row2)}>
                        <span className="matchDate">
                            <label className="mtDate">{matchObj?.matchStartTime ? formatDate(matchObj?.matchStartTime) : ''}</label>
                            <label className="mtTime">{matchObj?.matchStartTime ? t("MATCHES.STARTS_AT") + formatTime(matchObj?.matchStartTime) : formatDate(matchObj?.matchStartTime)}</label>
                        </span>
               </div>
               {/* <span className="matchStatslbl upcomeGreen">
                        <a href={`/match/${matchObj.matchId}`} style={{textDecoration: "none"}}>{t("matches.upcoming")}</a>
                    </span> */}
               {/* <span className="matchDate">
                        <label className="mtDate">{matchObj?.matchStartTime ? formatDate(matchObj?.matchStartTime) : ''}</label>
                        <label className="mtTime">{matchObj?.matchStartTime ? 'Starts at ' + formatTime(matchObj?.matchStartTime) : formatDate(matchObj?.matchStartTime)}</label>
                    </span> */}
               {/* <div className={classNames(styles.shareBtnWrapper)}>
                        <img src={shareIcon} />
                    </div> */}

            </div>
            <div className="teamDetail">
                    <span className="teamLogo">
                        <Link to={{
                           pathname: `/team/${matchObj.team1Id}`,
                           search: `returnUrl=${encodeURIComponent(location.pathname)}`
                        }}>
                            <img src={matchObj?.team1PicURL} alt=""/>
                        </Link>
                    </span>
               <span className="teamName">{matchObj?.team1Name ? matchObj?.team1Name : "TBA"}</span>
               <span className={classNames(styles.teamScoreWrapper, "teamScore")}>
                        {/* Removed Runs From Scheduled Matches */}
                  <label className="scoreLbl empty">0/0</label>
                        <label className="oversLbl empty">(0.0)</label>
                    </span>
            </div>
            <div className="teamDetail">
                    <span className="teamLogo">
                        <Link to={{
                           pathname: `/team/${matchObj.team2Id}`,
                           search: `returnUrl=${encodeURIComponent(location.pathname)}`
                        }}>
                            <img src={matchObj?.team2PicURL} alt=""/>
                        </Link>
                    </span>
               <span className="teamName">{matchObj?.team2Name ? matchObj?.team2Name : "TBA"}</span>
               <span className={classNames(styles.teamScoreWrapper, "teamScore")}>
                        <label className="scoreLbl empty">0/0</label>
                        <label className="oversLbl empty">(0.0)</label>
                    </span>
            </div>
         </Link>
      </div>
   );
   const completedMatchCard = (matchObj) => (
      <div className={classNames(styles.matchCard, "col-12 col-sm-6 col-md-6 col-lg-4 ps-sm-2 pe-sm-2 ps-lg-0 pe-lg-4")} key={matchObj.matchId}>
         <Link to={{
            pathname: `/match/${matchObj.matchId}`,
            search: `returnUrl=${encodeURIComponent(location.pathname)}&currentPage=${currentPage}&type=${selectedRadioBtnValue}`
         }} className="matchCardInnerWrapper pt-1 pb-2" style={{textDecoration: "none"}}>
            <div className={classNames(styles.matchInfoHeaderContainer, "matchInfoHeader")}>
               <div className={classNames(styles.row1)}>
                        <span className="matchStatslbl completedBlue" style={{textDecoration: "none"}}>
                            <a href={`/match/${matchObj.matchId}`} style={{textDecoration: "none"}}>{t("MATCHES.COMPLETED")}</a>
                        </span>

                  <div className={classNames(styles.shareBtnWrapper)} onClick={() => {
                     socialShareModalHandler(true, matchObj.matchId)
                  }}>
                     <Link to={'/matches'} onClick={e => e.preventDefault()}>
                        <img src={shareIcon} alt={""}/>
                     </Link>
                  </div>
               </div>
               <div className={classNames(styles.row2)}>
                        <span className={classNames(styles.matchDateWrapper, "matchDate")}>
                            <label className="mtDate">{matchObj?.message ? getProcessedMessage(matchObj.message) : formatDate(matchObj?.matchStartTime)}</label>
                            <label className="mtDate">
                                {matchObj?.matchEndTime ? formatDate(matchObj?.matchEndTime, true) + ", " : ''}
                               {matchObj?.matchEndTime ? formatTime(matchObj?.matchEndTime) : formatDate(matchObj?.matchEndTime)}
                            </label>
                        </span>
               </div>
               {/* <span className="matchStatslbl completedBlue" style={{textDecoration: "none"}}>
                        <a href={`/match/${matchObj.matchId}`} style={{textDecoration: "none"}}>{t("matches.completed")}</a>
                    </span> */}
               {/* <span className={classNames(styles.matchDateWrapper,"matchDate")}>
                        <label className="mtDate">{matchObj?.message ? matchObj?.message : formatDate(matchObj?.matchStartTime)}</label>
                        <label className="mtDate">
                            {matchObj?.matchEndTime ? formatDate(matchObj?.matchEndTime,true) + " ": ''}
                            {matchObj?.matchEndTime ? formatTime(matchObj?.matchEndTime) : formatDate(matchObj?.matchEndTime)}
                        </label>
                    </span> */}
               {/* <div className={classNames(styles.shareBtnWrapper)}>
                         <img src={shareIcon} />
                    </div> */}
            </div>
            <div className="teamDetail">
                    <span className="teamLogo">
                        <Link to={{
                           pathname: `/team/${matchObj.team1Id}`,
                           search: `returnUrl=${encodeURIComponent(location.pathname)}`
                        }}>
                            <img src={matchObj?.team1PicURL} alt=""/>
                        </Link>
                    </span>
               <span className="teamName">{matchObj?.team1Name ? matchObj?.team1Name : "TBA"}</span>
               <span className={classNames(styles.teamScoreWrapper, "teamScore")}>

                        {matchObj?.team1Runs != null ? (
                              <label className={getHighlightStylesCompleted(matchObj, true)}>{matchObj?.team1Runs}/{matchObj?.team1Wickets}</label>
                           )
                           : (
                              <label className={getHighlightStylesCompleted(matchObj, true)}>0/0</label>
                           )}
                  {matchObj?.team1Overs || matchObj?.team1Balls ? (
                     <label className="oversLbl">({overCount(matchObj?.team1Overs, matchObj?.team1Balls)})</label>
                  ) : (
                     <label className="oversLbl empty">(0.0)</label>
                  )}
                    </span>
            </div>
            <div className="teamDetail">
                    <span className="teamLogo">
                        <Link to={{
                           pathname: `/team/${matchObj.team2Id}`,
                           search: `returnUrl=${encodeURIComponent(location.pathname)}`
                        }}>
                            <img src={matchObj?.team2PicURL} alt=""/>
                        </Link>
                    </span>
               <span className="teamName">{matchObj?.team2Name ? matchObj?.team2Name : "TBA"}</span>
               <span className={classNames(styles.teamScoreWrapper, "teamScore")}>{matchObj?.team2Runs != null ? (
                  <label className={getHighlightStylesCompleted2(matchObj, true)}>{matchObj?.team2Runs}/{matchObj?.team2Wickets}</label>
               ) : (
                  <label className={getHighlightStylesCompleted2(matchObj, true)}>0/0</label>
               )}
                  {matchObj?.team2Overs || matchObj?.team2Balls ? (
                     <label className="oversLbl">({overCount(matchObj?.team2Overs, matchObj?.team2Balls)})</label>
                  ) : (
                     <label className="oversLbl empty">(0.0)</label>
                  )}
                    </span>
            </div>
         </Link>
      </div>
   );

   const loadingIndicator = (<div className="w-100 text-center">
      <div className={styles.loaderContainer}>
         <div className="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
         </div>
      </div>
   </div>);

   const renderAllMatches = () => {
      if (loadingInProgress) {
         return loadingIndicator;
      }
      return totalElements === 0 ? (
         <div className={styles.loaderContainer}>
            <p className="gray-text font18">
               {t("MATCHES.NO_MATCHES_FOUND")}
            </p>
         </div>
      ) : (
         <div className={classNames(styles.matchCardWrapper, "matchCards ps-0 ps-sm-0 ps-xl-0")}>
            {liveMs?.map((matchObj) => liveMatchCard(matchObj))}
            {scheduledMs?.map((matchObj) => scheduleMatchCard(matchObj))}
            {completedMs?.map((matchObj) => completedMatchCard(matchObj))}
         </div>
      )
   }
   const messageFromMatchesCallback = (message) => {
      // console.log("from matches callback", JSON.parse(message))

      try {
         let json = JSON.parse(message);
         console.log(json);

         if (currentPage == 0 && searchQuery == "") {

            if (selectedRadioBtnValue == MATCH_CARD_TYPES.ALL || selectedRadioBtnValue == MATCH_CARD_TYPES.LIVE) {

               if (json.bodyType == SOCKET_MESSAGES.MATCH_CARD_DETAILS) {
                  filterMatches(json.body);
               } else {
                  setLoading(false);
               }
            }
         } else if (currentPage != 0) {
            if (selectedRadioBtnValue != MATCH_CARD_TYPES.ALL || selectedRadioBtnValue != MATCH_CARD_TYPES.LIVE) {
               setUnsubscribeChannel("Matches-" + props.tournamentId);
               setUnsubscribeChannel("Live-" + props.tournamentId)
            }
         }

      } catch (error) {
         console.error("JSON parsing error:", error);
      }
   }
   return (
      <React.Fragment>
         {(connectPubnub && (token != null)) ?
            <PubNubMatchesHandler onRecieved={messageFromMatchesCallback} token={token} subscribe={subscribeChannel}
                                  onUnsubscribe={unSubscribeChannel} tournamentId={props.tournamentId}/> : <></>}

         <div className="Radial1"></div>
         <div className={classNames(styles.lightBolt)}></div>
         <div className={classNames(styles.ellipse50)}></div>

         <div className="pb-4">
            <div className={classNames(styles.TournamentMatchesPageWrapper, "col-12 row")}>
               <div className={classNames(styles.ResponceCont, "col-12", "row", "ps-2", "pe-2", "ps-xxl-0", "pe-xxl-0")}>
                  {/*<div className="col-12  p-0">*/}
                  {/*   <div className={classNames(styles.matchesProfileGuidanceTip, "matchesProfileGuidanceTip")} style={{height: '1px', width: '100%', display: 'block'}}>*/}
                  {/*      <GuidanceTip page="matches" gotItIndex={1} gotItHandler={gotItHandler} nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler} tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter} />*/}
                  {/*   </div>*/}
                  {/*   <NavbarSecondary page="matches"/>*/}
                  {/*</div>*/}
                  {!translationsLoaded ? <> </> : <>
                     <div className="row gx-0">

                        <div className="p-0 col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 selectWrapper" style={{display: "flex"}}>
                           <div className={classNames(styles.matchTypeWrapper, "fc-wrapper matches-radio-wrapper row mb-4 mb-sm-0")}>

                              <label className="radioButtonlbl order-sm-2 order-2 m-0 pe-2 col-md-auto col-6 p-0 mb-2 mb-sm-0">
                                 <input type="radio" name="radio" value='all' checked={selectedRadioBtnValue === MATCH_CARD_TYPES.ALL} onChange={handleRadioBtnValue}/>
                                 <span>{t("MATCHES.LIVE_NOW")}</span>
                              </label>
                              <label className="radioButtonlbl order-sm-2 order-2 m-0 pe-0 pe-sm-2 col-md-auto col-6 p-0 mb-2 mb-sm-0">
                                 <input type="radio" name="radio" value='live' checked={selectedRadioBtnValue === MATCH_CARD_TYPES.LIVE} onChange={handleRadioBtnValue}/>
                                 <span>{t("MATCHES.LIVE_2")}</span>
                              </label>
                              <label className="radioButtonlbl order-sm-2 order-2 m-0 pe-2 col-md-auto col-6 p-0 mb-2 mb-sm-0">
                                 <input type="radio" name="radio" value='upcoming' checked={selectedRadioBtnValue === MATCH_CARD_TYPES.UPCOMING} onChange={handleRadioBtnValue}/>
                                 <span>{t("MATCHES.UPCOMING_2")}</span>
                              </label>
                              <label className="radioButtonlbl order-sm-2 order-2 m-0 col-md-auto col-6 p-0 mb-2 mb-sm-0">
                                 <input type="radio" name="radio" value='completed' checked={selectedRadioBtnValue === MATCH_CARD_TYPES.COMPLETED} onChange={handleRadioBtnValue}/>
                                 <span>{t("MATCHES.COMPLETED")}</span>
                              </label>
                              <div className={classNames(styles.matchesMatchesGuidanceTip, "matchesMatchesGuidanceTip")}>
                                 <GuidanceTip page="matches" gotItIndex={2} gotItHandler={gotItHandler} nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler} tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}/>
                              </div>
                              <InputGroup className={classNames(styles.searchField, "searchFieldWrapper searchtxt order-sm-1 order-1 me-2 col-md-auto col-12 p-0 matches-radio-wrapper")}>
                                 <div className={classNames(styles.matchesSearchGuidanceTip)} style={{height: '1px', width: '10px', display: 'block'}}>
                                    <GuidanceTip className={classNames(styles.ssss)} page="matches" gotItIndex={0} gotItHandler={gotItHandler} nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler} tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}/>
                                 </div>
                                 <Form.Control style={{height: '54px'}} placeholder={t("MATCHES.SEARCH")} aria-label="SearchForArtworks" aria-describedby="basic-addon1"
                                               value={searchQuery}
                                               onChange={handleSearchChange}/>
                                 <InputGroup.Text style={{height: '54px'}} id="basic-addon1">
                                    <FaSearch/>
                                 </InputGroup.Text>
                              </InputGroup>
                           </div>
                        </div>
                     </div>

                     <div className={classNames(styles.matchCardContainer, "col-12 p-0")}>
                        <div className={classNames(styles.contentWrapper, "row gx-0 mt40")}>
                           <div className="col-xl-12 col-lg-12 p-0 float-left" style={{padding: "0"}}>
                              {renderAllMatches()}
                              {totalElements > 0 && !loadingInProgress ?
                                 <div className="pageWrap" style={{marginTop: "45px"}}>
                                    <Paginator postsPerPage={postsPerPage} totalElements={totalElements} currentPage={currentPage} paginate={paginate}/>
                                 </div> : <></>}
                           </div>
                           {/*<div className="col-xl-3 p-0 d-none d-xl-block">*/}
                           {/*   <div className="row gx-0">*/}
                           {/*      {SHOW_MATCH_ADVERTISEMENT_URL ?*/}
                           {/*         <div className="col-12">*/}
                           {/*            <div className="adImgWrap" style={{marginBottom: 25}}><img src={SHOW_MATCH_ADVERTISEMENT_URL} alt=""/></div>*/}
                           {/*         </div> : <></>}*/}
                           {/*   </div>*/}
                           {/*</div>*/}
                        </div>
                     </div>
                  </>}

               </div>
            </div>
            {!translationsLoaded ? <></> :
               <SocialShareModal
                  isShowModal={isOpenSocialShareModal}
                  modalCloseHandler={socialShareModalHandler}
                  sharePath={socialShareUrl}
                  currentUser={null}
                  isAuthenticated={props.isAuthenticated}
                  isSharingPoints={false}
               />
            }
         </div>
      </React.Fragment>
   );
};

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken != null || undefined,
      userId: state.auth.userId,
      userName: state.auth.userName,
      email: state.auth.email,
      Token: state.auth.accessToken,
      refresh: state.auth.refreshToken
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      onLogout: () => dispatch(actions.logout()), onRefreshAuth: (accessToken, refreshToken) => dispatch(actions.refreshSession(accessToken, refreshToken)),
   };
};
export default connect(mapStateToProps, mapDispatchToProps)(TournamentMatches);
