import React from 'react';

const MessageParser = ({children, actions}) => {

   const parse = (message) => {

      if (message.includes('hi')) {
         actions.handleHi();
      }

      if (message.includes('dog')) {
         actions.handleDog();
      }

   };

   return (
      <div>
         {React.Children.map(children, (child) => {
            return React.cloneElement(child, {
               parse: parse,
               actions: {actions},
            });
         })}
      </div>
   );
};

export default MessageParser;
