import Classnames from "classnames";
import styles from "./CreditPackagesModal.module.scss";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import CreditIcon from "../../../../assets/images/icons/24_sprts_token.webp";
import EthereumIcon from "../../../../assets/images/Ethereum-icon.webp";
import * as WalletService from "../../../../services/WalletService";
import {Puff} from "react-loading-icons";

function CreditPackagesModal({handleClose, handleProceedClose}) {

   const {t} = useTranslation();
   const [packageDetails, setPackageDetails] = useState({})
   const [loading, setLoading] = useState(false);
   const [calculateLoading, setCalculateLoading] = useState(false);
   const [latestMaticRate, setLatestMaticRate] = useState(0);
   const [latestUsdRate, setLatestUsdRate] = useState(0);
   const [inputOmniValue, setInputOmniValue] = useState(0);
   const [CalculatedMaticValue, setCalculatedMaticValue] = useState(0);

   const [selectedCard, setSelectedCard] = useState(null);
   const [selectedAmount, setSelectedAmount] = useState(null);
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   const handleCardClick = (index, amount, OmniValue) => {
      setSelectedCard(index);
      setSelectedAmount(amount);
      setInputOmniValue(OmniValue);
      setCalculatedMaticValue(0);
   };

   useEffect(() => {
      getTransactionRate();
   }, []);

   useEffect(() => {
      setLoading(true);
      const fetchData = async () => {
         await WalletService.getOmniCreditPurchasePackages().then(res => {
            if (res?.data?.apiStatus) {
               setPackageDetails(res?.data?.omniPurchasePackages);
               setLoading(false);
            } else {
               setLoading(false);
            }
         }).catch(e => {
            console.log(e)
         })
      }

      fetchData();

   }, []);

   const getTransactionRate = async () => {
      await WalletService.getLatestExchangeRate().then(res => {
         if (res?.data?.apiStatus) {
            setLatestMaticRate(res?.data?.matic);
            setLatestUsdRate(res?.data?.usd);
            setLoading(false);
            setCalculateLoading(false);
         } else {
            setLoading(false);
            setCalculateLoading(false);
         }
      }).catch(e => {
         console.log(e);
         setCalculateLoading(false);
      })
   }

   const calculateAmount = (e) => {
      setInputOmniValue(e.target.value)
      const inputOmniValue = e.target.value;
      setSelectedCard(0);
      if (e.nativeEvent.data >= 0) {
         setCalculateLoading(true);
         getTransactionRate();
         setCalculatedMaticValue(inputOmniValue * latestMaticRate);
      }
   }

   const handleProceed = () => {
      if (!selectedCard) {
         setSelectedCard(0);
         handleProceedClose(selectedCard, CalculatedMaticValue, inputOmniValue)
      } else {
         handleProceedClose(selectedCard, selectedAmount, inputOmniValue);
      }
   }

   const loadingIndicator = (
      <div style={{textAlign: "center"}}>
         <div className="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
         </div>
      </div>
   );


   return (
      <div className={Classnames("modal fade show", styles.modalBlur, styles.PaymentConfirmmodal)} id="exampleModalCenter"
           tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{display: "block"}}>
         <div className={Classnames(styles.CreditPurchaseModal, "modal-dialog modal-dialog-centered")} role="document">
            <div className={Classnames("modal-content", styles.modalContentContainer)}>
               <button className={Classnames(styles.closeButton, "close")} type="button" onClick={handleClose} data-dismiss="modal" aria-label="Close">
                   <span className={styles.closeButton} aria-hidden="true">
                      <img alt='Close Icon' src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/>
                   </span>
               </button>
               <div className={Classnames(styles.UserProfileModalWrapper)}>
                  {/* <div className={Classnames(styles.ModalHeader)}>
                     <h4><img src={WalletIcon}/> Wallet</h4>
                  </div> */}
                  <div className="row p-0">
                     <div className={Classnames(styles.UserProfileModal, "col-md-12")}>
                        <h4>{t("PAYMENT_CONFIRM_MODAL.BUY_$SPRTS_TOKENS")} </h4>
                     </div>
                  </div>
                  <div className="row p-0">
                     <div className={Classnames(styles.CreditRate)}>
                        <label>1 {t("PAYMENT_CONFIRM_MODAL.$SPRTS_CREDIT")} </label>
                        <img className={Classnames(styles.CreditIcon)} src={CreditIcon}/>
                        <label>=</label>
                        <span>{(latestMaticRate).toFixed(4)} MATIC</span>
                        <img className={Classnames(styles.EthereumIcon)} src={EthereumIcon}/>
                     </div>
                  </div>

                  {loading ? <>{loadingIndicator}</> :
                     <div className="row p-0">
                        <div className={Classnames(styles.OmniCardWrapper)}>
                           <div className={Classnames(styles.OmniCard, {[styles.OmniCardClicked]: selectedCard === 1})}
                                onClick={() => handleCardClick(1, packageDetails[0]?.amount * latestMaticRate, packageDetails[0]?.amount)}>
                              <div>
                                 <img className={Classnames(styles.CreditIcon)} src={CreditIcon}/>
                                 <label>{packageDetails[0]?.total} $SPRTS</label>
                                 <img className={Classnames(styles.EthereumIcon)} src={EthereumIcon}/>
                                 <span>{(packageDetails[0]?.amount * latestMaticRate).toFixed(2)} MATIC</span>
                              </div>
                           </div>
                           <div className={Classnames(styles.OmniCard, {[styles.OmniCardClicked]: selectedCard === 2})}
                                onClick={() => handleCardClick(2, packageDetails[1]?.amount * latestMaticRate, packageDetails[1]?.amount)}>
                              <div>
                                 <img className={Classnames(styles.CreditIcon)} src={CreditIcon}/>
                                 <label>{packageDetails[1]?.total} $SPRTS</label>
                                 <img className={Classnames(styles.EthereumIcon)} src={EthereumIcon}/>
                                 <span>{(packageDetails[1]?.amount * latestMaticRate).toFixed(2)} MATIC</span>
                              </div>
                           </div>
                           <div className={Classnames(styles.OmniCard, {[styles.OmniCardClicked]: selectedCard === 3})}
                                onClick={() => handleCardClick(3, packageDetails[2]?.amount * latestMaticRate, packageDetails[2]?.amount)}>
                              <div className={Classnames(styles.SaveTag)}>{t("PAYMENT_CONFIRM_MODAL.SAVE")} 25 %</div>
                              <div>
                                 <img className={Classnames(styles.CreditIcon)} src={CreditIcon}/>
                                 <label>{packageDetails[2]?.total} $SPRTS</label>
                                 <img className={Classnames(styles.EthereumIcon)} src={EthereumIcon}/>
                                 <span>{(packageDetails[2]?.amount * latestMaticRate).toFixed(2)} MATIC</span>
                              </div>
                           </div>
                           <div className={Classnames(styles.OmniCard, {[styles.OmniCardClicked]: selectedCard === 4})}
                                onClick={() => handleCardClick(4, packageDetails[3]?.amount * latestMaticRate, packageDetails[3]?.amount)}>
                              <div className={Classnames(styles.SaveTag)}>{t("PAYMENT_CONFIRM_MODAL.SAVE")} 50 %</div>
                              <div>
                                 <img className={Classnames(styles.CreditIcon)} src={CreditIcon}/>
                                 <label>{packageDetails[3]?.total} $SPRTS</label>
                                 <img className={Classnames(styles.EthereumIcon)} src={EthereumIcon}/>
                                 <span>{(packageDetails[3]?.amount * latestMaticRate).toFixed(2)} MATIC</span>
                              </div>
                           </div>
                        </div>
                     </div>}
                  <div className="row p-0">
                     <div className="col-md-12 ps-4 pe-4">
                        <fieldset>
                           <legend>{t("PAYMENT_CONFIRM_MODAL.OR_CALCULATE_YOUR_AMOUNT")}</legend>
                           <div className={Classnames(styles.OmniCal)}>
                              <div>
                                 <label>{t("PAYMENT_CONFIRM_MODAL.SPECIFY_AMOUNT_OF_$SPRTS")}</label>
                                 <input
                                    value={inputOmniValue}
                                    style={{color: 'white'}}
                                    onChange={(e) => calculateAmount(e)}
                                    autoComplete="off"
                                    maxLength={10}
                                 />
                              </div>
                              <span>=</span>

                              <div>
                                 <label>{t("PAYMENT_CONFIRM_MODAL.AMOUNT_IN_MATIC")}</label>
                                 {calculateLoading ? <>
                                       <br></br>
                                       <Puff height={'2em'} strokeWidth={5} speed={2}/>
                                    </> :
                                    <input
                                       readOnly={true}
                                       value={CalculatedMaticValue}
                                       style={{color: 'white'}}
                                    />
                                 }
                              </div>
                           </div>
                        </fieldset>
                     </div>
                  </div>
               </div>

               <div className={Classnames(styles.photoModifyModelBtnRow, "pt-2 pb-4")}>
                  <button type="button" className={styles.GrayButton}
                          onClick={handleClose}>
                     <text>{t("PAYMENT_CONFIRM_MODAL.CANCEL")}</text>
                  </button>
                  <button disabled={!(selectedCard || inputOmniValue)} type="button" className={Classnames(styles.LoginButton, styles.ProceedButton)} onClick={handleProceed}>{t("PAYMENT_CONFIRM_MODAL.PROCEED")}</button>
               </div>
            </div>
         </div>
      </div>
   );
}

export default CreditPackagesModal;
