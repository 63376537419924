import React, {Suspense} from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.scss";
import App from "./App";
import {Provider} from "react-redux";
import thunk from "redux-thunk";
import authReducer from "./store/reducers/auth";
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import ReactGA from 'react-ga4';
import "./localization/config";
import {SOCIAL_APP_ID} from "./utilities/Constants";
import {GoogleOAuthProvider} from "@react-oauth/google";

const TRACKING_ID = "G-48Q1LJWPP1";
ReactGA.initialize(TRACKING_ID);

const composeEnhancers =
  window.__REDUX__DEVTOOLS_EXTENSION_COMPOSE__ || compose;

console.warn = () => {
};

console.info = () => {
};

console.profile = () => {
};

if (process.env.REACT_APP_ENV == 'production') {
  console.error = () => {
  };

  console.log = () => {
  };
}

const rootReducer = combineReducers({
  auth: authReducer,
});

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Suspense fallback={<div>Loading...</div>}>
      <GoogleOAuthProvider clientId={SOCIAL_APP_ID.GOOGLE}>
        <App/>
      </GoogleOAuthProvider>
    </Suspense>
  </Provider>
);

// const app = (
//   <Provider store={store}>
//     <App />
//   </Provider>
// );
// ReactDOM.render(app, document.getElementById("root"));
// reportWebVitals();
