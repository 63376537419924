import React, {lazy, useState} from "react";
import Classnames from "classnames";
import styles from "./PlayerInfoHistoryModal.module.scss";

const PlayerInfoTab = lazy(() => import("./PlayerInfoTab"));
const PlayerHistoryTab = lazy(() => import("./PlayerHistoryTab"));

const OPTIONS = {
   PLAYER_INFO: "PLAYER_INFO", PLAYER_HISTORY: "PLAYER_HISTORY",
};

const PlayerInfoHistoryModal = ({data, handleModalClose}) => {
   const [selectedOption, setSelectedOption] = useState(OPTIONS.PLAYER_INFO);

   return (<div
      className={Classnames("modal fade show", styles.BaseModalPopUp, styles.PlayerInfoHistoryModal)}
      id="exampleModalCenter"
      tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"
      style={{display: "block"}}>
      <div className={Classnames(styles.ModalBodyWrapper, "modal-dialog modal-dialog-centered")} role="document">
         <div className={Classnames("modal-content", styles.modalContentContainer)}>
            <div className="HeaderButtonRow">
               <button className={Classnames(styles.closeButton, "close z-3")} type="button" onClick={handleModalClose}
                       data-dismiss="modal" aria-label="Close">
                     <span className={styles.closeButton} aria-hidden="true">
                        <img alt='Close Icon'
                             src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/>
                     </span>
               </button>
            </div>

            <div className={Classnames(styles.TabSetWrapper, "TabSetWrapper")}>
               <div className={Classnames(styles.TabTitles, "TabTitles")}>
                  <a role={'button'} onClick={() => setSelectedOption(OPTIONS.PLAYER_INFO)}
                     className={Classnames(selectedOption === OPTIONS.PLAYER_INFO && styles.active)}>Player Info</a>
                  <a role={'button'} onClick={() => setSelectedOption(OPTIONS.PLAYER_HISTORY)}
                     className={Classnames(selectedOption === OPTIONS.PLAYER_HISTORY && styles.active)}>Player
                     History</a>
               </div>
               <div className={Classnames(styles.TabContentBody, "TabContentBody row")}>

                  <div className={Classnames(styles.TabContent1, "TabContent1")}
                       style={{display: selectedOption === OPTIONS.PLAYER_INFO ? 'block' : 'none'}}>
                     <PlayerInfoTab playerId={data?.playerId} tournamentId={data?.tournamentId}
                                    handleModalClose={handleModalClose}/>
                  </div>

                  <div className={Classnames(styles.TabContent2, "TabContent2")}
                       style={{display: selectedOption === OPTIONS.PLAYER_HISTORY ? 'block' : 'none'}}>
                     <PlayerHistoryTab playerId={data?.playerId} tournamentId={data?.tournamentId}
                                       playerImage={data?.playerImage} playerName={data?.playerName}
                                       handleModalClose={handleModalClose}/>
                  </div>

               </div>
            </div>
         </div>
      </div>
   </div>)
}

export default PlayerInfoHistoryModal;
