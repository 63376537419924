import {ApiEndpoints} from "./ApiConstants";
import AxiosInstance from "../helpers/AxiosInstance";

export const getGlobalLeaderBoard = (page, size, property, isAsc) => {
   const sortType = isAsc ? 'asc' : 'desc';
   const url = `${ApiEndpoints.GET_USER_LEADERBOARD}?page=${page}&size=${size}&sort=${property},${sortType}`

   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const getTournamentLeaderBoard = (page, size, tournamentId, property, isAsc, authToken) => {
   const sortType = isAsc ? 'asc' : 'desc';
   const url = `${ApiEndpoints.GET_TOURNAMENT_USER_LEADERBOARD}/${tournamentId}?page=${page}&size=${size}&sort=${property},${sortType}`
   return AxiosInstance()
      .get(url, {
         headers: {
            "AuthToken": authToken
         }
      })
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const getUserForGlobalLB = (userName) => {
   const url = `${ApiEndpoints.GET_LEADERBOARD_USER}/${userName}`

   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
}

export const getLBUserByUserNameAndTournamentId = (userName, tournamentId) => {
   const url = `${ApiEndpoints.GET_LEADERBOARD_USER_TOURNAMENT}/${tournamentId}/${userName}`

   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
}

export const getLBMaxAvgRightAnsweredUser = () => {
   const url = `${ApiEndpoints.GET_LEADERBOARD_MAX_AVG_RIGHT_ANSWERED_USER}`

   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
}

export const getLBMaxAvgRightAnsweredUserByTournamentId = (tournamentId) => {

   const url = `${ApiEndpoints.GET_LEADERBOARD_MAX_AVG_RIGHT_ANSWERED_USER}/${tournamentId}`

   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
}

export const getMainLeaderboardPoints = (userName) => {
   const url = `${ApiEndpoints.GET_LEADERBOARD_MAIN_POINTS}/${userName}`

   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
}

export const getActiveTournamentList = (page, size, type, sort, authToken) => {
   const url = `${ApiEndpoints.GET_ACTIVE_TOURNAMENT_LIST}/?page=${page}&size=${size}&sort=${type},${sort}`
   return AxiosInstance()
      .get(url, {
         headers: {
            "AuthToken": authToken
         }
      })
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const getPositionChartDetails = (userName, tournamentId, authToken) => {
   const url = `${ApiEndpoints.GET_POSITION_CHART_DETAILS}/${tournamentId}/${userName}`
   return AxiosInstance()
      .get(url, {
         headers: {
            "AuthToken": authToken
         }
      })
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};
