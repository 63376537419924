import {useEffect, useState} from "react";
import * as TournamentService from "../services/TournamentService";
import {TOURNAMENT_TYPES} from "../utilities/Constants";
import {useNavigate, useParams} from "react-router-dom";
import {checkUserFantasySubscription} from "../services/FantasyService";
import {toast} from "react-toastify";
import {t} from "i18next";

const useTournamentValidator = ({tournamentDetails, userName, Token}) => {
   const pathname = window.location.pathname;
   let {tournamentId} = useParams();
   let navigate = useNavigate();

   const [isFantasySubscribed, setIsFantasySubscribed] = useState(false);

   useEffect(() => {
      const handleCheckUserFantasySubscription = async (tournamentId) => {
         try {
            const response = await checkUserFantasySubscription(tournamentId, userName, Token);
            if (response.apiStatus && response?.isSubscribed) {
               return response?.isSubscribed;
            } else if (response.apiStatus) {
               return false;
            }
            return null;
         } catch (error) {
            console.error("handleCheckUserFantasySubscription", error);
         }
      }

      const checkTournamentRegistration = async () => {
         sessionStorage.setItem('redirectPath', pathname);
         if (!localStorage.getItem('accessToken')) {
            if (tournamentDetails?.tournamentTicketOmniCredits > 0) {
               toast.error(t("ONGOING_TOURNAMENTS.PLEASE_SIGN_IN_TO_ACCESS_THE_PAID_TOURNAMENT"));
               navigate("/login");
            } else {
               setIsFantasySubscribed(false);
            }
         } else {
            try {
               const response = await TournamentService.checkTournamentRegistration(tournamentId, userName);
               if (response.apiStatus) {
                  if (!response.available) {
                     toast.error(`You haven't subscribed to the tournament.`);
                     navigate("/tournaments");
                     return;
                  }

                  const isSubscribed = await handleCheckUserFantasySubscription(tournamentId);
                  setIsFantasySubscribed(isSubscribed);

                  if (new Date(tournamentDetails?.startTime) > new Date()) {
                     const searchParams = new URLSearchParams(window.location.search);
                     const isReviewSchedule = searchParams.has('reviewSchedule') && searchParams.get('reviewSchedule') === 'true';

                     if (isReviewSchedule) {
                     } else if (isSubscribed === false && tournamentDetails.toStart === false) {
                        navigate(`/fantasy-team/${tournamentId}`, {
                           state: {
                              tournamentDetails: {
                                 id: tournamentId, tournamentType: TOURNAMENT_TYPES.LEAGUE
                              }
                           }
                        });
                     }
                  }
               } else {
                  toast.error(`You haven't subscribed to the tournament.`);
                  navigate("/tournaments");
               }
            } catch (error) {
               console.log(error);
               throw error;
            }
         }

      }

      checkTournamentRegistration();
   }, [tournamentId, userName, tournamentDetails, pathname, navigate, setIsFantasySubscribed, Token]);

   return {isFantasySubscribed};
}

export default useTournamentValidator;
