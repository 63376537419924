const YouTubeStream = ({videoId}) => {
   return (<div style={{
      position: 'relative',
      paddingBottom: '56.25%',
      height: 0,
      overflow: 'hidden',
      maxWidth: '100%',
      background: '#000'
   }}>
      <iframe
         title="Meta11 Live Stream"
         src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`}
         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
         allowFullScreen
         style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
      ></iframe>
   </div>);
};

export default YouTubeStream
