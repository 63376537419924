import {ApiEndpoints} from "./ApiConstants";
import AxiosInstance from "../helpers/AxiosInstance";

export function getOmniCreditsByUsername(token, userName) {
   const url = `${ApiEndpoints.GET_USER_CREDITS}/${userName}`;

   return AxiosInstance()
      .get(url, {
         headers: {
            "AuthToken": token
         }
      })
      .then((response) => {
         return {
            status: response.status,
            data: response.data,
         };
      })
      .catch((error) => {
         return error.response;
      });
}

export function getOmniCreditPurchasePackages() {
   const url = `${ApiEndpoints.GET_OC_PURCHASE_PACKAGES}`;

   return AxiosInstance()
      .get(url).then((response) => {
         return {
            status: response.status,
            data: response.data,
         };
      })
      .catch((error) => {
         return error.response;
      });
}

export function getLatestExchangeRate() {
   const url = `${ApiEndpoints.GET_OC_EXCHANGE_RATE}`;

   return AxiosInstance()
      .get(url).then((response) => {
         return {
            status: response.status,
            data: response.data,
         };
      })
      .catch((error) => {
         return error.response;
      });
}

export const sendWalletVerificationEmail = async (token, data) => {
   try {
      const response = await AxiosInstance().put(ApiEndpoints.SEND_WALLET_VERIFICATION_EMAIL, data, {
         headers: {
            "AuthToken": token
         }
      });
      return response.data;
   } catch (error) {
      return error.response;
   }
}

export const verifyWithdrawCode = async (token,data) => {
   try {
      const response = await AxiosInstance().post(ApiEndpoints.VERIFY_WITHDRAW_CODE, data, {
         headers: {
            "AuthToken": token
         }
      });
      return response.data;
   } catch (error) {
      return error.response;
   }
}

export const getUserTransactions = async (token, receiveTransactions, sendTransactions, page, size, userName) => {
   try {
      const response = await AxiosInstance().get(`${ApiEndpoints.GET_USER_TRANSACTIONS}?page=${page}&size=${size}&userName=${userName}&receiveTransactions=${receiveTransactions}&sendTransactions=${sendTransactions}`, {
         headers: {
            "AuthToken": token
         }
      });
      return response.data;
   } catch (error) {
      return error.response;
   }
}

export const checkWithdrawEligibility = async (token) => {
   try {
      const response = await AxiosInstance().get(ApiEndpoints.CHECK_WITHDRAW_ELIGIBILITY, {
         headers: {
            "AuthToken": token
         }
      });
      return response.data;
   } catch (error) {
      return error.response;
   }
}

export const purchaseFantasyPlayerBoost = async (token, data) => {
   try {
      const response = await AxiosInstance().post(ApiEndpoints.PURCHASE_FANTASY_PLAYER_BOOST, data, {
         headers: {
            "AuthToken": token
         }
      });
      return response.data;
   } catch (error) {
      return error.response;
   }
}
