import React from "react";
import Classnames from "classnames";
import styles from "../CreditPackagesModal/CreditPackagesModal.module.scss";
import WalletIcon from "../../../../assets/images/Wallet.webp";
import {Link} from "react-router-dom";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";


const CreditPurchaseStatusModal = (props) => {
   const {t} = useTranslation();

   return (
      <div className={Classnames("modal fade show", styles.modalBlur, styles.PaymentConfirmmodal)} id="exampleModalCenter"
           tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{display: "block"}}>
         <div className={Classnames(styles.CreditPurchaseModal, "modal-dialog modal-dialog-centered")} role="document">
            <div className={Classnames("modal-content", styles.modalContentContainer)}>
               <button className={Classnames(styles.closeButton, "close")} style={{position: "absolute", right: "15px", top: "10px", zIndex: "2"}} type="button" data-dismiss="modal" aria-label="Close" onClick={props.closeAllModals}>
                   <span className={styles.closeButton} aria-hidden="true">
                      <img alt='Close Icon' src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/>
                   </span>
               </button>
               <div className={Classnames(styles.UserProfileModalWrapper)}>
                  <div className={Classnames(styles.ModalHeader)}>
                     <h4><img src={WalletIcon} alt=''/>{props?.transactionStatus ? t("CREDIT_PURCHASE_STATUS_MODAL.SUCCESS") : t("CREDIT_PURCHASE_STATUS_MODAL.FAILED")}</h4>
                  </div>
                  <div className="row p-0">
                     <div className={Classnames(styles.UserProfileModal, "col-md-12")}>
                        {props?.transactionStatus ?
                           <h4>{t("CREDIT_PURCHASE_STATUS_MODAL.SUCCESSFULLY_PURCHASED")} {props?.sprtsAmount?.toFixed(3)} {t("CREDIT_PURCHASE_STATUS_MODAL.$SPRTS_TOKENS")}</h4> :
                           <h4>{t("CREDIT_PURCHASE_STATUS_MODAL.FAILED_TO_PURCHASE")} {props?.sprtsAmount?.toFixed(3)} {t("CREDIT_PURCHASE_STATUS_MODAL.$SPRTS_TOKENS")}</h4>}
                     </div>
                  </div>
                  <div className="row p-0">
                     <div className={Classnames(styles.CreditRate)}>
                        {props?.transactionStatus ? <>
                           <p>{t("CREDIT_PURCHASE_STATUS_MODAL.YOUR_TRANSACTION_WAS_SUCCESSFUL_PLEASE_WAIT_WHILE_WE_CREATE_$SPRTS_TOKENS_AND_ADD_TO_YOUR_WALLET")}</p>
                           <p>{t("CREDIT_PURCHASE_STATUS_MODAL.PLEASE_CONTACT")} <a href="mailto:support@meta11.com">support@meta11.com</a> {t("CREDIT_PURCHASE_STATUS_MODAL.IF_YOU_DO_NOT_RECEIVE_$SPRTS_TOKENS")}</p>
                        </> : <>
                           <p>{t("CREDIT_PURCHASE_STATUS_MODAL.YOUR_TRANSACTION_WAS_FAILED_PLEASE_TRY_AGAIN_LATER")}</p>
                           <p> {t("CREDIT_PURCHASE_STATUS_MODAL.OR_PLEASE_CONTACT")} <a href="mailto:support@meta11.com">support@meta11.com</a> {t("CREDIT_PURCHASE_STATUS_MODAL.IF_YOU_NEED_ANY_ASSISTANCE")}</p>
                        </>}

                     </div>
                  </div>
               </div>
               <div className={Classnames(styles.photoModifyModelBtnRow, "p-3")}>
                  <button type="button" className={styles.LoginButton} onClick={props?.closeAllModals}>{t("CREDIT_PURCHASE_STATUS_MODAL.FINISH")}</button>
                  {props?.transactionStatus ?
                     <Link to={`https://www.oklink.com/amoy/tx/${props?.transactionHash}`} target={"_blank"}>
                        <button type="button" className={styles.LoginButton}>{t("CREDIT_PURCHASE_STATUS_MODAL.VIEW_RECEIPT")}</button>
                     </Link> : <></>
                  }
               </div>
            </div>
         </div>
      </div>
   )
}

const mapDispatchToProps = (dispatch) => {
   return {
      onLogout: () => dispatch(actions.logout()),
   };
};
const mapStateToProps = (state) => {
   return {
      token: state.auth.accessToken, isAuthenticated: state.auth.accessToken !== null, profileImage: state.auth.imageURL, oAuthProviderType: state.auth.oAuthProviderType, userName: state.auth.userName, name: state.auth.name
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditPurchaseStatusModal);
