export const SubscribeUserToNewsLetter = async (userEmail) => {
  const JSONData = {email_address: userEmail, status: 'subscribed'};

  try {
    const response = await fetch('/members', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic a09b4173fd1b9a95b0af3c3b08507fcc-us11',
      },
      body: JSON.stringify(JSONData),
    });

    return {
      status: response.status,
      data: response.data
    };
  } catch (error) {
    console.error('An error occurred:', error);
  }
};
